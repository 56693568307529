<template>
  <div class="acquires-credits-success-page">
    <acquires-credits-success :name="name" :count="count" :type="type" :goodiecreditsorquota="goodiecreditsorquota" :coupondisplayin="coupondisplayin" />
  </div>
</template>
<script>
import AcquiresCreditsSuccess from '@/components/AcquiresCreditsSuccess'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'AcquiresCreditsSuccessPage',
  components: {
    AcquiresCreditsSuccess
  },
  provide () {
    return {
      closeGoodieBagSuccess: this.closeGoodieBagSuccess
    }
  },
  data () {
    return {
      count: '1',
      type: 'CASH_VOUCHER',
      goodiecreditsorquota: 0,
      name: '',
      coupondisplayin: 'COUPON_SECTION'
    }
  },
  mounted () {
    console.log('@ScarabQueue acquirecreditessuccesspage')
    this.emarsysSetCustomerId()
    const { count, type, goodiecreditsorquota, name, coupondisplayin } = this.$route.query
    if (count) {
      this.count = count
    }
    if (type) {
      this.type = type
    }
    if (goodiecreditsorquota) {
      this.goodiecreditsorquota = goodiecreditsorquota
    }
    if (name) {
      this.name = name
    }
    if (coupondisplayin) {
      this.coupondisplayin = coupondisplayin
    }
  },
  methods: {
    closeGoodieBagSuccess () {
      const { recordId, slotId } = this.$route.query
      let goodiebagRecordDetail = null
      if (recordId) {
        goodiebagRecordDetail = this.$store.getters['goodiebagparticipationrecords/recordDetail'](recordId)
      }
      const { goodie_bag: goodieBag } = goodiebagRecordDetail || {}

      let type = 'CASH_VOUCHER_CAMPAIGN_FOR_GOODIE_BAG'
      if (this.type === 'GIFT') {
        type = 'GIFT_CAMPAIGN_FOR_GOODIE_BAG'
      }
      const query = {
        type,
        recordId,
        'goodieBagId': goodieBag && goodieBag.id,
        slotId
      }

      pushRouterWithQuery(this.$router, { name: 'goodie-bag-campaign-list', query })
    }
  }
}
</script>
<style lang="scss" scoped>
    .acquires-credits-success-page{
        background: #FFFFFF;
        margin-top: 20px;
        margin-bottom: 30px;
    }
</style>
