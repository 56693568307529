import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import axiosSetup from './helpers/http'

import './registerServiceWorker'

import ElementUI from 'element-ui'
// import elLang from 'element-ui/lib/locale/lang/en'
// import locale from 'element-ui/lib/locale'
import browserDetect from 'vue-browser-detect-plugin'
import Clipboard from 'v-clipboard'
import VueSocialSharing from 'vue-social-sharing'
import VCalendar from 'v-calendar'
import VueLazyload from 'vue-lazyload'

// import '@babel/polyfill'

// import { getLangName } from '@/utils/lang'

require('@/assets/main.scss')
require('@/assets/element-ui-variables.scss')
require('@/assets/carousel.scss')
require('@/assets/tui-editor.css')
require('moment/locale/zh-hk')
require('moment/locale/zh-cn')
const moment = require('moment')

global.jQuery = require('jquery')

var $ = global.jQuery
window.$ = $

const longDateFormat = 'DD MMMM YYYY HH:mm'

if (process.env.NODE_ENV === 'production' && window &&
        window.location && window.location.port !== '8443' && window.location.port !== '5000' &&
        window.location.hostname && !window.location.hostname.endsWith('demo.com')) {
  if (process.env.VUE_APP_SERVER_ENVIR !== 'UAT' && window.console) { window.console.log = function () {} }
}

axiosSetup()

Vue.use(require('vue-moment'), { moment })
Vue.use(browserDetect)
Vue.use(Clipboard)

// locale.use(elLang)
Vue.use(ElementUI)
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})
Vue.use(VueSocialSharing)
Vue.use(VCalendar)

// lazy loading handling
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1
})

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    /*
    translateLang (text, item, key) {
      if (!item || !item.translations) {
        return text
      }
      console.log('locale:', this.$i18n.locale, item)
      const lang = this.$i18n.locale
      let translationText = text
      const translateLang = getLangName(lang)
      item.translations.map(translateItem => {
        if (translateItem.language === translateLang) {
          translationText = translateItem[key]
        }
      })
      console.log('52--- text: ', text)
      if (!translationText) {
        translationText = text
      }
      return translationText
    },
    */
    emarsysSetCustomerId (titleCategory) {
      const pageTitleDict = {
        'home': {
          'en': 'Hong Kong Tourism Board',
          'zh-t': '香港旅遊發展局',
          'zh-s': '香港旅游发展局'
        },
        'explore': {
          'en': 'Deals | Hong Kong Tourism Board',
          'zh-t': '全城優惠 | 香港旅遊發展局',
          'zh-s': '全城优惠 | 香港旅游发展局'
        },
        'offerDetails': {
          'en': 'Offer Details | Hong Kong Tourism Board',
          'zh-t': '獎賞詳情 | 香港旅遊發展局',
          'zh-s': '奖赏详情 | 香港旅游发展局'
        },
        'redeemOffer': {
          'en': 'Redeem Offer | Hong Kong Tourism Board',
          'zh-t': '換領獎賞 | 香港旅遊發展局',
          'zh-s': '换领奖赏 | 香港旅游发展局'
        },
        'myWallet': {
          'en': 'My Wallet | Hong Kong Tourism Board',
          'zh-t': '我的錢包 | 香港旅遊發展局',
          'zh-s': '我的钱包 | 香港旅游发展局'
        },
        'couponDetails': {
          'en': 'Coupon Details | Hong Kong Tourism Board',
          'zh-t': '獎賞詳情 | 香港旅遊發展局',
          'zh-s': '奖赏详情 | 香港旅游发展局'
        },
        'ticketDetails': {
          'en': 'Ticket Details | Hong Kong Tourism Board',
          'zh-t': '門票詳情 | 香港旅遊發展局',
          'zh-s': '门票详情 | 香港旅游发展局'
        },
        'useCoupon': {
          'en': 'Use Coupon | Hong Kong Tourism Board',
          'zh-t': '使用獎賞 | 香港旅遊發展局',
          'zh-s': '奖赏详情 | 香港旅游发展局'
        },
        'goodieBag': {
          'en': 'Rewards | Hong Kong Tourism Board',
          'zh-t': '獎賞 | 香港旅遊發展局',
          'zh-s': '奖赏 | 香港旅游发展局'
        },
        'myGoodieBag': {
          'en': 'My Rewards | Hong Kong Tourism Board',
          'zh-t': '我的獎賞 | 香港旅遊發展局',
          'zh-s': '我的奖赏 | 香港旅游发展局'
        },
        'goodieBagCampaigns': {
          'en': 'Rewards Campaigns | Hong Kong Tourism Board',
          'zh-t': '獎賞活動 | 香港旅遊發展局',
          'zh-s': '奖赏活动 | 香港旅游发展局'
        },
        'goodieBagCampaignsDetails': {
          'en': 'Rewards Campaigns Details | Hong Kong Tourism Board',
          'zh-t': '獎賞活動詳情 | 香港旅遊發展局',
          'zh-s': '奖赏活动详情 | 香港旅游发展局'
        },
        'myAccount': {
          'en': 'My Account | Hong Kong Tourism Board',
          'zh-t': '我的帳戶 | 香港旅遊發展局',
          'zh-s': '我的帐户 | 香港旅游发展局'
        }
      }
      try {
        const lang = this.$i18n.locale
        const documentTitle = titleCategory in pageTitleDict ? pageTitleDict[titleCategory][lang] : pageTitleDict['home'][lang]

        const profile = this.$store.getters['auth/userProfile']
        if (profile && profile.membership_id) {
          console.log('@ScarabQueue,setCustomerId', profile.membership_id, documentTitle)
          window.ScarabQueue.push(['setCustomerId', `ES-${profile.membership_id}`])
          window.ScarabQueue.push(['go'])
        } else {
          window.ScarabQueue.push(['go'])
        }
      } catch (error) {
        console.error('@ScarabQueue,setCustomerId error', error)
      }
    },
    updateMomentLocaleIfNeeded () {
      const lang = this.$i18n.locale
      let locale = 'en'
      if (lang === 'zh-s') {
        locale = 'zh-cn'
      } else if (lang === 'zh-t') {
        locale = 'zh-hk'
      }

      // console.log('@73, locale', locale, this.$moment.locale())
      if (this.$moment.locale() !== locale) {
        this.$moment.locale(locale)
        // console.log('@73b, udpated locale', this.$moment.locale())
      }
    },
    getDateString (dateObj, enFormatString = '', addGMT = false) {
      // console.log('@121, get long date string', enFormatString)

      const lang = this.$i18n.locale
      let formatString = enFormatString || 'DD MMMM YYYY'

      this.updateMomentLocaleIfNeeded()

      if (lang !== 'en') {
        formatString = 'LL'
        if (enFormatString.includes(' HH:mm:ss')) {
          formatString = 'LL HH:mm:ss'
        } else if (enFormatString.includes(' HH:mm')) {
          formatString = 'LL HH:mm'
        } else if (enFormatString.includes(' HH')) {
          formatString = 'LL HH'
        }
      }

      if (addGMT) {
        formatString += ' (G\\MT +8)'
      }

      // the locale of moment will respect i18n module
      // https://www.npmjs.com/package/vue-moment#configuration
      const result = this.$moment(dateObj).format(formatString)

      // console.log('@78', result, lang, this.$moment.locale(), dateObj)
      return result
    },
    getLongDateString (dateObj) {
      // console.log('@121, get long date string')
      return this.getDateString(dateObj, longDateFormat, true)
    },
    getCampaignVaidTil (recordEndDate, campaginEndDate, followGoodieBagActivePeriod) {
      let result = ''
      let endDate
      if (followGoodieBagActivePeriod) {
        endDate = recordEndDate
      } else {
        if (recordEndDate && campaginEndDate) {
          endDate = new Date(recordEndDate) > new Date(campaginEndDate) ? campaginEndDate : recordEndDate
        } else {
          endDate = recordEndDate || campaginEndDate
        }
      }

      if (endDate) {
        result = this.$t('myWalletValidTime') + this.getLongDateString(endDate)
      }
      return result
    },
    getGoodieBagValidDateString (startDateString, endDateString, itemAlias) {
      const startDate = startDateString ? new Date(startDateString) : new Date('0000-01-01')
      const endDate = endDateString ? new Date(endDateString) : new Date('9999-12-31')
      let itemName = this.$t('goodiebagRewards')
      if (itemAlias === 'cashVoucher') {
        itemName = this.$t('goodiebagCashVouchers')
      } else if (itemAlias === 'limitedOffer') {
        itemName = this.$t('goodiebagLimitedOffers')
      }
      const now = new Date()
      const startOfToday = this.$moment().startOf('day').toDate()
      const momentEndDate = this.$moment(endDate)

      if (now > endDate) {
        return this.$t('goodiebagVaildtil', {
          validTil: this.getLongDateString(endDate)
        })
      } else if (startDate > now) {
        return this.$t('goodiebagAcquiringAfterStart', {
          startDate: this.getLongDateString(startDate),
          itemName
        })
      } else if (now < endDate && momentEndDate.diff(startOfToday, 'hours') < 24 && momentEndDate.diff(startOfToday, 'hours') > 0) {
        return this.$t('goodiebagAcquiringLastDay', {
          endTime: momentEndDate.format('HH:mm'),
          itemName
        })
      } else if (now < endDate && momentEndDate.diff(startOfToday, 'days') < 7 && momentEndDate.diff(startOfToday, 'days') > 0) {
        return this.$t('goodiebagAcquiringLastNDay', {
          dayCount: momentEndDate.diff(startOfToday, 'days') + 1,
          endTime: momentEndDate.format('HH:mm'),
          itemName
        })
      } else if (endDateString) {
        return this.$t('goodiebagAcquiringBeforeEnd', {
          endDate: this.getLongDateString(endDate)
        })
      }
      return
    },
    judgeClient () {
      let client = ''
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        client = 'iOS'
      } else if (/(Android)/i.test(navigator.userAgent)) {
        client = 'Android'
      } else {
        client = 'PC'
      }
      console.log('73------ client', client)
      return client
    }
  }
})

new Vue({

  data: {
    initialHistoryCount: window.history.length,
    detailNavigated: false
  },
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

console.log('@46')
