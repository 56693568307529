<script>
import { LOCATION_AREA_ID, LOCATION_DISTRICT_ID
} from '@/utils/utils'
import { getChannelName } from '@/utils/utils'
import stringify from 'json-stable-stringify'
import axios from 'axios'

export default {
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

    isAreaSelected (filterValue) {
      const areaSelected = filterValue && filterValue.location &&
          filterValue.location[LOCATION_AREA_ID] &&
          filterValue.location[LOCATION_AREA_ID].length

      return areaSelected
    },

    removeHiddenLocationDistricts (filterValue) {
      if (this.isAreaSelected(filterValue) && filterValue.location &&
         filterValue.location[LOCATION_DISTRICT_ID]
      ) {
        const usableDistricts = this.getSelectedAreaRelatedDistrictIds(filterValue)
        console.log('@785@815', usableDistricts)

        filterValue.location[LOCATION_DISTRICT_ID] = filterValue.location[LOCATION_DISTRICT_ID].filter(
          item => usableDistricts.includes(item)
        )
      }
    },
    convertLocationResultCount (locationOptions, filterValue) {
      let totalCampaigns = 0
      const areaDict = {}
      const districtDict = {}

      locationOptions.forEach(area => {
        console.log('@317', filterValue.location)

        areaDict[area.id] = area.number_of_campaigns
        totalCampaigns += area.number_of_campaigns

        // const areaSelected = this.isAreaSelected(filterValue, area.id)
        console.log('@329@50', !this.isAreaSelected(filterValue))
        if (!this.isAreaSelected(filterValue) || (filterValue.location[LOCATION_AREA_ID].includes(area.id) &&
         area.number_of_campaigns)) {
          console.log('@329@333', area.districts)
          area.districts.forEach(district => {
            if (district.number_of_campaigns) {
              districtDict[district.id] = district.number_of_campaigns
            }
          })
        }
      })

      console.log('@329@333b', districtDict)

      const resultCount = {
        [LOCATION_AREA_ID]: { ...areaDict, all: totalCampaigns },
        [LOCATION_DISTRICT_ID]: { ...districtDict, all: totalCampaigns }
      }

      console.log('@329@478', this.isAreaSelected(filterValue), resultCount)
      return resultCount
    },
    convertLocationFilterOptions (locationOptions) {
      const areas = []
      let districts = []

      locationOptions.forEach(area => {
        area.attribute_tags = area.districts
        // delete area.districts

        areas.push(area)
        districts = [...districts, ...area.districts]
      })
      // districts.forEach(item => { item.all = item.number_of_campaigns })

      const newOptions = [
        { name: this.$t('goodiebagLocationFilterArea'), id: LOCATION_AREA_ID, attribute_tags: areas
        },
        { name: this.$t('goodiebagLocationFilterDistrict'), id: LOCATION_DISTRICT_ID, attribute_tags: districts
        }
      ]

      // console.log('@478@327', newOptions, locationOptions)

      return newOptions
    },
    getSelectedAreaRelatedDistrictIds (filterValue) {
      const { location } = filterValue
      const relatedDistrictIds = []
      console.log('@645@377', location)
      if (location) {
        const selectedAreas = location[LOCATION_AREA_ID]
        const { location: locationOptions } = this.filterOptions

        console.log('@815@383', locationOptions, selectedAreas)
        if (locationOptions && selectedAreas) {
          const districtOptions = locationOptions[1].attribute_tags
          // console.log('@645@382', districtOptions, selectedDistricts)

          districtOptions.forEach(districtOption => {
            // console.log('@815@389', selectedAreas, districtOption.region.id)
            if (selectedAreas.find(areaId => String(areaId) === String(districtOption.region.id))) {
              relatedDistrictIds.push(districtOption.id)
            }
          })
        }
      }

      console.log('@815@distrcts', relatedDistrictIds)
      return relatedDistrictIds
    },
    getSelectedDistrctsRelatedAreasIds (filterValue) {
      const { location } = filterValue
      const relatedAreaIds = []
      console.log('@645@377', location)
      if (location) {
        const selectedDistricts = location[LOCATION_DISTRICT_ID]
        const { location: locationOptions } = this.filterOptions
        // console.log('@645@377b', locationOptions)
        if (locationOptions && selectedDistricts) {
          const districtOptions = locationOptions[1].attribute_tags
          // console.log('@645@382', districtOptions, selectedDistricts)
          selectedDistricts.forEach(selectedDistrict => {
            const districtOption = districtOptions.find(item => {
              // console.log('@645p', item.id, selectedDistrict, selectedDistrict.id, String(item.id) === String(selectedDistrict))
              return String(item.id) === String(selectedDistrict)
            })

            // console.log('@645x', districtOption)
            if (districtOption) {
              const relatedAreaId = districtOption.region.id
              relatedAreaIds.push(relatedAreaId)
            }
          })
        }
      }
      console.log('@645@375', relatedAreaIds)
      return relatedAreaIds
    },
    getFilerValueAreaIds (filters) {
      const { location } = filters
      const finalAreaIds = []
      console.log('@415b', location)
      if (location && location[LOCATION_AREA_ID]) {
        const districtRelatedAreadIds = this.getSelectedDistrctsRelatedAreasIds(filters)
        // remove district realted area ids from selected area ids
        console.log('@415bc', location[LOCATION_AREA_ID], districtRelatedAreadIds)
        location[LOCATION_AREA_ID].forEach(areaId => {
          if (!districtRelatedAreadIds.includes(areaId)) {
            finalAreaIds.push(areaId)
          }
        })
      }

      console.log('@415', finalAreaIds)
      return finalAreaIds
    },
    getCombinedCategories (filters) {
      if (!filters) {
        filters = {}
      }
      let { 'category': selectedCategories } = filters
      const visualize = filters.visualize

      console.log('@547z', visualize)
      if (visualize) {
        const visualizeCategory = visualize.category
        const categoryOptions = filters.category

        console.log('@547@589a', visualizeCategory, categoryOptions)
        if (visualizeCategory && Object.keys(visualizeCategory).length) {
          const level_1_id = Object.keys(visualize.category)[0]
          if (!categoryOptions || !Object.keys(categoryOptions).length || !Object.keys(categoryOptions).includes(level_1_id)) {
          // categoryOptions = visualizeCategory
            selectedCategories = visualizeCategory
            console.log('@547@589b', selectedCategories)
          }
        }
      }

      console.log('@547zz', selectedCategories)
      return selectedCategories
    },
    async getCustomAttributeTags (filterValue) {
      const attributeTags = {}
      const { goodieBagId, type } = this.$route.query

      console.log('@492', filterValue)

      // if (!filterValue) { return }
      /*
      let categories = ''
      let brand = ''
      let offerType = ''
      let areas = ''
      let districts = ''

      if (filterValue && filterValue['category']) {
        Object.keys(filterValue['category']).forEach(item => {
          categories += `${item},`
        })
      }

      if (filterValue && filterValue['brand']) {
        Object.keys(filterValue['brand']).forEach(item => {
          brand += `${item},`
        })
      }
      if (filterValue && filterValue['offerType']) {
        Object.keys(filterValue['offerType']).forEach(item => {
          offerType += `${item},`
        })
      }

      if (filterValue && filterValue['location']) {
        if (filterValue['location'][LOCATION_AREA_ID] && filterValue['location'][LOCATION_AREA_ID]) {
          areas = filterValue['location'][LOCATION_AREA_ID].join(',')
        }
        if (filterValue['location'][LOCATION_DISTRICT_ID] && filterValue['location'][LOCATION_DISTRICT_ID]) {
          districts = filterValue['location'][LOCATION_DISTRICT_ID].join(',')
        }
      }

      const payload = {
        params: {
          channel: this.isWechatMiniProgram ? 'WeChat Mini Program' : '',
          goodie_bag: goodieBagId,
          categories: categories,
          brands: brand,
          offer_type_labels: offerType,
          type_in: type,
          sort: 'display_priority,id',
          'stores[areas]': areas,
          'stores[districts]': districts
        }
      }
*/
      const params = this.getQueryParams(filterValue)

      console.log('@544', filterValue, params)
      if (goodieBagId) {
        params.goodie_bag = goodieBagId
      }

      if (type) {
        params.type_in = type
      }

      // const payload = { ...params, goodie_bag: goodieBagId, type_in: type }

      console.log('@554', params, filterValue)
      const queryString = new URLSearchParams(params).toString()

      const customFilterOptionLabels = []
      const titleCustomRefs = {}
      const response = await axios.get(`/v2/attribute_tags?${queryString}`)
      if (response && response.data) {
        response.data.forEach(item => {
          if (item.key && !(item.key in attributeTags) && item.number_of_campaigns) {
            // console.log('@471c', item)
            if ((this.isExplorePage() && item.show_in_wechat_campaign_filter_level_1) ||
            (!this.isExplorePage() && item.show_in_goodie_bag_campaign_filter_level_1)) {
              // console.log('@471d')
              attributeTags[item.key] = item
              customFilterOptionLabels.push(item.key)
              titleCustomRefs[item.key] = item.name ? item.name : item.key
            }
          }
        })
      }

      this.titleCustomRefs = titleCustomRefs
      this.customFilterOptionLabels = customFilterOptionLabels
      this.customAttributeTags = attributeTags

      // console.log('@471g', this.customFilterOptionLabels)
    },

    getQueryParams (filters) {
      /*
      if (!filters) {
        return {}
      }
      */

      // useVisualizeCategory = false
      const params = {
        is_visible: true,
        type_in: ['GENERAL_MESSAGE_CAMPAIGN', 'COUPON_CAMPAIGN'],
        channel: getChannelName(),
        is_redirection_campaign: false,
        'page[number]': 1,
        'page[size]': 300
      }

      if (!filters) {
        return params
      }

      console.log('@547aaa', JSON.stringify(filters))

      const filterRefs = [
        { optionParams: 'brands', filterParam: 'brand', queryParam: 'brands' },
        { optionParams: 'categories', filterParam: 'category', queryParam: 'categories' },
        { optionParams: 'offerTypeLabels', filterParam: 'offerType', queryParam: 'offer_type_labels' }
      ]

      let level1SelectedTagIDList = []
      Object.keys(filters).forEach(item => {
        if (this.customFilterOptionLabels.includes(item) && filters[item]) {
          // console.log('@471q', item, filters[item])

          const tagIds = Object.keys(filters[item]) // walty: don't ask me why.. I just observed so in data structure
          if (tagIds.length) {
            level1SelectedTagIDList = [...level1SelectedTagIDList, ...tagIds]
          }
        }
      })

      console.log('@547', JSON.stringify(filters))
      if (level1SelectedTagIDList.length) {
        params['attribute_tags_level_1'] = level1SelectedTagIDList.join(';')
      }

      if (filters.location && filters.location[LOCATION_AREA_ID] && filters.location[LOCATION_AREA_ID].length) {
        params['stores[areas]'] = this.getFilerValueAreaIds(filters).join(',')
      }

      if (filters.location && filters.location[LOCATION_DISTRICT_ID] && filters.location[LOCATION_DISTRICT_ID].length) {
        params['stores[districts]'] = filters.location[LOCATION_DISTRICT_ID].join(',')
      }

      if (filters.searchKey) {
        params['search_campaign'] = filters.searchKey
      }

      console.log('@547c', params, filters.location)
      // console.log('@471p', filters)
      filterRefs.forEach(({ filterParam, optionParams, queryParam }) => {
        if (filterParam === 'category') {
          let selectedTagIDList = []

          let { [optionParams]: allCategory } = this
          allCategory = allCategory || []
          let { [filterParam]: selectedCategories } = filters

          selectedCategories = this.getCombinedCategories(filters)

          console.log('@547p@589', selectedCategories)

          selectedCategories = selectedCategories || {}
          const selectedCategoryIDList = []
          for (const categoryId of Object.keys(selectedCategories)) {
            const { 0: category } = allCategory.filter(item => item.id === parseInt(categoryId, 10))
            let { attribute_tags: attributeTags } = category || {}
            attributeTags = attributeTags || []
            const { [categoryId]: selectedAttributeTagIds } = selectedCategories
            const attributeTagIds = attributeTags.map(item => item.id)
            console.log('@547,selectedAttributeTagIds', category, allCategory, categoryId, selectedAttributeTagIds, attributeTagIds, attributeTagIds.every(tagId => selectedAttributeTagIds.includes(tagId)))
            if (attributeTagIds.every(tagId => selectedAttributeTagIds.includes(tagId))) {
              selectedCategoryIDList.push(categoryId)
            } else {
              selectedTagIDList = [...selectedTagIDList, ...selectedAttributeTagIds]
            }
            console.log('selectedTagIDList', selectedTagIDList)
          }
          if (selectedTagIDList.length > 0) {
            params['attribute_tags'] = selectedTagIDList
          }

          console.log('@547,@589c', selectedCategoryIDList)
          if (selectedCategoryIDList.length > 0) {
            params['categories'] = selectedCategoryIDList
          }
        } else {
          let { [optionParams]: allOptions } = this
          allOptions = allOptions || []
          let { [filterParam]: selectedList = [] } = filters
          const selectedIdList = []
          selectedList = selectedList || []

          console.log('@648', selectedList)
          selectedList.forEach(selected => {
            if (selected) {
              const option = allOptions.filter(option => option.name === selected)
              if (option[0] && option[0].id) {
                const { 0: { id: selectedId }} = option
                selectedIdList.push(selectedId)
              }
            }
          })
          if (selectedIdList && selectedIdList.length > 0) {
            params[queryParam] = selectedIdList
          }
        }
      })

      if (params.categories) {
        console.log('delete is_redirection_campaign')
        delete params.is_redirection_campaign
      }
      console.log('@547b', params)
      return params
    },
    async updateAllSubmenus (filterValue) {
      if (!this.previousFilterValueString) {
        this.previousFilterValueString = ''
      }

      // note that json-stable-stringify is used here
      if (stringify(filterValue) === this.previousFilterValueString) {
        return
      }

      this.previousFilterValueString = stringify(filterValue)

      console.log('@689@updateAllSubmenus', this.previousFilterValueString)
      await this.getCustomAttributeTags(filterValue)
      console.log('filterSubMenuChanged - updateAllSubmenus - location937 - clear', filterValue)
      this.updateFilteredResultCount({ filterValue, subMenu: 'category' })
      this.updateFilteredResultCount({ filterValue, subMenu: 'brand' })
      this.updateFilteredResultCount({ filterValue, subMenu: 'offerType' })
      this.updateFilteredResultCount({ filterValue, subMenu: 'location' })

      this.cachedResponse = {}
    },
    async filterSubMenuChanged ({ filterValue, subMenu, hasValueUpdated }) {
      console.log('@689', subMenu, filterValue, hasValueUpdated, JSON.stringify(filterValue))
      this.filterValue = { ...filterValue }
      this.filterSubMenu = subMenu

      if (!subMenu) { // back to home
        // if (hasValueUpdated) {
        await this.updateAllSubmenus(filterValue)
        // }
        return
      }

      console.log('@478k, filterSubMenuChanged', subMenu, filterValue)

      // await this.updateFilteredResultCount({ filterValue, subMenu })
    },
    async visualizeChanged ({ filterValue }) {
      // TODO walty: js shoud be always single thread, need to remove numberOfVisualizeThreads

      await this.updateAllSubmenus(filterValue)
    }
  }
}
</script>
