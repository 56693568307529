<template>
  <div
    v-if="isNoData"
    class="no-data"
  >
    <not-find />
  </div>
  <div v-else class="goodie-bag-main-container">
    <campaign-list-search-bar
      :class="$store.getters['app/isMobile']? '': 'desktop'"
      :goodiebag-name="goodieBagRecord.goodie_bag ? goodieBagRecord.goodie_bag.name : ''"
      :goodiebag-slot-id="slotId"
      @setsearchkey="searchKey = $event"
    />
    <div
      v-if="goodieBagRecord.goodie_bag && (goodieBagRecord.goodie_bag.name || goodieBagRecord.goodie_bag.short_description_in_campaign_listing_page)"
      :class="['goodie-bag-header-container', {'clipped': !expanded}]"
    >
      <div v-if="goodieBagRecord.goodie_bag.name" class="goodie-bag-title">
        {{ goodieBagRecord.goodie_bag.name }}
      </div>
      <div
        v-if="goodieBagRecord.goodie_bag.short_description_in_campaign_listing_page"
        ref="description"
        class="goodie-bag-description toastui-editor-contents"
        v-html="goodieBagRecord.goodie_bag.short_description_in_campaign_listing_page"
      />
      <div
        v-if="showSeeMore && !expanded"
        class="see-more"
        data-aid="goodie_bag_campaignlist_info_click"
        @click="toggleDescription"
      >
        {{ $t('goodiebagSeemore') }}
      </div>

    </div>

    <goodie-bag-banner
      :class="['credit-container', {mini: isWechatMiniProgram}]"
      :recordid="$route.query.recordId"
      :type="goodieBagType"
      :slotid="slotId.toString()"
    />

    <div class="goodie-bag-header-container" :class="$store.getters['app/isMobile']? 'mobile': 'desktop'">
      <explore-goodie-bag-label
        class="mobile"
        :item-type="$route.query.type"
        :credit-name="creditName"
        :quota-name="quotaName"
      />
      <div class="goodie-bag-label-line">
        <explore-goodie-bag-label
          class="desktop"
          :item-type="$route.query.type"
          :credit-name="creditName"
          :quota-name="quotaName"
        />
      </div>
      <campaign-list-search-bar
        :class="$store.getters['app/isMobile']? 'mobile': ''"
        :goodiebag-name="goodieBagRecord.goodie_bag ? goodieBagRecord.goodie_bag.name : ''"
        :goodiebag-slot-id="slotId"
        @setsearchkey="searchKey = $event"
      />

    </div>

    <sort-filter-bar
      ref="filter"
      v-model="filters"
      :filter-name="filterName"
      :filter-options="filterOptions"
      :filter-result-count="filterResultCount"
      :disabled="!storeItems || storeItems.length === 0"
      :custom-filter-option-labels="customFilterOptionLabels"
      :title-custom-refs="titleCustomRefs"
      :campaign-count="campaignCount"
      :campaign-settings="campaignSettings"
      :search-key="searchKey"
      :in-filtering="!campaignFetchFinished"
      @sub-menu-changed="filterSubMenuChanged"
      @visualize-changed="visualizeChanged"
      @input="inputChanged"
    />

    <!--<div class="result-count">
      {{ $t('goodiebagResults', {count: campaignCount}) }}
    </div>-->

    <div v-if="itemList && itemList.length > 0" class="voucher-list">
      <campaign-list-item
        v-for="item in itemList"
        :key="item.id"
        :goodie-bag="goodieBagRecord.goodie_bag"
        :item="item"
        data-aid="goodie_bag_campaign_click"
        :record-end-date="goodieBagRecord.coupon_acquire_end_date"
        :slotid="slotId"
        @click.native="handleClickCampaignItem(item)"
      />
    </div>
    <div v-else-if="campaignFetchFinished" class="empty-result">
      <search-campaign-no-result v-if="searchKey" />
      <div
        v-else-if="storeItems && storeItems.length > 0"
        class="empty-result-title"
      >
        {{ $t('goodiebagNoResultsFound') }}
      </div>
      <div
        v-else-if="$route.query.type === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG'"
        class="empty-result-title"
      >
        {{ $t('goodiebagNoLimiteedoffer') }}
      </div>
      <div
        v-else
        class="empty-result-title"
      >
        {{ $t('goodiebagNoVouch') }}
      </div>
      <router-link
        v-if="storeItems && storeItems.length === 0 && !searchKey"
        data-aid="goodie_bag_campaign_explore_click"
        tag="div"
        :to="$i18nRoute($route, 'home')"
        class="no-result-button primary-button"
      >
        {{ $t('goodiebagExploreOffer') }}
      </router-link>
    </div>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'
import sortFilterMixin from '@/mixins/sortFilterMixin'

import CampaignListItem from '@/components/CampaignListItem'
import SortFilterBar from '@/components/SortFilterBar'
import GoodieBagBanner from '@/components/GoodieBagBanner'
import ExploreGoodieBagLabel from '@/components/ExploreGoodieBagLabel'
import CampaignListSearchBar from '@/components/CampaignListSearchBar'
import SearchCampaignNoResult from '@/components/SearchCampaignNoResult'
import { fetchCampaigns } from '@/helpers/http'
import { isWechatMiniProgram, getChannelName, getGoodieBagCampaignSlot } from '@/utils/utils'
import NotFind from '@/components/NotFind'
import { LOCATION_AREA_ID, LOCATION_DISTRICT_ID } from '../utils/utils'
import { sendPageViewAnalytics, sendAdobeManualAnalytics } from '@/utils/tracking'
// import axios from 'axios'

export default {
  name: 'GoodieBagCampaignDetail',
  components: {
    CampaignListItem,
    SortFilterBar,
    GoodieBagBanner,
    ExploreGoodieBagLabel,
    NotFind,
    CampaignListSearchBar,
    SearchCampaignNoResult
  },
  mixins: [utilsMixin, backHandlerMixin, sortFilterMixin],
  data () {
    // const now = new Date()
    // const today = now.toISOString().split('T')[0]
    // const todayDate = new Date(today)

    return {
      expanded: false,
      showSeeMore: false,
      recordDetailCollected: false,
      filters: {
        sorting: 'featured',
        // validPeriod: { start: todayDate, end: todayDate },
        noExpiryDate: false
      },
      filterResultCount: {},
      campaignFetchFinished: false,
      filterValue: null,
      filterSubMenu: null,
      showSortDropdown: false,
      customFilterOptionLabels: [],
      titleCustomRefs: {},
      customAttributeTags: {},
      slotId: '',
      filterName: 'homepage-filter',
      searchKey: '',
      goodiebagRecordSlot: ''
    }
  },
  computed: {
    campaignSettings () {
      return {
        'category': this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_campaign_category_filter,
        'brand': this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_brand_filter,
        'offerType': this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_offer_type_label_filter,
        'location': this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_location_filter,
        'outOfStock': this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.hide_out_of_stock_campaign
      }
    },
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    isNoData () {
      const goodieBagRecord = this.goodieBagRecord
      console.log('recordDetailCollected', this.recordDetailCollected)
      if (!this.recordDetailCollected) {
        return false
      }

      if (!goodieBagRecord || Object.keys(goodieBagRecord).length === 0) {
        // Loading
        return true
      }

      const { goodie_bag: goodieBag } = goodieBagRecord
      let imageField = 'cover_photo_url'
      if (this.$store.getters['app/isMobile']) {
        imageField = 'mobile_photo_url'
      }
      const {
        [imageField]: coverPhotoUrl,
        name,
        short_description_in_campaign_listing_page: description
      } = goodieBag || {}
      return !coverPhotoUrl && !name && !description
    },
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    itemList () {
      return this.filterItems(this.filters)
    },
    campaignCount () {
      const campaignCount = this.itemList && this.itemList.length
      return campaignCount || 0
    },
    filterOptions () {
      let categoryOptions = []
      const attributeTagRefs = {}
      let creditOptions = []
      let brandOptions = []
      const brandIdMaps = {}
      let offerTypeOptions = []
      const areaOptions = []
      const districtOptions = []
      const categoryIDList = []
      const customOptions = this.customAttributeTags
      let hasStarred = false
      this.storeItems.forEach(item => {
        const {
          brand,
          offer_type_label: offerType,
          goodie_bag_reward: credit,
          stores
        } = item
        let {
          categories,
          attribute_tags: attributeTags
        } = item
        categories = categories && categories.length > 0 ? categories : []
        attributeTags = attributeTags && attributeTags.length > 0 ? attributeTags : []
        const { name: brandName, id: brandId } = brand || {}
        const { name: offerTypeName } = offerType || {}
        brandOptions.push(brandName)
        brandIdMaps[brandName] = brandId
        creditOptions.push(credit)
        offerTypeOptions.push(offerTypeName)

        if (stores) {
          stores.forEach((store) => {
          // console.log('@252a', store, store.district)
            if (store && store.district) {
              if (!districtOptions.some(item => item.id === store.district.id)) {
                districtOptions.push(store.district)
              }
              if (!areaOptions.some(item => store.district.region && item.id === store.district.region.id)) {
                areaOptions.push(store.district.region)
              }
            }
          })
        }
        categories.forEach((category) => {
          if (!categoryIDList.includes(category.id)) {
            categoryOptions.push(category)
            categoryIDList.push(category.id)
          }
        })
        attributeTags.forEach((tag) => {
          if (!(tag.id.toString() in attributeTagRefs)) {
            attributeTagRefs[tag.id] = tag
          }
        })
        if (item.is_starred) {
          hasStarred = true
        }
      })

      console.log('@252', areaOptions, districtOptions)

      categoryOptions.forEach((category) => {
        const { attribute_tags: campaignAttributeTags } = category
        if (campaignAttributeTags && campaignAttributeTags.length > 0) {
          // Fix API issue: attribute_tags in category don't have english_name
          category.attribute_tags = campaignAttributeTags.filter((tag) => tag.id && tag.id.toString() in attributeTagRefs)
          category.attribute_tags = category.attribute_tags.map(tag => attributeTagRefs[tag.id])
        }
      })

      console.log('creditOptions', creditOptions)

      console.log('categoryOptions', categoryOptions)

      categoryOptions = [...new Set(categoryOptions)]
      categoryOptions = categoryOptions
        .filter(category => !['', null, undefined].includes(category.name))
        // .sort((a, b) => a.name.localeCompare(b.name))

      categoryOptions = this.sortByDisplayPriority(categoryOptions)

      creditOptions = [...new Set(creditOptions)]
      creditOptions = creditOptions
        .filter(value => !['', null, undefined].includes(value))

      brandOptions = [...new Set(brandOptions)]
      brandOptions = brandOptions
        .filter(value => !['', null, undefined].includes(value))
        .sort((a, b) => a.localeCompare(b))
      offerTypeOptions = [...new Set(offerTypeOptions)]
      offerTypeOptions = offerTypeOptions
        .filter(value => !['', null, undefined].includes(value))
        .sort((a, b) => a.localeCompare(b))

      // let locationOptions = this.locations
      //  .filter(item => !['', null, undefined].includes(item.name))

      const locationOptions = [{ name: this.$t('goodiebagLocationFilterArea'), id: LOCATION_AREA_ID, attribute_tags: areaOptions },
        { name: this.$t('goodiebagLocationFilterDistrict'), id: LOCATION_DISTRICT_ID, attribute_tags: districtOptions }]

      const filterOptions = {
        sorting: [
          { label: this.$t('goodiebagFeatured'), value: 'featured' },
          { label: this.$t('goodiebagAlphabetical(aToz)'), value: 'name', shortLabel: this.$t('goodiebagAlpha...(aToz)') },
          { label: this.$t('goodiebagAlphabetical(zToa)'), value: '-name', shortLabel: this.$t('goodiebagAlpha...(zToa)') },
          { label: this.$t('goodiebagCredit(lowTohigh)'), value: 'goodie_bag_reward', shortLabel: this.$t('goodiebagCre...(lowTohigh)') },
          { label: this.$t('goodiebagCredit(highTolow)'), value: '-goodie_bag_reward', shortLabel: this.$t('goodiebagCre...(highTolow)') }
        ],
        category: this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_campaign_category_filter ? categoryOptions : [],
        brand: this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_brand_filter ? brandOptions : [],
        offerType: this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_offer_type_label_filter ? offerTypeOptions : [],
        customOptions,

        brandIdMaps,

        location: locationOptions
      }
      if (this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.show_credit_range_filter && creditOptions && creditOptions.length > 0) {
        filterOptions.creditRange = { max: Math.max(...creditOptions), min: Math.min(...creditOptions) }
      }

      if (this.$route.query.type === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
        filterOptions.sorting = [
          { label: this.$t('goodiebagFeatured'), value: 'featured' },
          { label: this.$t('goodiebagAlphabetical(aToz)'), value: 'name', shortLabel: this.$t('goodiebagAlpha...(aToz)') },
          { label: this.$t('goodiebagAlphabetical(zToa)'), value: '-name', shortLabel: this.$t('goodiebagAlpha...(zToa)') }
        ]
      }
      if (hasStarred) {
        filterOptions.sorting.splice(1, 0, { label: this.$t('campaignStarredCampaign'), value: '-is_starred', shortLabel: this.$t('campaignSta...Campaign') })
      }

      if (this.searchKey) {
        filterOptions.sorting = [{ label: this.$t('goodiebagRecommend'), value: 'search_order' }].concat(filterOptions.sorting)
      }
      console.log('filterOptions', filterOptions)

      return filterOptions
    },
    goodieBagType () {
      let type = 'Voucher'
      if (this.$route.query.type === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
        type = 'GIFT'
      }
      return type
    },
    goodieBagValidDateString () {
      const {
        coupon_acquire_start_date: startDate,
        coupon_acquire_end_date: endDate
      } = this.goodieBagRecord

      let itemAlias = 'cashVoucher'
      if (this.$route.query.type === 'GIFT_CAMPAIGN_FOR_GOODIE_BAG') {
        itemAlias = 'limitedOffer'
      }

      return this.getGoodieBagValidDateString(startDate, endDate, itemAlias)
    },
    storeItems () {
      const { type } = this.$route.query
      let { goodieBagId } = this.$route.query
      goodieBagId = parseInt(goodieBagId, 10)
      let items = []
      const testerPreview = this.$store.getters['auth/isTestUser'] || this.$route.query.tester_preview
      if (goodieBagId && type) {
        items = this.$store.getters['campaigns/goodieBagCampaignList'](goodieBagId, type, testerPreview, this.slotId.toString())
      }
      return items
    },
    goodieBagRecord () {
      const { recordId } = this.$route.query
      const goodieBagRecord = this.$store.getters['goodiebagparticipationrecords/recordDetail'](recordId)
      return goodieBagRecord || {}
    },
    campaignSlot () {
      return getGoodieBagCampaignSlot(this.goodieBagRecord, this.slotId)
    },
    creditName () {
      if (this.campaignSlot) {
        return this.campaignSlot.name
      } else if (this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.credit_name) {
        return this.goodieBagRecord.goodie_bag.credit_name
      }
      return this.$t('goodiebagCashVouchers')
    },
    quotaName () {
      if (this.campaignSlot) {
        return this.campaignSlot.name
      } else if (this.goodieBagRecord && this.goodieBagRecord.goodie_bag && this.goodieBagRecord.goodie_bag.quota_name) {
        return this.goodieBagRecord.goodie_bag.quota_name
      }
      return this.$t('goodiebagLimitedOffers')
    }
    // goodiebagRecordSlot () {
    //   const { recordId, goodieBagId, slotId } = this.$route.query
    //   console.log('@530: 434')
    //   return `${recordId}-${goodieBagId}-${slotId}`
    // }
  },
  watch: {
    'goodieBagRecord.goodie_bag.short_description_in_campaign_listing_page': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$nextTick(function () {
          this.checkAndShowSeeMore()
        })
      }
    },
    'goodieBagRecord.goodie_bag.cover_photo_url': function (newVal, oldVal) {
      const isMobile = this.$store.getters['app/isMobile']
      if (!isMobile && newVal && newVal !== oldVal) {
        this.$nextTick(function () {
          this.showGoodieBagImage(newVal)
        })
      }
    },
    'goodieBagRecord.goodie_bag.mobile_photo_url': function (newVal, oldVal) {
      const isMobile = this.$store.getters['app/isMobile']
      if (isMobile && newVal && newVal !== oldVal) {
        this.$nextTick(function () {
          this.showGoodieBagImage(newVal)
        })
      }
    },
    'filters.sorting': function (newVal, oldVal) {
      if (!this.isBackNavigation && newVal !== oldVal) {
        this.$store.commit('campaigns/clearGoodieBagCampaigns')
        this.campaignFetchFinished = false
        if (newVal === 'search_order') {
          this.getGoodieBagCampaigns(null, null, newVal)
        } else {
          this.getGoodieBagCampaigns()
        }
      }
    },
    'searchKey': function (newVal, oldVal) {
      console.log('500??? set to store', newVal)
      this.$store.commit('app/setLastGoodiebagCampaignListSearchKeyState', newVal)
      if (newVal !== oldVal && !this.isBackNavigation) {
        // this.filters = {}
        const onlyMobile = this.isMobile && !isWechatMiniProgram()
        const sourceFilter = { ...this.filters }
        let newFilters = { }
        if (newVal && !oldVal) {
          newFilters.sorting = 'search_order'
          // if (onlyMobile) {
          //   this.filters = { sorting: 'search_order' }
          // } else {
          //   this.filters.sorting = 'search_order'
          // }
        } else if (!newVal && oldVal) {
          newFilters.sorting = 'featured'
          // if (onlyMobile) {
          //   this.filters = { sorting: 'featured' }
          // } else {
          //   this.filters.sorting = 'featured'
          // }
        } else if (onlyMobile) {
          newFilters.sorting = 'search_order'
          // this.filters = { sorting: 'search_order' }
        }
        // else {
        //   this.$store.commit('campaigns/clearGoodieBagCampaigns')
        //   this.campaignFetchFinished = false
        //   this.getGoodieBagCampaigns()
        // }

        if (!onlyMobile) {
          newFilters = { ...this.filters, ...newFilters }
        }

        // if (onlyMobile) {
        //   this.filters = { ...newFilters }
        // } else {
        //   this.filters = { ...this.filters, ...newFilters }

        //
        // }

        console.log('500-start: ', newFilters.sorting === sourceFilter.sorting, newFilters.sorting, sourceFilter.sorting)
        if (newFilters.sorting === sourceFilter.sorting) {
          console.log('500')
          this.$store.commit('campaigns/clearGoodieBagCampaigns')
          this.campaignFetchFinished = false
          this.getGoodieBagCampaigns(null, null, newVal)
        }
        this.filters = { ...newFilters }
        console.log('??? reset filter...', newVal, ' - ', oldVal, this.isBackNavigation)
        // this.updateFilteredResultCount({ subMenu: 'location', filterValue: this.filters })
      }
    },
    'goodiebagRecordSlot': function (newVal, oldVal) {
      console.log('@@530: ')
      const { response_code: responseCode } = this.$route.query
      console.log('@@530: ', newVal, oldVal, responseCode)
      if (newVal !== oldVal && responseCode === '201') {
        this.$nextTick(function () {
          this.showMessage()
        })
      }
    }
  },
  mounted () {
    console.log('@ScarabQueue goodieBagCampaigns')
    this.emarsysSetCustomerId('goodieBagCampaigns')
    console.log('@433', this.isBackNavigation)

    this.$store.commit('app/setNavType', 'back_language_menu')

    sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie|list')
    if (!this.isBackNavigation) {
      this.$store.commit('campaigns/clearGoodieBagCampaigns')
      console.log('???1 list mounted', this.searchKey)
    } else {
      const { campaignCount, campaignFetchFinished, titleCustomRefs, customFilterOptionLabels,
        customAttributeTags, campaignSettings, searchKey } = this.$store.getters['app/getLastGoodiebagCampaignListState']
      this.campaignCount = campaignCount
      this.titleCustomRefs = titleCustomRefs
      this.customFilterOptionLabels = customFilterOptionLabels
      this.customAttributeTags = customAttributeTags
      this.campaignSettings = campaignSettings
      this.campaignFetchFinished = campaignFetchFinished
      this.searchKey = searchKey
      console.log('???2 list mounted', this.searchKey)
    }
    this.updateRecordDetailIfNeeded()

    if (this.isBackNavigation) {
      const filterValue = this.$store.getters['app/getFilterValue'](this.filterName)
      console.log('@491b, collected filter value', this.filterName, filterValue)
      if (filterValue) {
        this.filters = filterValue
      }
    }

    const { slotId } = this.$route.query
    if (!this.isBackNavigation) {
      this.getGoodieBagCampaigns()

      const { recordId, goodieBagId } = this.$route.query
      this.goodiebagRecordSlot = `${recordId}-${goodieBagId}-${slotId}`
    }

    this.getCustomAttributeTags()
    this.$nextTick(function () {
      const { goodie_bag: goodieBag } = this.goodieBagRecord || {}

      let imageField = 'cover_photo_url'
      if (this.$store.getters['app/isMobile']) {
        imageField = 'mobile_photo_url'
      }
      const { [imageField]: coverPhotoUrl } = goodieBag || {}

      if (coverPhotoUrl) {
        this.showGoodieBagImage(coverPhotoUrl)
      }
      this.checkAndShowSeeMore()
    })

    this.slotId = slotId.toString()
    setTimeout(() => { this.isBackNavigation = false }, 0)

    // console.log('@530: 434')
    // return `${recordId}-${goodieBagId}-${slotId}`
  },

  beforeDestroy () {
    console.log('??? before destroy')
    this.$store.commit('app/setNavType', 'logo_language_menu')
    this.$store.commit('app/hideGoodieBagImageUrl')
    this.$store.commit('app/setLastGoodiebagCampaignListState',
      {
        campaignCount: this.campaignCount,
        campaignFetchFinished: this.campaignFetchFinished,
        titleCustomRefs: this.titleCustomRefs,
        customFilterOptionLabels: this.customFilterOptionLabels,
        customAttributeTags: this.customAttributeTags,
        campaignSettings: this.campaignSettings
        // searchKey: this.searchKey
      }
    )
    const creditName = this.searchKey ? this.$t('searchResult') : this.$t('toSlotName', { creditName: this.creditName })
    this.$store.commit('app/setMiniProgramBackTitle', creditName)
  },

  methods: {
    handleClickCampaignItem (campaign) {
      // track goodie_bag_campaign_search_result_click click
      // sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_campaign_search_result_click', 'onclick')
    },
    sortByDisplayPriority (items) {
      if (items.length && items[0].display_priority) {
        // console.log('@461a', items)
        items = items.sort((a, b) => {
          return a.display_priority - b.display_priority
        })

        // console.log('@461b', items)
      }

      return items
    },
    inputChanged (filterValue) {
      console.log('@491@478pp', filterValue)
      this.$store.commit('app/setFilterValue', { filterName: this.filterName, filterValue })

      this.updateFilteredResultCount({ subMenu: 'location', filterValue: filterValue }, true)
    },
    showGoodieBagImage (coverUrl) {
      this.$store.commit('app/showGoodieBagImageUrl')
      this.$store.commit('app/setGoodieBagImageUrl', coverUrl)
    },
    async getGoodieBagCampaigns (pageNumber = null, prevResults = null, changeKeyword = null) {
      const { type } = this.$route.query
      let { goodieBagId, slotId, recordId } = this.$route.query
      goodieBagId = parseInt(goodieBagId, 10)
      const pageSize = 300
      pageNumber = pageNumber || 1

      let { sorting } = this.filters
      sorting = sorting || 'featured'

      const params = {
        lang: this.$i18n.locale,
        'page[number]': pageNumber,
        'page[size]': pageSize,
        search_campaign: this.searchKey ? this.searchKey.toLowerCase() : '',
        sort: sorting === 'recommended' ? 'search_order' : sorting === 'featured' ? ['is_out_of_stock', '-is_featured', '-id'] : sorting,
        type_in: [type],
        channel: getChannelName(),
        goodie_bag: goodieBagId
      }

      if (slotId) {
        slotId = parseInt(slotId, 10)
        params.goodie_bag_campaign_slot = slotId
      }
      if (recordId) {
        recordId = parseInt(recordId, 10)
        params.goodie_bag_record = recordId
      }

      let results = []
      let count = 0

      try {
        ({ count, results } = await fetchCampaigns(params, sorting))
        console.log('updateGoodieBagCampaign results', results)
      } catch (error) {
        console.log('error', error)
      }

      let campaigns = results || []
      if (results && prevResults) {
        campaigns = prevResults.concat(results)
      }

      if (results && results.length) {
        this.$store.commit('campaigns/updateGoodieBagCampaign', { goodieBagId, goodieBagCampaigns: campaigns, count })
      }

      // Get all results if goodie bag id is specified.
      if (results && results.length && results.length === pageSize) {
        this.getGoodieBagCampaigns(pageNumber + 1, campaigns)
      } else {
        this.campaignFetchFinished = true
        this.updateFilteredResultCount({ subMenu: 'location', filterValue: this.filters })
      }

      if (changeKeyword) {
        // track goodie_bag_campaign_search_result pv
        sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { 'pageName': 'goodie_bag_campaignlist_search_result', 'search_term': this.searchKey, 'search_result_count': count })
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie|keyword search', { 'search': { 'goodie_term': this.searchKey, 'goodie_result': count }})
        // if (count) {
        //   // track goodie_bag_campaign_search_success event
        //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_campaign_search_success', 'event')
        // } else {
        //   // track goodie_bag_campaign_search_unsuccess event
        //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie_bag_campaign_search_unsuccess', 'event')
        // }
      }

      return
    },

    getAreaOptions () {
      const { location: locationOptions } = this.filterOptions

      console.log('@499', locationOptions)
    },

    async updateFilteredResultCount ({ filterValue, subMenu }, isFilter = null) {
      console.log('filterSubMenuChanged', subMenu, filterValue, this.filters)
      /*
      if (!['category', 'brand', 'offerType', 'location'].includes(subMenu) || !filterValue) {
        return {}
      }
      */

      if (!['category', 'brand', 'offerType', 'location'].includes(subMenu)) {
        return {}
      }

      const { [subMenu]: options, brandIdMaps } = this.filterOptions
      const resultCount = {}
      const filters = { ...filterValue }

      delete filters[subMenu]

      // delete filters[subMenu]
      const itemList = this.filterItems(filters)
      if (subMenu === 'category') {
        options.forEach(category => {
          const tagsResultCount = {}
          let { attribute_tags: attributeTags } = category
          attributeTags = attributeTags || []
          const tagItemList = this.filterItems({ [subMenu]: { [category.id]: [] }}, itemList)
          tagsResultCount['all'] = tagItemList.length
          attributeTags.forEach(attributeTag => {
            const tagId = attributeTag ? attributeTag.id : null
            const tagList = attributeTag ? [attributeTag.id] : null
            const tagItemList = this.filterItems({ [subMenu]: { [category.id]: tagList }}, itemList)
            tagsResultCount[tagId] = tagItemList.length
          })
          resultCount[category.id] = tagsResultCount
        })
      } else if (subMenu === 'location') {
        /*
        const locationResult = this.convertLocationResultCount(itemList, filterValue)
        console.log('@531', locationResult)
        Object.assign(resultCount, locationResult)
        */

        // const districtDict = {}
        // const areaDict = {}

        const areaList = []
        // let totalItemsInStore = 0
        console.log('filterSubMenuChanged itemList: ', itemList)
        itemList.forEach((item) => {
          console.log('@532', item)
          /*
          if (item.stores && item.stores.length) {
            totalItemsInStore += 1
          }
          */

          if (!item.stores) {
            return true
          }
          item.stores.forEach((store) => {
            const districtId = store.district.id

            const areaId = store.district.region.id
            if (!areaList.some(item => item.id === areaId)) {
              areaList.push({ ...store.district.region, number_of_campaigns: 0, districts: [] })
            }
            const area = areaList.find(item => item.id === areaId)
            area.number_of_campaigns += 1

            if (!area.districts.some(item => item.id === districtId)) {
              area.districts.push({ ...store.district, number_of_campaigns: 0 })
            }
            const district = area.districts.find(item => item.id === districtId)
            district.number_of_campaigns += 1

            console.log('@574', areaList)
          })
        })

        // Object.assign(resultCount[subMenu],
        // this.convertLocationResultCount(areaList, filterValue))
        console.log('@574-2', areaList)
        Object.assign(resultCount, this.convertLocationResultCount(areaList, filterValue))
        this.removeHiddenLocationDistricts(filterValue)

        console.log('@583@574', filterValue, resultCount)

        /*
        resultCount[LOCATION_AREA_ID] = { ...areaDict, all: totalItemsInStore }
        resultCount[LOCATION_DISTRICT_ID] = { ...districtDict, all: totalItemsInStore }
        */
      } else {
        options.forEach(option => {
          const subMenuItemList = this.filterItems({ [subMenu]: [option] }, itemList)
          resultCount[option] = subMenuItemList.length
        })
      }

      console.log('@563', resultCount)
      this.filterResultCount = { ...this.filterResultCount, [subMenu]: resultCount }
      if (isFilter) {
        if (filterValue.subMenu === 'location') {
          let goodie_filter_level1 = ''
          if (filterValue.location.L_AREA?.length) {
            filterValue.location.L_AREA.forEach((item, index) => {
              if (index === 0) {
                goodie_filter_level1 += `A:${item}`
              } else {
                goodie_filter_level1 += `+A:${item}`
              }
            })
          }
          if (filterValue.location.L_DISTRICT?.length) {
            if (goodie_filter_level1) {
              goodie_filter_level1 += ','
            }
            filterValue.location.L_DISTRICT.forEach((item, index) => {
              if (index === 0) {
                goodie_filter_level1 += `D:${item}`
              } else {
                goodie_filter_level1 += `+D:${item}`
              }
            })
          }
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie|filter location', { 'goodie_filter_level1': goodie_filter_level1, 'goodie_filter_level2': '', 'goodie_result': itemList.length })
        } else if (filterValue.subMenu === 'category' && filterValue.selectedCategory) {
          if (filterValue.selectedCategory.id !== 0) {
            sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie|filter category', { 'goodie_filter_level1': `C:${filterValue.selectedCategory.id}`, 'goodie_filter_level2': '', 'goodie_result': itemList.length })
          }
        } else {
          let goodie_filter_level1 = ''
          if (filterValue.brand?.length) {
            filterValue.brand.forEach((item, index) => {
              const brandId = brandIdMaps[item] || ''
              if (index === 0) {
                goodie_filter_level1 += `B:${brandId}`
              } else {
                goodie_filter_level1 += `+B:${brandId}`
              }
            })
            sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie|filter brand', { 'goodie_filter_level1': goodie_filter_level1, 'goodie_filter_level2': '', 'goodie_result': itemList.length })
          }
        }
      }
    },
    filterItems (filters, items = null) {
      console.log('@569', filters, items)
      const {
        creditRange,
        brand: brandOptions,
        offerType: offerTypeOptions,
        validPeriod,
        noExpiryDate,
        // visualize,
        location = {}
      } = filters

      const { [LOCATION_DISTRICT_ID]: districtIds = [] } = location
      const areaIds = this.getFilerValueAreaIds(filters)

      // this.getFilerValueAreaIds(filters)
      console.log('@645', filters)
      /*
      let categoryOptions = filters.category
      let visualizeCategory
      if (visualize) {
        visualizeCategory = visualize.category
        if (visualizeCategory && Object.keys(visualizeCategory).length) {
          const level_1_id = Object.keys(visualize.category)[0]
          if (!categoryOptions || !Object.keys(categoryOptions).length || !Object.keys(categoryOptions).includes(level_1_id)) {
            categoryOptions = visualizeCategory
          }
        }
      }
      */

      const categoryOptions = this.getCombinedCategories(filters)

      // console.log('@521', filters)
      // console.log('@522', categoryOptions)

      const customOptions = {}

      Object.keys(filters).filter(key => key !== 'sorting').forEach(item => {
        if (this.customFilterOptionLabels.includes(item)) {
          Object.assign(customOptions, filters[item])
        }
      })

      const allItems = items || this.storeItems
      console.log('569: allItems count', allItems)
      console.log('allItems count', allItems.length)
      const itemList = allItems
        .filter(item => {
          const {
            brand,
            goodie_bag_reward: credit,
            offer_type_label: offerType
          } = item

          let { categories, attribute_tags: attributeTags } = item
          categories = categories || []
          attributeTags = attributeTags || []
          attributeTags = this.sortByDisplayPriority(attributeTags)

          let {
            end_date: endDate,
            start_date: startDate
          } = item
          endDate = endDate ? new Date(endDate) : new Date('9999-12-31')
          startDate = startDate ? new Date(startDate) : new Date('0000-01-01')

          const { name: brandName } = brand || {}
          const { name: offerTypeName } = offerType || {}
          let categoryMatched = true
          let customOptionsMatched = true
          const hasCategoryOptions = categoryOptions && Object.keys(categoryOptions).length > 0
          const hasCustomOptions = customOptions && Object.keys(customOptions).length > 0
          if (hasCategoryOptions) {
            categoryMatched = categories.some(({ id: categoryId }) => {
              const attributeTagOptions = categoryOptions[categoryId]
              if (!attributeTagOptions) {
                return false
              } else if (attributeTagOptions.length === 0) {
                return true
              } else {
                const availableCatgory = this.filterOptions.category && this.filterOptions.category.find(item => item.id === categoryId)
                const availableAttributeTags = availableCatgory && availableCatgory.attribute_tags || []

                // console.log('@772', availableAttributeTags, attributeTagOptions)

                if (availableAttributeTags.length === attributeTagOptions.length) {
                  // all options selected
                  return true
                }

                const attributeTagIds = attributeTags.map(item => item.id)
                return attributeTagIds.some(tagId => attributeTagOptions.includes(tagId))
              }
            })
          }
          if (hasCustomOptions) {
            customOptionsMatched = attributeTags.some(({ id: customOptionsId }) => {
              const attributeTagOptions = customOptions[customOptionsId]
              if (!attributeTagOptions) {
                return false
              } else if (attributeTagOptions.length === 0) {
                return true
              }
              return false
            })
          }

          let creditMatched = true
          const { max: creditMax, min: creditMin } = creditRange || {}
          if (creditMax) {
            creditMatched = credit <= creditMax
          }
          if (creditMatched && creditMin) {
            creditMatched = credit >= creditMin
          }

          let offerTypeMatched = true
          if (offerTypeOptions && offerTypeOptions.length > 0) {
            offerTypeMatched = offerTypeOptions.includes(offerTypeName)
          }

          let brandMatched = true
          if (brandOptions && brandOptions.length > 0) {
            brandMatched = brandOptions.includes(brandName)
          }

          let validDateMatched = true
          const { start: validStart, end } = validPeriod || {}
          const validEnd = this.$moment(end).add(1, 'days').toDate()
          if (noExpiryDate) {
            validDateMatched = !item.end_date
          } else if (validPeriod) {
            validDateMatched = (startDate >= validStart && startDate <= validEnd) ||
                (validStart >= startDate && validStart <= endDate)
          }
          if (!validDateMatched) {
            console.log('validStart', validStart)
            console.log('validEnd', validEnd)
            console.log('startDate', startDate)
            console.log('endDate', endDate)
          }

          let locationMatched = true

          const distrctRelatedAreas = []
          if (areaIds.length || districtIds.length) {
            locationMatched = false
            if (item.stores && item.stores.length) {
              item.stores.forEach((store) => {
                if (districtIds.length && districtIds.includes(store.district.id)) {
                  console.log('@715@703 distirct matched')
                  locationMatched = true
                  distrctRelatedAreas.push(store.district.region.id)
                  return false
                }

                if (areaIds.length && areaIds.includes(store.district.region.id)) {
                  console.log('@715@704 area matched')
                  locationMatched = true
                  return false
                }
              })
            }
          }
          let hideOutOfStockCampaign = false
          if (item.coupon_campaign_type_coupon_stock === 0 && this.campaignSettings.outOfStock === true) {
            hideOutOfStockCampaign = true
          }
          console.log('@569-919', item, areaIds, districtIds, locationMatched)
          return customOptionsMatched && categoryMatched && creditMatched && offerTypeMatched && brandMatched && locationMatched && !hideOutOfStockCampaign
        })

      console.log('569: itemList count', itemList.length)
      return itemList
    },
    async updateRecordDetailIfNeeded () {
      const { recordId } = this.$route.query
      console.log('update record detail if needed', recordId, this.recordDetailCollected)
      if (!recordId || this.recordDetailCollected) { return }
      try {
        await this.$store.dispatch('goodiebagparticipationrecords/updateRecord', recordId)
      } catch (error) {
        console.log('fail to get record', error)
      }
      this.recordDetailCollected = true
    },
    toggleDescription () {
      console.log('toggleDescription')
      this.expanded = !this.expanded
    },
    checkAndShowSeeMore () {
      if (this.$refs.description) {
        const { scrollHeight, clientHeight } = this.$refs.description
        console.log('check and show read more')
        if (scrollHeight > clientHeight) {
          this.showSeeMore = true
        }
      }
    },
    clearFilter () {
      this.filters = { sorting: 'search_order' }
      console.log('??? reset filter...968')
    },
    showMessage () {
      // const { is_created: isNewRedeemRecord } = this.$route.query
      // if (isNewRedeemRecord !== '201') {
      //   return
      // }
      const message = this.$t('goodiebagSuccessfullyUnlockedXBoth')

      let customClass = 'goodiebag-unlock-success'
      let offset = 73
      if (this.$store.getters['app/isMobile']) {
        customClass += ' mobile'
        offset = 61
      }

      this.$message({
        type: 'success',
        offset,
        duration: 5000,
        dangerouslyUseHTMLString: true,
        message,
        customClass
      })
    }
  }
}
</script>

<style lang="scss">
.el-message.goodiebag-unlock-success {
  background: #E6F4E7;
  border: 1px solid rgba(128, 189, 120, 0.1);

  .el-message__icon{
    font-size: 20px;
  }

  .el-message__content {
    color: #000000;
  }

  &.mobile {
    border-radius: 6px;
    min-width: calc(100vw - 40px);

    .el-message__content {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.goodie-bag-valid-date strong {
  color: #999999;
  font-weight: normal;
}

.credit-container.cash-vouchers-banner  .credit-text-small {
  font-size: 14px;
  line-height: 23px;
  z-index: 1;
}

.is-vue-desktop .credit-container.cash-vouchers-banner{
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 12px;
    top: 170px;

    .circle-big{
      bottom: 15px;
      right: 365px;
    }
    .circle-small{
      font-weight: 600;
      font-size: 15px;
      line-height: 24px;
    }
    .credit-count{
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;

      b {
        font-size: 30px;
        line-height: 35px;
      }
    }
    .credit-text{
      font-size: 20px;
      line-height: 27px;
    }
    .credit-text-small{
      font-size: 15px;
      line-height: 24px;
    }
    .line img{
      margin-right: 10px;
      margin-top: 6px;
    }
    .line.alarm{
      font-size: 15px;
      line-height: 23px;
      align-items: flex-start;
    }
  }

</style>

<style lang='scss' scoped>

.is-vue-desktop {

  .goodie-bag-main-container {
    position: relative;

    .goodie-bag-header-container {
      padding-bottom: 30px;

      &.desktop {
        padding-bottom: 0px;
      }

      .see-more {
        cursor: pointer;
        font-size: 15px;
      }

      .goodie-bag-title{
        padding-top: 30px;
        font-weight: bold;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        color: #000000;
      }

      .goodie-bag-description {
        margin-top: 20px;
        font-size: 15px;
        color: #000000;
      }

      .goodie-bag-title, .goodie-bag-valid-date, .goodie-bag-description{
        padding-left: 30px;
        padding-right: 30px;
      }

      .goodie-bag-title, .goodie-bag-valid-date {
        text-align: center;
      }
    }

    .result-count {
      font-size: 15px;
      line-height: 23px;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 10px;
      margin-top: -16px;
    }

    .explore-goodie-bag-container {
      display: flex;
      &.mobile {
        display: none;
      }
    }

    .credit-container{
      background: linear-gradient(270deg, #E93838 10.08%, #F93838 88.52%);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .circle-big, .circle-small {
        display: none;
      }
    }

    .voucher-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .voucher-detail {
        width: 225px;
        margin-top: 30px;

        &.featured {
          margin-top: 0px;
          margin-left: 11px;
        }
      }
    }
  }
}

.empty-result {
  margin-top: 24px;
  margin-bottom: 59px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-result-title {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 16px;
  }
  .no-result-button {
    border-radius: 50px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 10px;
  }
}

.result-count{
  padding-left: 21px;
  padding-right: 21px;
  font-size: 12px;
  line-height: 20px;
  color: #8B8B8B;
  margin-bottom: 13px;
}

.credit-container{
  overflow: hidden;
  position: sticky;
  display: flex;
  top: 72px;
  z-index: 29;
  margin-top: -0.5px;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  background: linear-gradient(270deg, #E93838 10.08%, #F93838 88.52%);

  &.mini {
    top: 48px;
    // top:0;
  }
}

.goodie-bag-label-line{
  display: flex;
}

.explore-goodie-bag-container {
  display: none;
  &.mobile {
    display: flex;
    padding-top: 24px;
    padding-bottom: 4px;
    // background-color: white;
  }
}

.goodie-bag-header-container {
  flex: 1;
  background-color: white;
  position: relative;
  padding-bottom: 0px;

  &.clipped {
    padding-bottom: 29px;

    .goodie-bag-description {
      max-height: 113px;
    }
  }

  .goodie-bag-banner {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  .goodie-bag-title{
    padding-top: 16px;
    padding-left: 19px;
    padding-right: 19px;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
  }

  .goodie-bag-valid-date {
    margin-top: 9px;
    padding-left: 19px;
    padding-right: 19px;
    font-size: 13px;
    line-height: 18px;
    color: #999999;
  }

  .goodie-bag-description{
    margin-top: 6px;
    padding-left: 19px;
    padding-right: 19px;
    flex: 1;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    overflow: hidden;

  }

  .see-more {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 14px;
    height: 88px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #E2002C;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.99) 44.31%, rgba(255, 255, 255, 0) 113.74%);
  }

}

</style>
