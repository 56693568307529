<template>
  <div
    v-if="isNoData"
    class="no-data"
  >
    {{ $t('ContentNotAvailable') }}
  </div>
  <div v-else class="campaign-detail-main-container">
    <div class="campaign-detail-section">
      <div class="campaign-detail-container">
        <div class="campaign-title center">{{ name }}</div>
        <div class="campaign-description toastui-editor-contents" v-html="description" />
      </div>
    </div>
    <div
      v-if="termsAndConditionsContent"
      class="campaign-detail-section"
    >
      <div class="term-and-condition-container">
        <div
          :class="['tc-title-container', {'has-content': termsAndConditionsContent}]"
          @click="toggleTerms"
        >
          <div class="tc-title">{{ termsAndConditionsTitle || $t('genericTerms&ConditionsGTConditions') }}</div>
          <div
            v-if="termsAndConditionsContent"
            class="toggle-arrow-container"
          >
            <img
              :class="['toggle-arrow', {'active': showTerms}]"
              srcset="/img/toggle.1x.png, /img/toggle.2x.png 2x, /img/toggle.3x.png 3x"
              src="/img/toggle.2x.png"
            >
          </div>
        </div>
        <div
          v-if="termsAndConditionsContent"
          :class="['campaign-terms-container', {'active': showTerms}]"
        >
          <div class="campaign-terms toastui-editor-contents" v-html="termsAndConditionsContent" />
        </div>
      </div>
    </div>

    <div v-if="!hasError && fetchFinished && !this.$route.query.tester_preview" class="campaign-button-container">
      <div
        v-if="haveStock && status === 'active'"
        class="campaign-button primary-button"
        data-aid="acquire_goodie_bag_click"
        @click="redeemGoodieBag"
      >
        {{ $t('goodiebagEntitlementLink2') }}
      </div>
      <div
        v-else-if="status === 'pending' "
        class="campaign-button disabled pending"
      >
        {{ $t('goodiebagAvailableOn', { date: availableTime }) }}
      </div>
      <div
        v-else-if="status === 'expired' "
        class="campaign-button disabled"
      >
        {{ $t('goodieBagExpired') }}
      </div>
      <div v-else class="campaign-button disabled">
        {{ $t('campaignOutOf') }}
      </div>
    </div>
    <div v-else-if="combinedResponseCode && combinedResponseCode==='error_grant_goodie_bag_invalid_country_code'" class="campaign-button-container">
      <div class="campaign-button-box">
        <div class="campaign-button disabled">
          {{ $t('goodiebagEntitlementLink2') }}
        </div>
        <div class="error-txt">{{ $t('goodiebagInvalidCountryCode') }}</div>
      </div>
    </div>
    <div
      v-else-if="combinedResponseCode && combinedResponseCode === 'error_grant_goodie_bag_coupon_out_of_stock'"
      class="campaign-button-container"
    >
      <div class="campaign-button-box">
        <div class="campaign-button disabled reward-unavailable-buttong-text">
          {{ $t('rewardNotReadyButton') }}
        </div>
        <div class="error-txt">{{ $t('rewardUnavailableMessage') }}</div>
      </div>
    </div>
    <el-dialog
      :visible.sync="showErrorDialog"
      class="fail-to-acquire-goodie-bag"
      :class="{'no-errorMessage': !errorMessage}"
      width="316px"
      top="calc(50vh - 93px)"
      :title="errorTitle"
      center
      :show-close="false"
      :close-on-click-modal="!couponOutOfStock"
    >
      <span v-if="errorMessage">{{ errorMessage }}</span>
      <span slot="footer" class="dialog-footer">
        <router-link
          v-if="rewardType==='FORTUNE_BAG' && (combinedResponseCode === 'error_grant_goodie_bag_already' ||
            combinedResponseCode === 'error_grant_goodie_bag_exceed_per_head_limit')"
          tag="button"
          type="button"
          :to="$i18nRoute($route, 'my-wallet-all', null, {})"
          class="el-button el-button--primary primary-button"
        >
          {{ $t('fortunebagAcquireGoodiebag') }}
        </router-link>
        <router-link
          v-else-if="combinedResponseCode === 'error_grant_goodie_bag_already' ||
            combinedResponseCode === 'error_grant_goodie_bag_exceed_per_head_limit'"
          tag="button"
          type="button"
          :to="$i18nRoute($route, 'my-reward')"
          class="el-button el-button--primary primary-button"
        >
          {{ $t('goodiebagAcquireGoodiebagA6') }}
        </router-link>
        <router-link
          v-else-if="combinedResponseCode === 'error_grant_goodie_bag_by_others'"
          tag="button"
          type="button"
          :to="$i18nRoute($route, 'home')"
          class="el-button el-button--primary primary-button"
        >
          {{ $t('goodiebagAcquireGoodiebagA3') }}
        </router-link>
        <el-button
          v-else-if="combinedResponseCode === 'error_grant_goodie_bag_coupon_out_of_stock'"
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="errorDialogClick"
        >
          {{ $t('rewardUnavailableButton') }}
        </el-button>
        <el-button
          v-else
          tag="button"
          type="button"
          class="el-button el-button--primary primary-button"
          @click="errorDialogClick"
        >
          {{ $t('goodiebagAcquireGoodiebagA3') }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title=""
      :visible.sync="isShowAgeRestricted"
      class="age-declaration"
      :width="$store.getters['app/isMobile']?'316px':'600px'"
      center
      custom-class="coupon-alert-bar-dialog"
      :show-close="false"
      :lock-scroll="true"
      :close-on-click-modal="false"
    >
      <div slot="title" class="age-declaration-title goodiebag-age-declaration-title" v-html="ageRestrictedTermsAndConditionsTitle" />
      <div class="age-declaration-content goodiebag-age-declaration-content toastui-editor-contents" v-html="ageRestrictedTermsAndConditionsContent" />
      <div class="age-declaration-bottom">
        <div class="button primary-button ok" @click="ageContentOkClick()">
          <div v-html="ageRestrictedPrimaryButtonName" />
        </div>
        <div class="button secondary-button cancel" @click="ageContentCancelClick()">
          <div v-html="ageRestrictedSecondaryButtonName" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'
import { getLangName } from '@/utils/lang'
import { isWechatMiniProgram, getCampaignAPIPrefix, replaceRouterWithQuery, setupDefaultQueryString } from '@/utils/utils'
import { sendPageViewAnalytics, sendManualAnalytics, sendAdobeManualAnalytics } from '@/utils/tracking'
import { loginSSO } from '@/helpers/http'
import axios from 'axios'

export default {
  name: 'GoodieBagCampaignDetail',
  mixins: [utilsMixin],
  data () {
    return {
      isNoData: false,
      startDate: null,
      goodieBagId: null,
      endDate: null,
      showErrorDialog: false,
      showTerms: false,
      name: null,
      description: null,
      termsAndConditionsTitle: null,
      termsAndConditionsContent: null,
      availableTime: null,
      haveStock: false,
      fetchFinished: false,
      stockMessage: null,
      responseCode: null,
      responseDetail: null,
      response: null,
      isShowAgeRestricted: false,
      isCloseAgeRestricted: false,
      ageRestrictedTermsAndConditionsTitle: null,
      ageRestrictedTermsAndConditionsContent: null,
      ageRestrictedPrimaryButtonName: null,
      ageRestrictedSecondaryButtonName: null
    }
  },
  computed: {
    status () {
      let status = 'active'
      const now = new Date()
      if (this.startDate && now <= new Date(this.startDate)) {
        status = 'pending'
      }
      if (this.endDate && now > new Date(this.endDate)) {
        status = 'expired'
      }
      return status
    },
    hasError () {
      if (this.stockMessage) { // even if not logged, if have stock message, means have error
        return true
      }

      const ssoUid = this.$store.getters['auth/ssoUid']

      if (!ssoUid) {
        return false
      }

      const responseCode = this.combinedResponseCode

      console.log('@157', responseCode)
      if (['409', '400', 400, 409].includes(responseCode) ||
          [500, '500'].includes(responseCode) ||
          (responseCode && responseCode.startsWith('error_'))) {
        return true
      }

      return false
    },
    errorMessage () {
      let errorMessage = null
      const responseCode = this.combinedResponseCode

      console.log('@171', responseCode, this.response)

      if (['409', '400', 400, 409].includes(responseCode)) {
        errorMessage = this.$t('goodieBagNotEntitlePrompt')
      } else if ([500, '500'].includes(responseCode)) {
        errorMessage = this.$t('generalServerError')
      } else if (responseCode === 'error_grant_goodie_bag_out_of_stock') {
        errorMessage = this.$t('goodieBagOutOfStockPrompt')
      } else if (responseCode === 'error_grant_goodie_bag_by_others') {
        // errorMessage = this.$t('goodiebagAcquireGoodiebagA2')
      } else if (responseCode === 'error_grant_goodie_bag_already') {
        // errorMessage = this.$t('goodiebagAcquireGoodiebagA5')
        console.log('@203', this.$route.query.response_detail, errorMessage)
      } else if (responseCode === 'error_grant_goodie_bag_exceed_per_head_limit') {
        errorMessage = '' // use title only
      } else if (responseCode === 'error_grant_goodie_bag_invalid_country_code') {
        errorMessage = '' // use title only
      } else if (responseCode === 'error_grant_goodie_bag_exceed_group_per_head_limit') {
        errorMessage = '' // use title only
      } else if (responseCode === 'error_grant_goodie_bag_coupon_out_of_stock') {
        errorMessage = this.$t('rewardUnavailableMessage')
      } else if (responseCode === 'error_grant_goodie_bag_exceed_star_per_head_owned_limit') {
        errorMessage = this.$t('notAddMywalletRewardMessage')
      }
      return errorMessage
    },
    errorTitle () {
      const responseCode = this.combinedResponseCode
      const responseDetail = this.combinedResponseDetail

      let errorTitle = this.$t('goodiebagAcquireGoodiebagA1')
      if (responseCode === 'error_grant_goodie_bag_by_others') {
        errorTitle = this.$t('goodiebagAcquireGoodiebagA2')
      } else if (responseCode === 'error_grant_goodie_bag_already') {
        errorTitle = this.$t('goodiebagAcquireGoodiebagA5')
      } else if (responseCode === 'error_grant_goodie_bag_exceed_per_head_limit') {
        errorTitle = this.$t('goodiebagPerheadLimitReached')
      } else if (responseCode === 'error_grant_goodie_bag_invalid_country_code') {
        errorTitle = this.$t('goodiebagInvalidCountryCode')
      } else if (responseCode === 'error_grant_goodie_bag_exceed_group_per_head_limit') {
        errorTitle = responseDetail
      } else if (responseCode === 'error_grant_goodie_bag_coupon_out_of_stock') {
        errorTitle = this.$t('rewardUnavailableTitle')
      } else if (responseCode === 'error_grant_goodie_bag_exceed_star_per_head_owned_limit') {
        errorTitle = this.$t('notAddMywalletRewardTitle')
      }
      return errorTitle
    },
    combinedResponseCode () {
      const { response_code: queryResponseCode } = this.$route.query
      const responseCode = queryResponseCode || this.responseCode || this.stockMessage

      return responseCode
    },
    combinedResponseDetail () {
      const responseDetail = this.responseDetail
      console.log('response-detail ', responseDetail)

      return responseDetail
    },
    rewardType () {
      const { goodie_bag_id: goodieBagId } = this.$route.query
      let rewardType = ''
      if (this.$store.getters['goodiebags/goodieBagDetail'](goodieBagId)) {
        rewardType = this.$store.getters['goodiebags/goodieBagDetail'](goodieBagId).reward_type
      }
      return rewardType
    },
    couponOutOfStock () {
      return this.combinedResponseCode === 'error_grant_goodie_bag_coupon_out_of_stock'
    }

  },
  watch: {
    '$store.state.app.OneTrustHeight': function () {
      if (!this.oneTrustHeight) {
        window.$('.coupon-alert-bar-dialog').css('margin-top', '15vh')
        window.$('.coupon-alert-bar-dialog .age-declaration-content').css('height', 'auto')
      }
    }
  },
  beforeDestroy () {
    this.$store.commit('app/hideGoodieBagImageUrl')
  },

  mounted () {
    console.log('@ScarabQueue goodieBag')
    this.emarsysSetCustomerId('goodieBag')
    this.getInitialData()

    this.$store.commit('app/showGoodieBagImageUrl')

    /*
    const { response_code: responseCode } = this.$route.query
    this.showErrorDialog = [
      'error_grant_goodie_bag_by_others',
      'error_grant_goodie_bag_already',
      'error_grant_goodie_bag_out_of_stock',
      '409',
      '400',
      '500'
    ].includes(responseCode)
    */

    if (this.hasError) {
      this.showErrorDialog = true
      const { goodie_bag_id: goodieBagId } = this.$route.query
      if (this.rewardType !== 'FORTUNE_BAG') {
        if (this.combinedResponseCode === 'error_grant_goodie_bag_already') {
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie|already acquired', { goodie_bag_id: goodieBagId })
        }
      } else {
        if (this.combinedResponseCode === 'error_grant_goodie_bag_coupon_out_of_stock') {
          sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'goodie|acquire unavailable', { goodie_bag_id: goodieBagId })
        }
      }
    }

    if (this.couponOutOfStock) {
      // track pv goodiebag(fortunebag)_out_of_stock_pop_up
      sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { 'couponOutOfStock': true })
    }
  },
  methods: {
    ageContentOkClick () {
      // track click click_exit_in_goodiebag_restriction_pop_up
      sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_continue_in_goodiebag_restriction_pop_up', 'onclick', {})

      this.enableOverflow()
      this.isShowAgeRestricted = false
      this.isCloseAgeRestricted = true
    },
    async getInitialData () {
      await Promise.all([this.getGoodieBagDetail(), this.getGoodieBagStock()])
      if (!this.$store.getters['auth/isTestUser'] && !this.$route.query.tester_preview) {
        await this.checkGoodieBagIsValid()
      }
      this.fetchFinished = true
    },
    async checkGoodieBagIsValid () {
      const ssoUid = this.$store.getters['auth/ssoUid']
      const { method: method } = this.$route.query

      console.log('@262', ssoUid, method)
      if (!ssoUid || !(method === 'A' || method === 'B' || method === 'C' || method === 'D') ||
          !this.haveStock || this.status !== 'active') {
        return
      }

      console.log('@262b', ssoUid)

      const {
        goodie_bag_id: goodieBagId,
        partner_id: merchantId,
        entitle_id_type: entitle_id_type,
        entitle_id: entitle_id,
        amount: amount,
        sign: sign,
        ts: ts,
        t: trackingCode
      } = this.$route.query
      const postData = {
        goodie_bag: goodieBagId,
        brand: merchantId,
        entitle_id_type: entitle_id_type,
        entitle_id: entitle_id,
        amount: amount,
        sign: sign,
        ts: ts,
        tracking_code: trackingCode,
        check_link: true
      }
      try {
        const response = await axios.post(getCampaignAPIPrefix() + `/customers/${ssoUid}/goodie_bag_participation_records?check_link=true`, postData)
        console.log('@695--- detail', response)
      } catch (error) {
        const response = error.response
        let responseCode = response.status
        let responseDetail = response.data.display_error_content
        if (responseCode === 409 && response.data && response.data.code) {
          responseCode = response.data.code
          responseDetail = response.data.display_error_content
        }
        this.responseCode = responseCode
        this.responseDetail = responseDetail
        this.response = error.response

        if ((responseCode === 'error_grant_goodie_bag_invalid_country_code' ||
        responseCode === 'error_grant_goodie_bag_exceed_group_per_head_limit' ||
      responseCode === 'error_grant_goodie_bag_exceed_per_head_limit') &&
      this.$root.detailNavigated) {
        // no need to show error dialog
        } else {
          this.showErrorDialog = true
        }

        if (responseCode === 'error_grant_goodie_bag_coupon_out_of_stock') {
          // track pv goodiebag(fortunebag)_out_of_stock_pop_up
          sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { 'couponOutOfStock': true })
        }
      }
    },
    toggleTerms () {
      if (this.termsAndConditionsContent) {
        this.showTerms = !this.showTerms
      }
    },
    async logCustomerActivityLog (goodieBagId, activityType) {
      try {
        const ssoUid = this.$store.getters['auth/ssoUid']
        const response = await axios.post(`/customers/${ssoUid}/activity_logs`, { activity_type: activityType, goodie_bag: goodieBagId })
        console.log('@logCustomerActivityLog', response)
      } catch (error) {
        console.log('@logCustomerActivityLog', error)
      }
    },
    async getGoodieBagStock () {
      const {
        goodie_bag_id: goodieBagId,
        partner_id: merchantId = ''
      } = this.$route.query
      try {
        const {
          data: {
            result: { have_stock: haveStock,
              have_coupon_stock: haveCouponStock,
              message: stockMessage }
          }
        } = await axios.get(`/goodie_bags/${goodieBagId}/check_stock?brand=${merchantId}`)
        this.haveStock = haveStock && haveCouponStock
        this.stockMessage = stockMessage

        if (this.stockMessage) {
          this.showErrorDialog = true
          if (this.stockMessage === 'error_grant_goodie_bag_coupon_out_of_stock') {
            this.logCustomerActivityLog(goodieBagId, 'GOODIE_BAG_ACQUIRE_FAIL_WITH_OUT_OF_STOCK')
          }
        }
      } catch (error) {
        console.log('fail to get stock', error)
      }
    },

    async getGoodieBagDetail () {
      const { goodie_bag_id: goodieBagId, t: trackingCode } = this.$route.query
      let notFound = false

      let imageField = 'cover_photo_url'
      if (this.$store.getters['app/isMobile']) {
        imageField = 'mobile_photo_url'
      }
      const { response_code: queryResponseCode } = this.$route.query
      let isNotAvaliableContent = {}
      try {
        await this.$store.dispatch('goodiebags/updateGoodieBag', { goodieBagId, trackingCode })
        const {
          name,
          id,
          short_description: description,
          start_date: startDate,
          end_date: endDate,
          display_end_date: displayEndDate,
          display_start_date: displayStartDate,
          [imageField]: coverPhotoUrl,
          generic_terms_and_conditions_content: termsAndConditionsContent,
          generic_terms_and_conditions_title: termsAndConditionsTitle,
          is_age_restricted: isAgeRestricted,
          age_restricted_primary_button_name: ageRestrictedPrimaryButtonName,
          age_restricted_secondary_button_name: ageRestrictedSecondaryButtonName,
          age_restricted_terms_and_conditions_content: ageRestrictedTermsAndConditionsContent,
          age_restricted_terms_and_conditions_title: ageRestrictedTermsAndConditionsTitle,
          tracking_code_is_active: trackingCodeIsActive
        } = this.$store.getters['goodiebags/goodieBagDetail'](goodieBagId)
        this.$store.commit('app/setGoodieBagImageUrl', coverPhotoUrl)
        if (!queryResponseCode && isAgeRestricted && !this.isCloseAgeRestricted) {
          this.isShowAgeRestricted = isAgeRestricted
          this.disableOverflow()
          setTimeout(() => { this.adjustAgeRestrictedPopupForOneTrust() }, 0)
        }
        this.name = name
        this.goodieBagId = id
        this.description = description
        this.termsAndConditionsTitle = termsAndConditionsTitle && termsAndConditionsTitle.trim()
        this.termsAndConditionsContent = termsAndConditionsContent
        this.startDate = startDate
        this.endDate = endDate
        this.ageRestrictedPrimaryButtonName = ageRestrictedPrimaryButtonName
        this.ageRestrictedSecondaryButtonName = ageRestrictedSecondaryButtonName
        this.ageRestrictedTermsAndConditionsContent = ageRestrictedTermsAndConditionsContent
        this.ageRestrictedTermsAndConditionsTitle = ageRestrictedTermsAndConditionsTitle
        if (this.$store.getters['app/isMobile']) {
          this.ageRestrictedPrimaryButtonName = ageRestrictedPrimaryButtonName.replace('\n', '<br>')
          this.ageRestrictedSecondaryButtonName = ageRestrictedSecondaryButtonName.replace('\n', '<br>')
        }
        if (startDate) {
          this.availableTime = this.getDateString(new Date(startDate), 'D MMM YYYY HH:mm')
        }
        const now = new Date()

        if (displayEndDate && now > new Date(displayEndDate)) {
          notFound = true
          isNotAvaliableContent = { title: 'goodiebagHasExpired' }
        } else if (displayStartDate && now < new Date(displayStartDate)) {
          notFound = true
          isNotAvaliableContent = { title: 'goodiebagHasNotStartedYet' }
        }

        if (!trackingCodeIsActive) {
          notFound = true
          isNotAvaliableContent = { title: 'goodiebagIsNotAvaliable' }
        }

        if (isAgeRestricted) {
          // track pv goodie_bag_restriction_pop_up
          sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { 'isAgeRestricted': true })
        }
      } catch (error) {
        const { data } = error.response || {}
        const { detail } = data || {}
        console.log('371--- error', error.response, detail)
        notFound = detail === 'Not found.'
      }

      if (!this.goodieBagId) {
        this.isNoData = true
        this.$store.commit('app/hideGoodieBagImageUrl')
      }

      if (notFound) {
        replaceRouterWithQuery(this.$router, { name: 'page-not-found', query: { ...isNotAvaliableContent, from: 'GOODIE_BAG' }})
      }
    },
    redeemGoodieBag () {
      const lang = getLangName(this.$i18n.locale)
      console.log('query----', window.location.search)
      let query = window.location.search
      if (query) { query += '&' }

      query += `lang=${lang}`

      let redeemUrl = `${process.env.VUE_APP_WALLET_URL}/redeem/${query}`
      const isLogin = localStorage.getItem('refreshToken')

      if (isWechatMiniProgram() && !isLogin) {
        loginSSO(redeemUrl)
        return
      }

      redeemUrl = setupDefaultQueryString(this.$route, redeemUrl)

      window.location.href = redeemUrl
    },

    errorDialogClick () {
      this.showErrorDialog = false
      if (this.combinedResponseCode === 'error_grant_goodie_bag_coupon_out_of_stock') {
        // track click click_OK_in_goodiebag(fortunebag)_out_of_stock_pop_up
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'click_OK_in_goodiebag(fortunebag)_out_of_stock_pop_up', 'onclick', {})
      }
    }

  }
}
</script>
<style lang="scss">
.fail-to-acquire-goodie-bag {
  [role="dialog"] {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    .el-dialog__header {
      padding:18px;
      padding-top: 30px;
      padding-bottom: 10px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #000000;
      }
    }
    .el-dialog__body {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 6px;
      padding-bottom: 0;
      display: flex;
      justify-content: center;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }
    .el-dialog__footer {
      padding-top: 24px;
      padding-bottom: 24px;
      .el-button--primary {
        padding-top: 8px;
        padding-bottom: 10px;
        padding-left: 39.5px;
        padding-right: 39.5px;
        border-radius: 50px;
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}

.fail-to-acquire-goodie-bag.no-errorMessage{
  .el-dialog__footer{
    padding-top: 8px;
  }
}

</style>
<style lang='scss' scoped>
.is-vue-desktop {
  .no-data {
    height: 50px;
  }

  .campaign-detail-section {
    margin-bottom: 8px;
    background-color: #FBFBFB;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .campaign-detail-container{
    width: 807px;

    .campaign-title {
      font-size: 30px;
      line-height: 41px;
      margin-bottom: 30px;
      max-width: 807px;
    }

    .campaign-description {
      max-width: 807px;
      font-size: 15px;
    }
  }

  .term-and-condition-container {
    width: 807px;

    .tc-title-container {
      max-width: 807px;
      flex: 1;
      justify-content: space-between;

      &.has-content {
        cursor: pointer;
      }

      .tc-title {
        font-size: 20px;
        line-height: 27px;
      }
    }

    .campaign-terms-container{
      max-width: 807px;
      max-height: 0px;

      .campaign-terms {
        padding-top: 30px;
        font-size: 15px;
        line-height: 23px;
      }
    }
  }

  .campaign-button-container{
    padding-top: 40px;
    padding-bottom: 40px;

    .campaign-button {
      font-size: 20px;
      line-height: 27px;

      &.disabled{
        cursor: default;
      }
    }

  }

}

.no-data{
  color: #E2002C;
  background: #FFE2E2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  height: 70px;
}

.campaign-detail-section {
  margin-bottom: 8px;
  background-color: #FBFBFB;
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.campaign-detail-main-container{
  background-color: #F7F7F7;
}
.campaign-banner-container {
  cursor: pointer;
  flex: 1;

  .campaign-banner {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;

    img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}

.campaign-button-container{
  background-color: #FBFBFB;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 40px;

  .campaign-button {
    padding-top: 8px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #FFFFFF;
    width: 277px;
    border-radius: 50px;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    display: flex;

    &.disabled{
      width: unset;
      padding-left: 43px;
      padding-right: 43px;
      background: #E5A1AE;
    }

    &.pending {
      padding-left: 36px;
      padding-right: 36px;
    }
    &.reward-unavailable-buttong-text{
      text-align: center;
    }
  }
}

.campaign-detail-container{
  width: 100%;

  .campaign-title {
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #2D2D2D;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
  }

  .campaign-description {
    font-size: 14px;
    line-height: 22px;
    color: #2D2D2D;
  }
}

.campaign-button-box{
  display: flex;
  align-items: center;
  flex-direction: column;
  .error-txt{
    font-weight: 600;
    font-size: 14px;
    color: #999999;
    font-style: normal;
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 12px;
  }
}

.term-and-condition-container {
  background-color: #FBFBFB;
  overflow: hidden;
  width: 100%;

  .tc-title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .tc-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      align-items: center;
      color: #05218A;
    }

    .toggle-arrow-container {
      justify-content: center;
      display: flex;
      align-items: center;
      .toggle-arrow {
        transition: transform 300ms ease-in-out;
        &.active {
          transform: rotate(180deg)
        }
      }
    }
  }

  .campaign-terms-container{
    transition: max-height 300ms ease-in-out;
    max-height: 0;

    &.active {
      max-height: 10000px;
    }

    .campaign-terms {
      padding-top: 16px;
      font-size: 14px;
      line-height: 22px;
      color: #2D2D2D;
    }
  }

}

</style>
