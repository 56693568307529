<template>
  <div v-if="canSubmitSurvey" class="feedback-container">
    <div v-if="!isShowFeedbackResult && !(coupon.coupon_used_transaction && coupon.coupon_used_transaction.transaction_amount_survey_value)" class="feedback-form-container">
      <div class="feedback-title">{{ $t('mycouponFeedback') }}</div>
      <div class="feedback-tips">{{ $t('mycouponTransactionAmount') }}</div>
      <div v-if="formOptions && formOptions.length" class="feedback-form">
        <el-radio-group v-model="price">
          <el-radio v-for="(item, index) in formOptions" :key="index" :label="item">
            {{ item }}
          </el-radio>
        </el-radio-group>
        <div class="feedback-form-actions">
          <div class="feedback-form-submit" data-aid="coupon_detail_survey_submit" @click="submitForm">{{ $t('mycouponSubmit') }}</div>
        </div>
      </div>
    </div>
    <div v-else class="feedback-result">
      <img class="feedback-result-icon" src="@/assets/images/feedback-result.png">
      <div class="feedback-result-tips">
        <div class="feedback-result-thankyou">{{ $t('mycouponSubmissionTitle') }}</div>
        <div class="feedback-result-tip">{{ $t('mycouponSubmissionDescription') }}</div>
      </div>
      <div class="feedback-result-actions">
        <div v-if="!$store.getters['app/isMobile']" class="back-to-home" @click="goHome">{{ $t('generalBacktoHome') }}</div>
        <div v-else class="view-other-offers" @click="goHome">{{ $t('generalBacktoHome') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import config from '@/config'
import axios from 'axios'
import { pushRouterWithQuery } from '@/utils/utils'

export default {
  name: 'FeedBack',
  data: function () {
    return {
      price: null,
      isShowFeedbackResult: false,
      couponId: null
    }
  },
  computed: {
    coupon () {
      console.log('try get coupon')
      let result = null
      if (this.couponId) {
        result = this.$store.getters['coupons/couponDetail'](this.couponId)
      }
      if (!result) {
        result = {}
      }
      console.log('51--- result---', result)
      return result
    },
    couponTemplate () {
      let result = null
      if (this.coupon) {
        result = this.coupon.template
      }
      console.log('50---- result', result)
      return result
    },
    canSubmitSurvey () {
      console.log('63----- submit')
      return this.couponTemplate && this.couponTemplate.require_transaction_amount_survey
    },
    formName () {
      let name = null
      if (this.couponTemplate && this.couponTemplate.transaction_amount_survey) {
        name = this.couponTemplate.transaction_amount_survey.name
      }
      return name
    },
    formOptions () {
      let formOptions = null
      if (this.couponTemplate && this.couponTemplate.transaction_amount_survey) {
        formOptions = this.couponTemplate.transaction_amount_survey.options
      }
      return formOptions
    },
    couponUsedTransaction () {
      let result = null
      if (this.coupon) {
        result = this.coupon.coupon_used_transaction
      }
      return result
    }
  },
  mounted () {
    console.log('@ScarabQueue feedback')
    this.emarsysSetCustomerId()
    this.$store.commit('app/setNavType', 'back_language')
    const query = this.$route.query
    console.log('query: ', query)
    if (query && (query.couponId || query.couponid)) {
      this.couponId = query.couponId || query.couponid
    }

    // console.log('@97, isShowFeedbackResult', this.isShowFeedbackResult)
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
  },

  methods: {
    submitForm () {
      console.log('50------ submit form', this.price)
      if (!this.couponUsedTransaction || !this.price) {
        return
      }
      const ssoUid = this.$store.getters['auth/ssoUid']
      axios.patch(`/customers/${ssoUid}/coupon_records/${this.couponUsedTransaction.id}`, {
        'transaction_amount_survey_value': this.price
      })
        .then(response => {
          console.log('response----', response)
          this.isShowFeedbackResult = true
        }).catch(error => {
          console.log('error--', error)
        })
    },
    goHome () {
      const lang = this.$i18n.locale
      console.log('67---- lang: ', lang)
      pushRouterWithQuery(this.$router, { path: `/${lang}/home` })
    }
  }
}
</script>
<style lang="scss">
.feedback-form-container {
    .el-radio-group {
        display: flex;
        flex-direction: column;
    }

    .el-radio:not(:first-child) {
        margin-top: 21px;
    }
    .el-radio__label {
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #000000;
    }
    .el-radio__inner {
        width: 18px;
        height: 18px;
    }

    .el-radio__input.is-checked + .el-radio__label {
        color: #000000;
    }
}

.is-vue-mobile {
    .feedback-form-container {

        .el-radio__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
        }

        .feedback-form-container .el-radio:not(:first-child) {
            margin-top: 20px;
        }
    }
}
</style>
<style scoped lang='scss'>

.feedback-container {
    margin-top: 30px;
}

.feedback-form {
    margin-top: 31px;
}

.feedback-form-actions {
    margin-top: 38px;
}

.feedback-result {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.feedback-result-icon {
    width: 138px;
    margin-top: 113px;
}

.feedback-result-tips {
    margin-top: 15px;
}

.feedback-result-tip {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: #404040;
    margin-top: 10px;
}

.feedback-result-thankyou {
    font-size: 20px;
    color: #4A4A4A;
    font-weight: bold;
}

.feedback-result-actions {
    display: flex;
    justify-content: center;
    margin-top: 26px;
    margin-bottom: 114px;
}

.back-to-home {
    // width: 196px;
    height: 48px;
    background: #E2002C;
    border-radius: 41px;
    font-weight: bold;
    font-size: 20px;
    line-height: 48px;
    text-align: center;
    color: #FFFFFF;
    padding-left: 30px;
    padding-right: 30px;
    cursor: pointer;
}

.feedback-form-submit {
    background: #E2002C;
    border-radius: 41px;
    // width: 154px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #FFFFFF;
    cursor: pointer;
    padding-left: 41px;
    padding-right: 41px;
    display: inline-block;
}

.feedback-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
}

.feedback-tips {
    margin-top: 10px;
    font-size: 15px;
    line-height: 23px;
    color: #000000;
}

.feedback-form-container {
    padding-bottom: 40px;
    padding-top: 30px;
    padding-left: 30px;
    box-shadow: 0px 2px 9px rgba(224, 224, 224, 0.34);
    border-radius: 8px;
    margin-top: 30px;
    background-color: #FFFFFF;
}

.is-vue-mobile {
    .feedback-result-tips {
        margin-top: 12.65px;
    }

    .view-other-offers {
        width: 178px;
        height: 48px;
        line-height: 48px;
        font-weight: bold;
        color: #FFFFFF;
        background: #E2002C;
        border-radius: 41px;
    }

    .feedback-result-thankyou {
        font-size: 22px;
        line-height: 30px;
        font-weight: bold;
        color: #000000;
    }

    .feedback-result-tip {
        margin-top: 12px;
        font-size: 14px;
        line-height: 22px;
    }

    .feedback-result-icon {
        margin-top: 102px;
        width: 150px;
    }

    .feedback-container {
        margin-top: 0px;
    }

    .feedback-form-container {
        margin-top: 0px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 91px;
    }

    .feedback-title {
        font-size: 22px;
        line-height: 30px;
    }

    .feedback-tips {
        margin-top: 16px;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
    }

    .feedback-form {
        margin-top: 23px;
    }

    .feedback-form-actions {
        margin-top: 48px;
        display: flex;
        justify-content: center;
    }

    .feedback-form-submit {
        width: 100%;
    }
}
</style>
<style>
.feedback-form .el-radio__label{
   white-space: break-spaces;
}
.feedback-form .el-radio__inner::after{
  background-color: #E2002C;
  width: 10px;
  height: 10px;
}
.feedback-form .el-radio__input.is-checked .el-radio__inner{
  background: #fff;
}
</style>
