<template>
  <div class="logout" />
</template>
<script>
// import { getSsoAuthClient } from '@/helpers/http'
import { doLogout } from '@/helpers/http'
export default {
  name: 'Logout',
  data: function () {
    return {

    }
  },
  async mounted () {
    const { redirectUrl: redirectUrl } = this.$route.query
    await doLogout(redirectUrl, true, false)
  },
  beforeDestroy () {

  },
  methods: {
  }
}
</script>
<style scoped lang='scss'>
</style>
