<template>
  <div class="preview-test">
    <div class="top-contain">
      <div class="line"><b class="title">Campaign ID: </b>{{ campaignId }}</div>
      <div class="line"><b class="title">Campaign Name: </b>{{ campaignName }}</div>
      <div class="line coupon"><b class="title">Coupon ID: </b>{{ couponId }}</div>
      <div class="line"><b class="title">Coupon Name: </b>{{ couponName }}</div>
    </div>
    <div class="store">
      <div class="store-title">
        <div class="left">Applicable stores</div>
        <div class="right">{{ applicableStoresList.length }} Stores</div>
      </div>
      <applicable-store
        :pagetype="applicableStoresPageType"
        :applicablestoreslist="applicableStoresList"
        :campaign-id="campaignId"
      />
    </div>
    <div class="bottom">
      <div class="use-coupon primary-button" @click="useCoupon">
        <div>Use Coupon</div>
      </div>
    </div>
    <div v-show="showCodeType==='qr-code'" id="scan-qr-code">
      <div class="qr-code-full-window">
        <div class="qr-contain full-width">
          <div class="full-width close-contain">
            <img class="close" src="/img/scan_close.png" width="40px" height="40px" @click="close()">
          </div>
          <div class="title-contain">
            {{ $t('myWalletScanQRcodeScanning') }}
          </div>
          <div class="title-contain">
            {{ $t('myWalletScanQRcode') }}
          </div>
          <div class="description-contain">
            {{ $t('myWalletScanQRcodeAlign') }}
          </div>
        </div>
        <div id="qr-code-full-region" />
      </div>
      <el-dialog
        title=""
        :visible.sync="isShowScanFail"
        width="316px"
        top="0vh"
        center
        custom-class="scan-alert-bar-dialog"
        :lock-scroll="true"
        :show-close="false"
      >
        <div slot="title" class="scan-alert-title">Failure to use</div>
        <div class="scan-alert-content">
          The coupon is not applicable in this store, please contact HKTB admin.
        </div>
        <div class="scan-alert-bottom">
          <div class="button" @click="isShowScanFail=false">{{ $t('goodiebagOk') }}</div>
        </div>
      </el-dialog>
    </div>
    <el-dialog
      title=""
      :visible.sync="isShowSuccess"
      width="330px"
      top="0vh"
      center
      custom-class="scan-alert-bar-dialog success"
      :lock-scroll="true"
    >
      <div class="qrcode-success-dialog-content">
        <!--
        <img class="qrcode-success-img" width="133px" src="/img/qrcode_success.png">
        -->
        <div class="qrcode-success-check">&check;</div>
        <div class="qrcode-success-description">Coupon can be used successfully</div>
        <div class="qrcode-success-description last">(For testing only)</div>
        <div class="qrcode-success-message">Related store:</div>
        <div class="qrcode-success-message data">{{ storeInfo.name }}</div>
        <div v-if="storeInfo.district" class="qrcode-success-message data">({{ storeInfo.district.name }})</div>
        <div class="qrcode-success-message">Redemption time: </div>
        <div class="qrcode-success-message data">{{ redeemedTime }}</div>
        <div v-if="$t('myWalletSuccessfullyRedeemedSuffix')" class="qrcode-success-description qrcode-successfully-redeemed-suffix">{{ $t('myWalletSuccessfullyRedeemedSuffix') }}</div>
      </div>
      <span slot="footer" class="scan-alert-bottom">
        <div class="button" @click="isShowSuccess = false">{{ $t('myWalletSuccessfullyRedeemedDone') }}</div>
        <!--<el-button type="primary" class="qrcode-success-done" @click="isQrcodeSuccessDialog = false">{{ $t('myWalletSuccessfullyRedeemedDone') }}</el-button>-->
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ApplicableStore from '@/components/ApplicableStore'
import utilsMixin from '@/mixins/utilsMixin'
import axios from 'axios'
import { isDev } from '@/utils/utils'
import { sendManualAnalytics } from '@/utils/tracking'

export default {
  components: {
    ApplicableStore
  },
  mixins: [utilsMixin],
  data () {
    return {
      showCodeType: 'normal',
      applicableStoresPageType: 'list',
      lockScan: false,
      html5Qrcode: null,
      isShowScanFail: false,
      isShowSuccess: false,
      storeInfo: {},
      redeemedTime: '',
      campaignData: null,
      campaignId: ''
    }
  },
  computed: {
    /*
    couponData () {
      return this.$store.getters['coupons/couponDetail'](this.couponId)
    },
    */
    couponId () {
      if (this.campaignData && this.campaignData.coupon_campaign_type_coupon_template) {
        return this.campaignData.coupon_campaign_type_coupon_template.test_coupon_id
      }

      return '888888'
    },

    campaignName () {
      return this.campaignData ? this.campaignData.name : ''
    },
    couponName () {
      if (this.campaignData && this.campaignData.coupon_campaign_type_coupon_template) {
        return this.campaignData.coupon_campaign_type_coupon_template.name
      }
      return ''
    },
    applicableStoresList () {
      if (this.campaignData && this.campaignData.stores) {
        return this.formatApplicableStores(this.campaignData.stores)
      }

      return []
    }
  },
  mounted () {
    console.log('@ScarabQueue previewtest')
    this.emarsysSetCustomerId()
    const { campaignId } = this.$route.query

    if (campaignId) {
      this.campaignId = campaignId

      axios.get(`/campaigns/${campaignId}?test_use_coupon=true`).then(response => {
        console.log('response---', response)
        this.campaignData = response.data
        if (isDev() && this.campaignData && this.campaignData.name) {
          this.campaignData.name = this.campaignData.name.replace('<DATETIME>', (new Date().getTime()))
        }
        this.$store.commit('campaigns/updateCampaignDetail', { id: campaignId, detail: this.campaignData })
      }).catch(error => {
        console.log('371--- errro', error)
        const { data } = error.response || {}
        const { detail } = data || {}
        console.log('371--- errro', error.response, detail)

        if (detail === 'Not found.') {
          alert('Campaign not found.')
        }
      })
    }

    // this.testUserCoupon({ store_qr_code: '0e731514-79a2-11ec-815a-0a58a9feac02', coupon_id: '13407899' })

    const that = this
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      window.location.origin + '/js/html5-qrcode.min.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
    window.onresize = () => {
      return (() => {
        if (that.showCodeType === 'qr-code') {
          setTimeout(() => { this.initQrcode() }, 0)
        }
      })()
    }
    this.$store.commit('app/setNeedShowBackToTop', false)
  },
  destroyed () {
    this.$store.commit('app/setNeedShowBackToTop', true)
  },
  methods: {
    initQrcode () {
      const config = {
        fps: 10,
        qrbox: 250,
        aspectRatio: 1.777778
      }
      console.log('487------ uri init qrcode')
      this.html5Qrcode = new window.Html5Qrcode('qr-code-full-region')
      console.log(this.html5Qrcode)
      this.lockScan = false
      this.html5Qrcode.start(
        { facingMode: 'environment' }, config, this.onScanSuccess, this.onScanError
      ).catch((err) => {
        console.log('@1324', err)
        sendManualAnalytics(this.$route, this.$i18n.locale, this.$store,
          'coupon_camera_fail', 'event',
          { id: this.campaignId, camera_errormsg: JSON.stringify(err) })

        alert('Error: ' + JSON.stringify(err))
      })
    },
    useCoupon () {
      this.showCodeType = 'qr-code'
      setTimeout(() => { this.initQrcode() }, 0)
    },
    close () {
      setTimeout(() => {
        this.html5Qrcode.stop()
      }, 0)
      this.showCodeType = 'normal'
    },
    async onScanSuccess (qrCodeMessage) {
      console.log('qr code message', qrCodeMessage, this.couponData)
      if (this.lockScan) {
        return
      }
      let response = null
      this.lockScan = true
      try {
        response = await this.testUserCoupon({ store_qr_code: qrCodeMessage, test_use_coupon: true })
      } catch (error) {
        console.log('error', JSON.stringify(error))
        this.isShowScanFail = true
        this.lockScan = false
        return
      }

      this.lockScan = false
      this.html5Qrcode.stop()
      const { data } = response
      const { store, used_at } = data
      this.redeemedTime = this.getDateString(used_at, 'D MMM YYYY HH:mm:ss')
      this.storeInfo = store
      this.isShowSuccess = true

      this.showCodeType = 'normal'
    },
    onScanError (errorMessage) {
      console.log('5470----- error Message', errorMessage)
      // this.isQrcodeErrorDialog = true
      this.error = errorMessage
    },
    async testUserCoupon (payload) {
      const couponId = payload.coupon_id || this.couponId
      const response = await axios.post(`/coupons/${couponId}/test_usage_record?test_use_coupon=true`, payload)
      // const { data } = response
      // const { coupon } = data
      console.log('response', response)
      // this.$store.commit('coupons/updateCoupon', coupon)
      return response
    }
  }

}
</script>
<style scoped lang='scss'>
.top-contain{
    padding: 14px;
    background: #FFFFFF;
    .title{
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        color: #000000;
    }
    .line{
        font-weight: 700;
        color: #8C8C8C;
        line-height: 28px;
    }
    .line.coupon{
        margin-top: 10px;
    }
}
.store{
    margin-top: 8px;
    background: #FFFFFF;
    padding-bottom: 80px;
    .store-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 20px;
        padding-bottom: 7px;
        .left{
           color: #05218A;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
        }
        .right{
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #8C8C8C;
        }
    }
}
.bottom{
    width: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
    .use-coupon{
      border-radius: 24px;
      height: 48px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}
#scan-qr-code{
  z-index: 999; // qr code scanner box

  .qr-code-full-window {
    position: fixed !important;
    margin: 0;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 30;
    background-color: #7B7B7B;
    display: flex;
    align-items: center;
    flex-flow: column;
  }
  #qr-code-full-region {
    width: 100%;
    position: fixed !important;
  }
  .full-width{
    width: 100%;
  }
  .close-contain{
    justify-content: flex-end;
    display: flex;
    margin-bottom: -18px;
  }
  .close-contain img{
    height: 40px;
  }
  .close{
    float: right;
    margin-right: 21px;
    margin-top: 19px;
  }
  .title-contain{
    font-weight: bold;
    font-size: 22px;
    color: #FFFFFF;
    padding-left: 28px;
  }
  .description-contain{
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF;
    padding-left: 28px;
    margin-top: 12px;
  }
  .qr-contain{
    z-index: 40;
  }
}
.scan-alert-bar-dialog{
  .scan-alert-title{
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #2D2D2D;
  }
  .scan-alert-content{
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #040404;
    word-break: break-word;
  }
  .scan-alert-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    .button{
      background: #E2002C;
      border-radius: 41px;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      padding-left: 57px;
      padding-right: 57px;
      color: #FFFFFF;
      border: none;
    }
    .button:hover{
        background: #AF0023;
    }
  }
  .qrcode-success-img{
    margin-bottom: 8px;
  }
  .qrcode-success-check{
    margin-bottom: 8px;  /* 44px - 16px */
    margin-top: 21px;
    color: #04BD81;
    font-size: 80px;
    font-weight: 300;
    line-height: 108.95px;
    font-family: Open Sans, Microsoft JHei TC Regular, arial, sans-serif;
  }
  .qrcode-success-dialog-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
  }
  .qrcode-success-description{
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    color: #000000;
  }
  .qrcode-success-description.last{
    margin-bottom: 8px;
  }
  .qrcode-success-message{
    font-size: 16px;
    line-height: 28px;
    color: #040404;
  }
  .qrcode-success-message.data{
    color: #575757;
    text-align: center;
  }
}
</style>
<style>
.el-dialog.scan-use-confirm-dialog, .el-dialog.scan-alert-bar-dialog{
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}
.preview-test .el-dialog__wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-test .scan-alert-bar-dialog .el-dialog__header{
  padding-top: 27px;
}
.preview-test .scan-alert-bar-dialog .el-dialog__body{
  padding-top: 0px;
  padding-left: 15px;
  padding-right: 15px;
}
.preview-test .scan-alert-bar-dialog.success .el-dialog__footer{
  padding-top: 0px;
}
.preview-test .scan-alert-bar-dialog.success .el-dialog__body{
  padding-bottom: 0px;
}
.preview-test .scan-alert-bar-dialog.success .el-dialog__close.el-icon.el-icon-close{
  font-size: 25px;
    font-weight: bold;
}
.preview-test .scan-alert-bar-dialog.success .el-dialog__header{
  padding-top: 20px;
}
</style>
