
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhsLocale from 'element-ui/lib/locale/lang/zh-CN'
import zhtLocale from 'element-ui/lib/locale/lang/zh-TW'
import { isWechatMiniProgram } from '@/utils/utils'

const messages = {
  'en': {
    'navBarMenu': 'Menu',
    'navBarMenuAlert': 'Notifications',
    'navBarExplore': 'Deals',
    'navBarWallet': 'My Wallet',
    'navBarRewards': 'My Rewards',
    'navDeals': 'DEALS',
    'navDealsLow': 'Deals',
    'homeSignUp': 'Login or sign up to explore more offers',
    'homeShowMore': 'Show more',
    'homeExploreMore': 'Deals',
    'homeTopOfferTitle': 'Deals',
    'homeTopOfferViewMore': 'View more offers',
    'linkCopied': 'Link copied, you may paste and view in web browsers.',
    'linkCopiedShort': 'Link copied',
    'codeCopiedShort': 'Code copied',
    'homeDiscoverOffer': 'Discover your offers today!',
    'homeBrowseOffer': 'Start browsing and acquire yours!',
    'headerlanguage': '语言',
    'headerHome': 'HOME',
    'headerHomeLow': 'Home',
    'headerMyWallet': 'My Wallet',
    'headerMyWalletLow': 'My Wallet',
    'headerDearMember': 'Valued user',
    'homeMyWallet': 'My Wallet',
    'headerMyRewards': 'MY REWARDS',
    'headerMyRewardsSingle': 'My Rewards',
    'headerMyAccount': 'MY ACCOUNT',
    'headerMyAccountLow': 'My Account',
    'headerHelloName': 'Hello, ',
    'headerLoginSignup': 'Login / Sign up',
    'headerLogout': 'LOG OUT',
    'headerLogoutLow': 'Log Out',
    'generalLogoutPromptTitle': 'Logout?\r',
    'generalLogoutPromptDescription': 'Are you sure you want to log out?',
    'generalLogoutPromptCancel': 'Cancel',
    'generalLogoutPromptLogout': 'Logout',
    'showLanguageReminderTitle': 'Hint',
    'showLanguageReminderContent': 'Would you like to switch to the English version?',
    'showLanguageReminderOk': 'Yes',
    'showLanguageReminderCancel': 'No',
    'footerTermsConditions': 'Terms of use',
    'miniNavTermsConditions': 'Terms of use',
    'footerPrivacyPolicy': 'Privacy policy',
    'footerFAQ': 'FAQ',
    'footer2021HKTBAllRightsRecerved': 'Copyright © {year} Hong Kong Tourism Board. All rights reserved.',
    'footerReCAPTCHA': 'This site is protected by reCAPTCHA and the Google:',
    'footerRePrivacyPolicy': 'Privacy Policy',
    'footerTermsOfService': 'Terms of Service',
    'myWalletMyVouchers': 'My Rewards',
    'myWalletMyCoupons': 'My coupons',
    'myWalletCoupons': 'Coupons',
    'myWalletTickets': 'Tickets',
    'myWalletAllStatus': 'All status',
    'myWalletAvailableAndUnavailable': 'Available / Current unavailable',
    'myWalletRedeemedExpired': 'Redeemed / Expired',
    'myWalletRecentlyAdded': 'RECENTLY ACQUIRED',
    'myWalletRedeemed': 'REDEEMED',
    'myWalletExpired': 'EXPIRED',
    'myWalletNoExpiryDate': 'No expiry date',
    'myWalletLastDay': 'Last day',
    'myWalletStartDate': 'Start Date: ',
    'myWalletXDaysLeft': '{dayCount} days left',
    'myWalletExpiryDate': 'Expires on: ',
    'myWalletEffectiveDate': 'Valid from {startDate} to {endDate}',
    'myWalletEffectiveDateLessOneDay': 'Valid on {endDate}',
    'myWalletEmptyCouponsMessage': 'Oops! No coupons here. Browse around and get the latest offers now!',
    'myWalletDiscover': 'Discover more',
    'myWalletValidTime': 'Valid till ',
    'myWalletRedeemedTime': 'Redeemed on: {dateString}',
    'myWalletApplicableStores': 'Applicable stores',
    'myWalletSeeMore': 'See more',
    'myWalletUserExperienceMessage': 'Please scan this QR code to use the coupon on your mobile device.',
    'myWalletUserExperienceTicketMessage': 'Please scan this QR code to use the ticket on your mobile device.',
    'myWalletScanQRcodeMessage': 'Scan this QR code to view the coupon details on mobile.',
    'myWalletScanQRcodeTicketMessage': 'Scan this QR code to view the ticket details on mobile.',
    'myWalletAvailableTime': 'This coupon can be used from',
    'myWalletTicketAvailableTime': 'This ticket can be used from',
    'myWalletAvailableTimeSuffix': '',
    'myWalletRedeemNow': 'Redeem now',
    '//myWalletStarredAlert': 'Tips: You already hold <b>{count}</b> unused Music Show Ticket(s). You can only hold a maximum of <b>{limit}</b> unused Music Show Ticket(s) at one time.',
    'myWalletStarredAlert': 'Tip: A maximum of <span><b>{limit}</b></span> ‘Hong Kong Night Treats for Locals’ dining voucher can be acquired and saved in ‘My Wallet’. Please be reminded you have <span><b>{count}</b></span> unused dining voucher.',
    'acquireUseTicketOnMobile': 'Please use the ticket on your mobile device.',
    'myAccountAccountInformation': 'Account information',
    'myAccountFirstName': 'First name',
    'myAccountLastName': 'Last name',
    'myAccountNickname': 'Nickname',
    'myAccountIam': 'Please enter',
    'myAccountEmail': 'Email',
    'myAccountResidence': 'Residence',
    'myAccountIamFrom': 'Please select',
    'myAccountSalutationLabel': 'Salutation',
    'myAccountSalutation': 'Please select',
    'myAccountCountry': 'Country',
    'myAccountMobileNo': 'Mobile',
    'myAccountBirthMonthYear': 'Birth month',
    'myAccountOptional': '(Optional)',
    'myAccountSelectBirthdate': 'Please select',
    'myAccountEditBirthprompt': "This cannot be edited once it's confirmed.",
    'myAccountUpdate': 'Update',
    'myAccountAccountSettings': 'Account settings',
    'myAccountLanguagePreference': 'Language',
    'myAccountEditLanguageRegion': 'Please select',
    'myAccountInterestPreference': 'Interest',
    'myAccountDirectMarketing': 'Direct Marketing',
    'myAccountDirectMarketinghints': 'I would like to receive direct marketing news and information and personalised messages from the Hong Kong Tourism Board.',
    'myAccountChangePassword': 'Change password',
    'myAccountCurrentPassword': 'Current password',
    'myAccountNewPassword': 'New password',
    'myAccountPasswordErrormessage': 'Minimum 8 characters, including numbers AND capital letters',
    'myAccountDeleteAccount': 'Delete my account',
    'myAccountDeleteAccountPrompt': 'This would permanently delete your account and all your coupons and data. ',
    'myAccountDelete': 'Delete',
    'myAccountUnsavedPromptTile': 'You have unsaved changes',
    'myAccountUnsavedPromptDescription': 'Save the changes before leaving?',
    'myAccountUnsavedPromptDiscardchanges': 'Discard changes',
    'myAccountUnsavedPromptSave': 'Save',
    'myAccountBirthConfirmPromptTitle': 'Confirm?',
    'myAccountBirthConfirmPromptDescription': 'You cannot edit your birth month after confirmation.',
    'myAccountBirthConfirmPromptCancel': 'Cancel',
    'myAccountBirthConfirmPromptConfirm': 'Confirm',
    'myAccountSuccessfullyUpdated': 'Successfully updated!',
    'myAccountDeleteAccountconfirmPromptTitle': 'Delete your account',
    'myAccountDeleteAccountconfirmPromptDescription': 'Are you sure you want to permanently delete this account and all its data? After the account is deleted, all acquired coupons and tickets will be permanently removed and invalidated.',
    'myAccountDeleteAccountconfirmPromptCancel': 'Cancel',
    'myAccountDeleteAccountconfirmPromptConfirm': 'Confirm',
    'myAccountDeleteAccountconfirm2PromptTitle': 'To continue, please verify it’s you',
    'myAccountDeleteAccountconfirm2PromptDescription': 'A link has been sent to your email account, please check and continue the process.',
    'myAccountDeleteAccountconfirm2PromptOK': 'OK',
    'myAccountDeleteAccountconfirm2PromptTitleMobile': '',
    'myWalletAcquiredVouchar': 'Congratulations! You’ve acquired the coupon(s) successfully! ',
    'myWalletAcquiredCoupons': 'Congratulations! You’ve acquired the coupon(s) successfully! ',
    'myWalletAcquiredTickets': 'You have successfully acquired the ticket(s). ',
    'myWalletAcquiredCouponViewVouchar': 'View coupons',
    'myWalletAcquiredCouponViewCoupons': 'View coupons',
    'myWalletAcquiredCouponViewTickets': 'View tickets',
    'myWalletValueCoupon': 'Only applicable for',
    'myWalletScanQRcode': 'SCAN STORE QR CODE',
    'myWalletScanQRcodeAlign': 'Align QR Code within frame to scan.',
    'myWalletScanQRcodeScanning': 'Scanning...',
    'myWalletSuccessfullyRedeemedTitle': 'Successfully redeemed on',
    'myWalletSuccessfullyRedeemedSuffix': '',
    'myWalletSuccessfullyRedeemedTakeSuvey': 'Take Survey',
    'myWalletSuccessfullyRedeemedDone': 'Done',
    'myWalletInvalidQRcodeLineOne': 'Invalid QR code',
    'myWalletInvalidQRcodeLineTwo': 'Try a new one or try again.',
    'myWalletMerchantScan': 'Scan this code to update the coupon usage record in POS.',
    'myWalletTakeSurvey': 'Take Survey',
    'myWalletViewCouponCode': 'View Coupon Code',
    'myWalletViewTicketCode': 'View ticket',
    'myWalletViewCode': 'View Code',
    'myWalletUsingQRCodetip': 'You must hold a valid account when using the QR code. If your account is deleted, the QR code will also become invalid.',
    'myWalletUseTicket': 'Please present this QR code at the entrance of event for admission',
    'myWalletUseCoupon': 'Show this code to the staff while using this coupon',
    'myWalletUseTicketPromptTitle': 'Use Ticket',
    'myWalletUseCouponPromptTitle': 'Use Coupon',
    'myWalletUseTicketPromptDescription': 'Are you sure you want to use this ticket now?',
    'myWalletUseCouponPromptDescription': 'Are you sure you want to use this coupon now?',
    'myWalletUseCouponPromptUselater': 'Use later',
    'myWalletUseCouponPromptConfirm': 'Confirm',
    'myWalletUseCouponDiscount': "Use this code on the merchant's website to acquire this offer!",
    'myWalletUseCouponDiscountLink': 'Enjoy this offer/reward with below designated link!',
    'myWalletCopyCode': 'Copy Code',
    'campaignApplicableStores': 'Applicable stores',
    'campaignEventLocation': 'Event location',
    'campaignBusinessHours': 'Business hours',
    'campaignFortuneBagcampaign': 'Fortune bag campaign',
    'homeLatest': 'Latest',
    'campaignUesCoupon': 'Cash voucher/Coupon can only be used on a mobile device',
    'campaignAddtoMyallet': 'Add to My Wallet',
    'campaignShare': 'Share',
    'campaignTooltips': 'Copied to clipboard',
    '//campaignStarredCampaign': 'Music Show Ticket',
    '//campaignSta...Campaign': 'Limited...Offer',
    'campaignStarredCampaign': 'HK Night Treats',
    'campaignSta...Campaign': 'HK Night Treats',
    'campaignOutOf': 'Out of quota',
    'campaignLow': 'Few quotas left',
    'homeEmptyOffers': 'Oops! No coupons here. Browse around and get the latest offers now!',
    'generalBroeserSupportedTitle': 'Sorry, your browser is not supported.',
    'generalBroeserSupportedDescription': 'To browse this site, please upgrade to the latest version of',
    'generalBroeserSupportedAffix': '',
    'generalBroeserSupportedOr': 'or',
    'generalBroeserSupportedSuffix': '',
    'generalPagenotFoundtitle': 'Page Not Found.',
    'generalPagenotFoundDescription': 'Sorry this page is not available.',
    'generalLanguageSupported': 'Please click the 🌐 at the top of the page to select another language.',
    'generalBacktoHome': 'Back to home',
    'generalServerError': 'Server error, please try again later.',
    'generalServerBusyError': 'The server is busy at this moment, please try again later.',
    'generalAddtoHomescreenClick': 'Click',
    'generalAddtoHomescreenSuffix': 'and select',
    'generalAddtoHomescreenAdd': 'Add to home screen',
    'generalAddtoHomescreenAffix': 'to access our latest offers easily.',
    'generalAddtoHomescreenAppSuffix': 'and select',
    'generalAddtoHomescreenAppInstall': 'Install app',
    'generalAddtoHomescreenAppTo': 'to',
    'generalAddtoHomescreenAppAdd': 'add to home screen',
    'generalAddtoHomescreenAppAffix': 'add this app to home screen to access our latest offers easily.',
    'generalAddtoHomescreenWebSuffix': 'and select ',
    'generalAddtoHomescreenWebInstall': 'Install',
    'generalAddtoHomescreenWebTo': 'to ',
    'generalAddtoHomescreenWebAdd': 'add to home screen',
    'generalAddtoHomescreenWebDescription': 'add this app to home screen to access our latest offers easily.',
    'generalAddtoHomescreenOfferYoucan': 'You can select',
    'generalAddtoHomescreenOfferAdd': 'Add to home screen ',
    'generalAddtoHomescreenOfferDescription': 'to access our latest offers easily!',
    'generalAddtoHomescreenOfferAffix': 'View Details',
    'generalCookieAffix': 'This website uses cookies to ensure you get the best experience on our website.',
    'generalCookiePrefix': 'For more information, please consult our',
    'generalCookieSuffix': 'Cookie Policy',
    'ContentNotAvailable': 'This content is not available in the selected language.',
    'generalGotIt': 'Got it',
    'loginMobileNo': 'Mobile',
    'profileAddSecondarycontact': 'Secondary contact for receiving our latest news and offers',
    'profileAddSecondarycontactPhoneReminder': '*Please make sure your mobile device is able to receive SMS messages',
    'generalCountryCode': 'Country Code',
    'profileMobileNo.': 'Mobile number',
    'profileSendCode': 'Send code',
    'profileVerificationCode': 'Please enter verification code',
    'profileResend': 'Resend',
    'profileResendInSeconds': 'Resend in {0}s',
    'profilePhoneTakenTryAnother': 'This mobile number has been taken. Please try another.',
    'profileEmailTakenTryAnother': 'This email address has been taken. Please try another.',
    'profileValidphoneNo': 'Please enter a valid phone number.',
    'profileValidemail': 'Please enter a valid email address.',
    'profileInvalidVerificationcode': 'Invalid verification code. ',
    'profileMobileSuccessfullyVerified': 'Your mobile number has been successfully verified! ',
    'profileEmailSuccessfullyVerified': 'Your email address has been successfully verified! ',
    'profileVerified': 'Verified',
    'profileEmailAddress': 'Email',
    'profileVerificationTip': 'In order to receive SMS messages sent from Hong Kong SAR, you may need to activate the service by contacting your telecommunications provider. Please make sure your mobile device is able to receive SMS messages.',
    'commonLogoutCancel': 'Cancel',
    'commonLogoutConfirm': 'Log out',
    'mycouponFeedback': 'Your feedback matters',
    'mycouponSubmit': 'Submit',
    'mycouponSubmissionTitle': 'Thank you！',
    'mycouponSubmissionDescription': 'Your feedback has been sent!',
    'mycouponSubmissionAffix': 'View other offers',
    'mycouponfullWindowBarcode': 'Please rotate the screen to landscape mode to fully display the barcode',
    'campaignGetCoupon': 'Add to My Wallet',
    'availableOn': 'Available on {startDate}',
    'fortuneBagExpired': 'Fortune bag expired',
    'homeUseCoupon': 'Use coupon',
    'homeUseTicket': 'Use ticket',
    'myWalletClickCouponunlockCode': "Use this code on the merchant's website to acquire this offer!",
    'homeMembershipID': 'Membership ID',
    'footerT&C': 'Terms of use',
    'campaignAvailableOn': 'Available on ',
    'mycouponTransactionAmount': 'Please input the amount of your transaction using this coupon.',
    'generalOfferMessageTitle': 'e-Solution',
    'generalOfferMessageDescription': 'Enjoy the latest Offers! 立即享受最新優惠！',
    'generalTwoPasswordserrorMessage': 'Passwords do not match',
    'campaignGeneralMessagePrompt': 'Click to view more details.',
    'generalConfirmNewPassword': 'Confirm New Password',
    'campaignEnjoyOffer': 'Enjoy this offer NOW:\n',
    'myAccountDeleteMolAccountconfirm2PromptTitle': 'To continue, verify it’s you',
    'myAccountDeleteMolAccountconfirm2PromptDescription': 'A link has been sent to your mobile number, please check and continue the process.',
    'myAccountDeleteMolAccountByWhatsAppconfirm2PromptDescription': 'A link has been sent to your mobile number by WhatsApp, please check and continue the process.',
    'myAccountDeleteMolAccountBySMSconfirm2PromptDescription': 'A link has been sent to your mobile number by SMS, please check and continue the process.',
    'myAccountDeleteMolAccountconfirm2PromptOK': 'OK',
    'detailDetail': 'Detail',
    'tourcardSectionTourCardSection': 'Itinerary',
    'offerdetailsectionOfferDetailsection': 'Offer detail',
    'instructionsectionInstructionSection': 'How to use',
    'merchantTerms&ConditionsMTConditions': 'Merchant Terms & Conditions',
    'genericTerms&ConditionsGTConditions': 'Generic Terms & Conditions',
    'profileEmailPlaceHolder': 'Email address',
    'storeWeekdayTo': ' to ',
    'storeClosed': 'closed',
    'storePublicHoliday': 'Public Holiday',
    'myAccountIncorrectCurrentPassword': 'Incorrect current password',
    'myAccountSamePasswordError': 'Cannot be the same as your current password',
    'myAccountRequired': '<div>*</div> Indicates a required field',
    'storeArea': 'AREA',
    'storeDistrict': 'DISTRICT',
    'navigationNavigationTitle': 'How to\r\nredeem offers',
    'navigationStep1': 'Sign up with email or mobile number & create password',
    'navigationStep2': 'Select offer & click “Add to My Wallet”',
    'navigationStep3': 'Go to “MY WALLET”',
    'navigationStep4': 'Click “Use coupon”',
    'navigationStep5': 'Scan merchant’s QR code',
    'navigationStep6': 'Receive a notification after successful redemption',
    'navigationStep7': 'Enjoy offers now',
    'navigationContact': 'Contact us',
    'cookiepolicyCookiePolicy': 'Cookie policy',
    'cookiepolicyManageCookie': 'Manage my cookies',
    'wechatLoginWelcom': 'Welcome!',
    'wechatLoginMessage': 'Login to/Sign up for ‘My Wallet’ to enjoy special offers throughout the many neighbourhoods of Hong Kong provided by our one-stop platform.',
    'wechatLoginT&CandPolicy': 'I have read and agree to our Terms of Use and Privacy Policy.',
    'wechatLoginDescription': 'By using our service, you agree to our',
    'wechatLoginT&C': 'Terms of Use',
    'wechatLoginAnd': 'and',
    'wechatLoginPolicy': 'Privacy Policy.',
    'wechatLoginSignup': 'Login / Sign up\r',
    'wechatLoginSignupNav': 'Login / Sign up',
    'wechatLogout': 'Log out',
    'campaignFull': 'FULL',
    '//campaignSTARRED': 'Music Show Ticket',
    '//campaignExceededTheMax': 'You have already reached the Music Show Ticket acquisition limit.',
    'campaignSTARRED': 'HK Night Treats',
    'campaignExceededTheMax': 'You have already reached the acquisition limit for the ‘Hong Kong Night Treats for Locals’ dining vouchers .',
    'myAccountLoginMethods': 'You can login with following methods',
    'myAccountWeChatAccount': 'WeChat account',
    'myRewardEmptyTitle': 'There\'s no reward here yet',
    'myRewardEmptyDesc': 'Click below to explore the latest offers.',
    'goodiebagEntitlementLink1': 'Terms of Use',
    'goodiebagEntitlementLink2': 'Acquire Now',
    'goodiebagEntitlementLink3': 'Out of quota',
    'goodiebagAcquireGoodiebagA1': 'Fail to acquire goodie bag',
    'goodiebagAcquireGoodiebagA2': 'The rewards have been acquired.',
    'goodiebagAcquireGoodiebagA3': 'OK',
    'goodiebagAcquireGoodiebagA5': 'You have acquired the rewards.',
    'goodiebagAcquireGoodiebagA6': 'Check My Rewards',
    'goodiebagAcquireGoodiebagB2': 'Sorry! You’re not entitle to this goodie bag.',
    'goodiebagCheckmyWallet': 'Check ‘My Wallet’',
    'goodiebagMyRewardCheckmyWallet': 'Check ‘<img width="18px" style="margin-right:5px;margin-bottom: 3px;" class="wallet-icon" srcset="/img/my_wallet.1x.png, /img/my_wallet.2x.png 2x, /img/my_wallet.3x.png 3x" src="/img/my_wallet.2x.png"><b style="color: #E2002C;text-transform: uppercase;">My Wallet</b>’',
    'goodiebagFindredemption': 'Find your saved cash vouchers or coupons here',
    'goodiebagRecentlyAdded': 'RECENTLY ACQUIRED ',
    'goodiebagAcquiringAfterStart': 'Start acquiring your {itemName} on {startDate}',
    'goodiebagAcquiringBeforeEnd': 'Add the offers to ‘My Wallet’ before {endDate}',
    'goodiebagAcquiringLastNDay': ' Last {dayCount} days to acquire your {itemName} before {endTime}',
    'goodiebagAcquiringLastDay': ' Last day to acquire your {itemName} before {endTime}',
    'goodiebagExpired': 'Expired',
    'goodiebagRewards': 'Rewards',
    'goodiebagMax': 'Max.',
    'goodiebagMin': 'Min.',
    'goodieBagUnlimitedRewardDesc': 'Acquire your exciting offer now!',
    'goodiebagCashVouchers': 'Cash vouchers',
    'goodiebagCredits': 'Credit(s)',
    'goodiebagCreditTitle': '<strong>{owned}</strong> Credit(s)',
    'goodiebagLimitedOffers': 'Coupons',
    'goodiebagQuotaTitle': '<strong>{owned}</strong>',
    'goodiebagTreatReward': 'Explore and acquire offers.',
    'goodiebagSuccessfullyUnlockedBoth': 'Successfully unlocked <strong>‘{name}’</strong> with <strong>{credit}</strong> credit(s) and <strong>{quota}</strong> quotas.',
    'goodiebagSuccessfullyUnlockedCash': 'Successfully unlocked <strong>‘{name}’</strong> with <strong>{credit}</strong> credit(s).',
    'goodiebagSuccessfullyUnlockedGift': 'Successfully unlocked <strong>‘{name}’</strong> with <strong>{quota}</strong> quotas.',
    'goodiebagSuccessfullyUnlockedXBoth': 'Rewards acquired successfully.',
    'goodiebagSuccessfullyUnlockedXCash': 'Rewards acquired successfully.',
    'goodiebagSuccessfullyUnlockedXGift': 'Rewards acquired successfully.',
    'goodiebagSeemore': 'See more',
    'goodiebagExplorecashvoucher': 'Explore cash vouchers',
    'goodiebagAcquirecredit': 'Explore and acquire offers with credit(s).',
    'goodiebagStillHave': 'You still have',
    'goodiebagStillHave2': '<b>{cash}</b>',
    'goodiebagStillHave2b': 'credit(s)&nbsp;',
    'goodiebagStillHave3': ' (out of {limit})',
    'goodiebagSort': 'Sort',
    'goodiebagFilters': 'Filters',
    'goodiebagLocationFilters': 'Location',
    'goodiebagLocationFilterArea': 'Area',
    'goodiebagLocationFilterDistrict': 'District',
    'goodiebagResults': '{count} result(s)',
    'goodiebagFeature': 'FEATURED',
    'goodiebagVaildtil': 'Valid till {validTil}',
    'goodiebagNoVouch': 'Oops! No cash voucher here.',
    'goodiebagExploreOffer': 'Explore offers',
    'goodiebagOnlyAcquire': 'A max of <b>{limit}</b> coupons/cash vouchers can be acquired in total',
    'goodiebagOwnedCouponLimit': 'and hold a maximum of <b>{limit}</b> unused reward(s) at one time.',
    'goodiebagCouponDownloadLimit': 'You can acquire a maximum of <b>{limit}</b> reward(s),',

    'goodiebagCouponLimitMix1': 'You can acquire a maximum of <b>{limit1}</b> reward(s).',
    'goodiebagCouponLimitMix3': 'You can hold a maximum of <b>{limit3}</b> unused reward(s) at one time.',
    'goodiebagCouponLimitMix1And3': 'You can acquire a maximum of <b>{limit1}</b> reward(s), and hold a maximum of <b>{limit3}</b> unused reward(s) at one time.',
    '//goodiebagOwnedCouponLimitSTARRED': 'Remarks: You can acquire a maximum of <b>{limit}</b> Music Show Ticket(s) within the promotion period.',
    'goodiebagOwnedCouponLimitSTARRED': 'Remarks: You can acquire a maximum of <b>{limit}</b> HK Night Treats within the promotion period.',
    'goodiebagRequirecredits': 'Credit(s) required (per offer)',
    'goodiebagAcquireNow': 'Add to ‘My Wallet’',
    'goodiebagAvailableOn': 'Available on {date}',
    'goodiebagFailedAcquire1': "Can't add this offer into 'MY WALLET'",
    'goodiebagFailedAcquire2': 'Desired reward(s) are sold out.',
    'goodiebagFailedAcquire6': 'Exceed campaign per head limit',
    'goodiebagFailedAcquire7': '[OK]',
    'goodiebagFailedAcquire10': 'Exceed campaign per head owned limit',
    'goodiebagFailedAcquire14': 'Not enough credit(s)/quotas',
    'goodiebagConfirmation': 'Please confirm the details below:',
    'goodiebagCampaginExpiryDateText': 'Expires on (GMT+8)',
    'goodiebagTotalCreditsrequired': 'Total credit(s) required',
    'goodiebagRemainingCredits': 'Remaining credit(s) after acquiring',
    'goodiebagConfirm': 'Confirm',
    'goodiebagReminderAllTime': 'Once added to ‘MY WALLET’, the coupon cannot be changed or cancelled. Expired coupons will not be reissued.',
    'goodiebagReminderRelative': 'Once added to ‘MY WALLET’, the coupon is valid for {day} days and cannot be changed or cancelled. Expired coupons will not be reissued.',
    'goodiebagReminderAbsolute': 'Once added to ‘MY WALLET’, the coupon is valid until {endDate} and cannot be changed or cancelled. Expired coupons will not be reissued.',
    'goodiebagExploreLimitedoffers': 'Explore coupons',
    'goodiebagAcquireLimitedoffer': 'Explore and acquire offers.',
    'goodiebagYousStillHave1': 'You still have',
    'goodiebagYousStillHave2': '<b>{cash}</b>',
    'goodiebagYousStillHave2b': 'offer(s)',
    'goodiebagYousStillHave3': ' (out of {limit})',
    'goodiebagYousStillHave4': '&nbsp;to be claimed&nbsp;',
    'goodiebagUnlimitedRewardTitle': 'Acquire your exciting offer now!',
    'goodiebagNoLimiteedoffer': 'Oops! No coupons here.',
    'goodiebagSelectQuantily': 'Select quantity',
    'goodiebagQuantily': 'Quantity',
    'goodiebagTotalQuotas': 'Total quotas required',
    'goodiebagRemainingQuotas': 'Remaining no. of offer(s) to be claimed',
    'goodiebagClearAll': 'Clear all',
    'goodiebagCategory': 'Category',
    'goodiebagBrand1': 'Brand',
    'goodiebagOfferTypelabel': 'Offer type label',
    'goodiebagCreditRange': 'Credit range',
    'goodiebagValidPeriod': 'Valid period',
    'goodiebagNoRxpiryDate': 'No expiry date',
    'goodiebagViewItems': 'View items',
    'goodiebagNoResultsFound': 'No results found. Try another search.',
    'goodiebagFeatured': 'Featured',
    'goodiebagAlphabetical(aToz)': 'Alphabetical (A to Z)',
    'goodiebagAlphabetical(zToa)': 'Alphabetical (Z to A)',
    'goodiebagCredit(lowTohigh)': 'Credit (low to high)',
    'goodiebagCredit(highTolow)': 'Credit (high to low)',
    'goodiebagAlpha...(aToz)': 'Alpha... (A to Z)',
    'goodiebagAlpha...(zToa)': 'Alpha... (Z to A)',
    'goodiebagCre...(lowTohigh)': 'Cre...(low to high)',
    'goodiebagCre...(highTolow)': 'Cre...(high to low)',
    'goodiebagSuccessfullyAcquired1': 'Successfully acquired ',
    'goodiebagSuccessfullyAcquired2': ' offer(s):',
    'goodiebagSuccessfullyAcquired4': ' offer(s):',
    'goodiebagSuccessfully': 'Rewards acquired successfully.',
    'goodiebagOk': 'OK',
    'goodiebagBrowseOtherOffers': 'Browse other offers',
    'goodiebagSsomeOffers': 'Some offers you may also like',
    'goodiebagAll': 'All',
    'goodiebagNothingHere': 'No coupons or tickets available',
    'goodiebagVoucharNothingHere': 'No coupons here',
    'goodiebagCouponsNothingHere': 'No coupons here',
    'goodiebagTicketsNothingHere': 'No tickets available',
    'goodiebagBrowseAround': 'Browse the latest offers now!',
    'goodiebagBrowseAroundCenter': 'Browse the offers and acquire yours!',
    'goodiebagSelectAll': 'Select all',
    'goodieBagExpired': 'Reward expired',
    'goodieBagOutOfStockPrompt': 'This goodie bag is temporary out of stock.',
    'goodieBagNotEntitlePrompt': 'Sorry! you are not entitle to this goodie bag',
    'goodiebagInvalidCountryCode': 'This reward is not available in the country/region of your registered number.',
    'goodiebagPerheadLimitReached': 'Acquisition limit of this reward reached.',
    'couponCampaginReminderTitle': 'Reminder',
    'couponCampaginReminderContent': 'Once added to ‘MY WALLET’, the coupon cannot be changed or cancelled.',
    'couponCampaginReminderConfirm': 'Confirm',
    'couponCampaginReminderCancel': 'Cancel',
    'campaginInvalidCountryCode': 'This offer is not available in the country/region of your registered number.',
    'findYourAcquiredReward': 'Find your acquired reward in ‘My Wallet’',
    'sessionTitle': 'Session ended',
    'sessionContent': 'Your session has ended. Please click ‘OK’ to queue up again to enter our website.',
    'ageTitle': 'Important notice<br />重要告示',
    'ageContent': 'Under the law of Hong Kong, intoxicating liquor must not be sold or supplied to a minor in the course of business. I declare that I am aged 18 or above.<br />根據香港法律，不得在業務過程中，向未成年人售賣或供應令人醺醉的酒類。謹此聲明本人已年滿18歲。',
    'ageContentOK': 'I am aged 18 or above',
    'ageContentOKSecond': '本人年滿18歲',
    'ageContentCancel': 'Leave',
    'ageContentCancelSecond': '離開',
    'referenceID': 'Reference ID:',
    'campaginCategoryTitle': 'Select single category:',
    'headerBack': 'Back',
    'toHome': ' to ‘Home’',
    'toSlotName': ' to ‘{creditName}’',
    'toDeals': ' to ‘Deals’',
    'toMyWallet': ' to ‘My Wallet’',
    'toMyRewards': ' to ‘My Rewards ’',
    'toSearchResult': ' to search results',
    'searchResult': ' to search results',
    'acquiredFortuneBagCouponsSuccessful': 'You’ve successfully acquired the following ‘{goodiebagName}’ offer(s)!',
    'acquiredFortuneBagCouponsSuccessfulMany': 'You’ve successfully acquired ‘{goodiebagName}’ offers!',
    'tipShowFortuneBagCoupon': 'The offer(s) can be found in ‘My Wallet’.',
    'tipShowFortuneBagCouponMany': 'The offers can be found in ‘My Wallet’.',
    'acquiredFortuneBagCouponsSuccessfulButtonText': 'Done',
    'showCodeToStaff': 'Show this code to the staff while using this coupon',
    'tipShowFullBarCode': 'If the barcode does not work, please <span>click here to view in larger size</span>. ',
    'partnerCodeReferenceId': 'Reference ID:',
    'ViewFullBarcode': 'View Barcode',
    'fortunebagAcquireGoodiebag': 'Check ‘My Wallet’',
    'rewardUnavailableTitle': 'Reward is temporarily unavailable',
    'rewardUnavailableMessage': 'Please check back in a while to claim your reward.',
    'rewardUnavailableButton': 'Okay, I‘ll return later',
    'rewardNotReadyButton': 'Reward is temporarily unavailable',
    'notAddMywalletRewardTitle': 'Can‘t add this offer into ’MY WALLET‘',
    '//notAddMywalletRewardMessage': 'You have already reached the Limited Quota Offer acquisition limit.',
    'notAddMywalletRewardMessage': 'You have already reached the acquisition limit for the ‘Hong Kong Night Treats for Locals’ dining vouchers .',

    '//homeUseCouponNotAvailable': 'This coupon is valid only on the specified date or time.',
    'homeUseCouponNotAvailable': 'Use Coupon (Not yet started)',
    'couponNotAvailableInSpecificDaysPromptTitle': 'Not yet available',
    'couponNotAvailableInSpecificDaysPrompt': 'This coupon is valid only on the specified date or time. \nPlease check the available time in the coupon details.',
    'couponInvalidPromptTitle': 'Invalid Coupon',
    'couponInvalidPrompt': 'This coupon has expired.',
    'couponUsedPrompt': 'This coupon has been used.',
    'couponInactivePrompt': 'This coupon is currently inactive.',
    'couponUnavailablePromptTitle': 'Coupon Unavailable',
    'couponNotInActivePeriodPrompt':
      'This coupon is currently not available for use. Please check the validity period.',

    'recaptcha_low_score_title': 'Notice',
    'recaptcha_low_score_content': '<div className="recaptcha-fail-reminder-title">We are currently unable to process your request. Please try the following steps:</div><ul><li>Use a different browser</li><li>Temporarily disable any ad blockers or extensions in your browser</li><li>Disable any VPN or proxy services and connect directly to the internet</li><li>Avoid rapid, repeated actions</li></ul>',
    'recaptcha_fail_content': 'Your request cannot be processed at the moment. Please try again later.',

    'searchButton': 'Search',
    'goodiebagRecommend': 'Recommended',
    'goodiebagSearchPlaceholder': 'Search offers in {goodiebagName}',
    'inSearching': 'Searching...',
    'searchResultCount': 'Your search for \'<b>{keyword}</b>\' found {count} results',
    'searchNoResultTitle': 'No results found',
    'searchNoResultContent': 'Please try other keywords.',

    'goodiebagHasNotStartedYet': 'This campaign will commence soon.',
    'goodiebagHasExpired': 'This campaign is concluded.',
    'goodiebagIsNotAvaliable': 'This campaign page is not available.',
    'tryOtherGoodiebag': 'Please try other goodie bag.',
    'tryAgainLater': 'Please try again later.'

  },
  'zh-t': {
    'navBarMenu': '選單',
    'navBarMenuAlert': '通知',
    'navBarWallet': '我的錢包',
    'navBarExplore': '全城優惠',
    'navBarRewards': '我的獎賞',
    'navDeals': '優惠資訊',
    'navDealsLow': '優惠資訊',
    'homeSignUp': '登入或註冊，探索更多優惠',
    'homeShowMore': '顯示更多',
    'homeExploreMore': '發掘更多',
    'homeTopOfferTitle': '全城優惠',
    'homeTopOfferViewMore': '查看更多優惠',
    'linkCopied': '鏈接已複製，您可以在瀏覽器中粘貼並查看。',
    'linkCopiedShort': '已複製連結',
    'codeCopiedShort': '已複製代碼',
    'homeDiscoverOffer': '立即發掘更多屬於你的優惠！',
    'homeBrowseOffer': '盡情瀏覽，領取你的優惠！',
    'headerlanguage': 'Language / 语言',
    'headerHome': '首頁',
    'headerHomeLow': '首頁',
    'headerMyWallet': '我的錢包',
    'headerMyWalletLow': '我的錢包',
    'headerDearMember': '親愛的用戶',
    'homeMyWallet': '我的錢包',
    'headerMyRewards': '我的獎賞',
    'headerMyRewardsSingle': '我的獎賞',
    'headerMyAccount': '我的帳戶',
    'headerMyAccountLow': '我的帳戶',
    'headerHelloName': '你好，',
    'headerLoginSignup': '登入/註冊',
    'headerLogout': '登出',
    'headerLogoutLow': '登出',
    'generalLogoutPromptTitle': '登出?',
    'generalLogoutPromptDescription': '你是否確認登出?',
    'generalLogoutPromptCancel': '取消',
    'generalLogoutPromptLogout': '確認',
    'showLanguageReminderTitle': '提示',
    'showLanguageReminderContent': '你是否想要切換到繁體中文界面？',
    'showLanguageReminderOk': '是',
    'showLanguageReminderCancel': '否',
    'footerTermsConditions': '條款及細則',
    'miniNavTermsConditions': '條款及細則',
    'footerPrivacyPolicy': '隱私政策',
    'footerFAQ': '常見問題',
    'footer2021HKTBAllRightsRecerved': '版權所有© {year} 香港旅遊發展局。',
    'footerReCAPTCHA': 'This site is protected by reCAPTCHA and the Google:',
    'footerRePrivacyPolicy': 'Privacy Policy',
    'footerTermsOfService': 'Terms of Service',
    'myWalletMyVouchers': '我的獎賞',
    'myWalletMyCoupons': '我的優惠券',
    'myWalletCoupons': '優惠券',
    'myWalletTickets': '門票',
    'myWalletAllStatus': '全部狀態',
    'myWalletAvailableAndUnavailable': '可使用 / 未可用',
    'myWalletRedeemedExpired': '已使用/過期',
    'myWalletRecentlyAdded': '最近領取',
    'myWalletRedeemed': '已使用',
    'myWalletExpired': '已過期',
    'myWalletNoExpiryDate': '沒有期限',
    'myWalletLastDay': '最後一天',
    'myWalletStartDate': '開始日期: ',
    'myWalletXDaysLeft': '最後{dayCount}天',
    'myWalletExpiryDate': '到期日: ',
    'myWalletEffectiveDate': '有效期：{startDate}到{endDate}',
    'myWalletEffectiveDateLessOneDay': '有效期：{endDate}',
    'myWalletEmptyCouponsMessage': '噢！你還未有優惠券呢！立即瀏覽最新優惠！',
    'myWalletDiscover': '發掘更多',
    'myWalletValidTime': '有效至',
    'myWalletRedeemedTime': '使用日期：{dateString}',
    'myWalletApplicableStores': '適用商店',
    'myWalletSeeMore': '查看更多',
    'myWalletUserExperienceMessage': '請使用行動裝置掃描此二維碼，並在手機上使用此優惠券。',
    'myWalletUserExperienceTicketMessage': '請使用行動裝置掃描此二維碼，並在手機上使用此門票。',
    'myWalletScanQRcodeMessage': '你可掃描二維碼，用行動裝置瀏覽優惠詳情。',
    'myWalletScanQRcodeTicketMessage': '你可掃描二維碼，用行動裝置瀏覽門票詳情。',
    'myWalletAvailableTime': '此優惠券將由',
    'myWalletTicketAvailableTime': '此門票將由',
    'myWalletAvailableTimeSuffix': '開始可以使用',
    'myWalletRedeemNow': '立即使用',
    '//myWalletStarredAlert': '提示: 你已持有 <b>{count}</b> 張「音樂會門票」。你最多只可同時持有 <b>{limit}</b> 張未使用之「音樂會門票」。',
    'myWalletStarredAlert': '提示：每位登記者同一時間最多只可領取及儲存<span><b>{limit}張</b></span>「香港夜饗樂」本地市民版餐飲消費券。請注意您現時已持有<span><b>{count}張</b></span>餐飲消費券。',
    'acquireUseTicketOnMobile': '請在手機上使用此門票。',
    'myAccountAccountInformation': '我的個人資料',
    'myAccountFirstName': '名',
    'myAccountLastName': '姓',
    'myAccountNickname': '暱稱',
    'myAccountIam': '請輸入',
    'myAccountEmail': '電郵',
    'myAccountResidence': '居住地',
    'myAccountIamFrom': '請選擇',
    'myAccountSalutationLabel': '稱謂',
    'myAccountSalutation': '請選擇',
    'myAccountCountry': '國家',
    'myAccountMobileNo': '手機號碼',
    'myAccountBirthMonthYear': '出生月份',
    'myAccountOptional': '(可選填)',
    'myAccountSelectBirthdate': '請選擇',
    'myAccountEditBirthprompt': '此項一經確認，不能更改。',
    'myAccountUpdate': '更新',
    'myAccountAccountSettings': '帳戶設定',
    'myAccountLanguagePreference': '語言',
    'myAccountEditLanguageRegion': '請選擇',
    'myAccountInterestPreference': '興趣',
    'myAccountDirectMarketing': '直接促銷推廣訊息',
    'myAccountDirectMarketinghints': '我希望收到香港旅遊發展局的直接促銷活動消息、資訊及個人化訊息。',
    'myAccountChangePassword': '更改密碼',
    'myAccountCurrentPassword': '現時密碼',
    'myAccountNewPassword': '新密碼',
    'myAccountPasswordErrormessage': '必須至少8個字元，並包含英文大寫字母及數字',
    'myAccountDeleteAccount': '刪除我的賬戶',
    'myAccountDeleteAccountPrompt': '我們會永久刪除你的帳戶、優惠券和數據。',
    'myAccountDelete': '刪除',
    'myAccountUnsavedPromptTile': '您有未儲存的更改',
    'myAccountUnsavedPromptDescription': '離開前儲存更改？',
    'myAccountUnsavedPromptDiscardchanges': '放棄更改',
    'myAccountUnsavedPromptSave': '儲存',
    'myAccountBirthConfirmPromptTitle': '確認？',
    'myAccountBirthConfirmPromptDescription': '出生月份一經確認，不能更改。',
    'myAccountBirthConfirmPromptCancel': '取消',
    'myAccountBirthConfirmPromptConfirm': '確認',
    'myAccountSuccessfullyUpdated': '更新成功！',
    'myAccountDeleteAccountconfirmPromptTitle': '刪除你的帳戶',
    'myAccountDeleteAccountconfirmPromptDescription': '你確定要永久刪除此帳戶及其資料？確認刪除後，已領取的優惠券及門票等將被永久刪除及失效。',
    'myAccountDeleteAccountconfirmPromptCancel': '取消',
    'myAccountDeleteAccountconfirmPromptConfirm': '確認',
    'myAccountDeleteAccountconfirm2PromptTitle': '請先驗證你的身份',
    'myAccountDeleteAccountconfirm2PromptDescription': '我們已發送連結到你的電郵帳戶，請檢查並按指示繼續。',
    'myAccountDeleteAccountconfirm2PromptOK': '知道了',
    'myAccountDeleteAccountconfirm2PromptTitleMobile': '',
    'myWalletAcquiredVouchar': '恭喜！你已經成功獲得了優惠券！',
    'myWalletAcquiredCoupons': '恭喜！你已經成功獲得了優惠券！',
    'myWalletAcquiredTickets': '你已經成功獲得門票。',
    'myWalletAcquiredCouponViewVouchar': '查看優惠券',
    'myWalletAcquiredCouponViewCoupons': '查看優惠券',
    'myWalletAcquiredCouponViewTickets': '查看門票',
    'myWalletValueCoupon': '僅適用於',
    'myWalletScanQRcode': '掃描商戶二維碼',
    'myWalletScanQRcodeAlign': '對齊掃描框掃描二維碼',
    'myWalletScanQRcodeScanning': '掃描中...',
    'myWalletSuccessfullyRedeemedTitle': '已於',
    'myWalletSuccessfullyRedeemedSuffix': '成功使用',
    'myWalletSuccessfullyRedeemedTakeSuvey': '填寫問卷',
    'myWalletSuccessfullyRedeemedDone': '確認',
    'myWalletInvalidQRcodeLineOne': '無效的二維碼',
    'myWalletInvalidQRcodeLineTwo': '嘗試其他二維碼或重試。',
    'myWalletMerchantScan': '掃描此條碼，並更新POS的優惠券使用紀錄。',
    'myWalletTakeSurvey': '填寫問卷',
    'myWalletViewCouponCode': '查看優惠碼',
    'myWalletViewTicketCode': '查看門票',
    'myWalletViewCode': '查看商戶條碼',
    'myWalletUsingQRCodetip': '使用此二維碼時，必須持有有效帳戶。如帳戶已被刪除，此二維碼亦會失效。',
    'myWalletUseTicket': '請於活動入場處出示此二維碼',
    'myWalletUseCoupon': '使用此券時請出示優惠碼',
    'myWalletUseTicketPromptTitle': '使用門票',
    'myWalletUseCouponPromptTitle': '使用優惠券',
    'myWalletUseTicketPromptDescription': '你確認現在使用門票?',
    'myWalletUseCouponPromptDescription': '你確認現在使用優惠券？',
    'myWalletUseCouponPromptUselater': '以後使用',
    'myWalletUseCouponPromptConfirm': '確認',
    'myWalletUseCouponDiscount': '於商戶網站使用此代碼即可享指定優惠!',
    'myWalletUseCouponDiscountLink': '使用下列指定網店連結即可享優惠或獎賞!',
    'myWalletCopyCode': '複製優惠碼',
    'wechatLogout': '登出',
    'campaignApplicableStores': '適用商店',
    'campaignEventLocation': '會場地點',
    'campaignBusinessHours': '營業時間',
    'campaignFortuneBagcampaign': '福袋活動',
    'homeLatest': '最新',
    'campaignUesCoupon': '現金券/優惠券只能於行動裝置上使用',
    'campaignAddtoMyallet': '新增到我的錢包',
    'campaignShare': '分享',
    'campaignTooltips': '已複製到剪貼簿',
    '//campaignStarredCampaign': '音樂會門票',
    '//campaignSta...Campaign': '音樂會門票',
    'campaignStarredCampaign': '香港夜饗樂',
    'campaignSta...Campaign': '香港夜饗樂',
    'campaignOutOf': '已換罄',
    'campaignLow': '尚餘少量',
    'homeEmptyOffers': '噢！你還未有優惠券呢！立即瀏覽最新優惠！',
    'generalBroeserSupportedTitle': '抱歉，我們並不支援你的瀏覽器。',
    'generalBroeserSupportedDescription': '若要瀏覽此網站，請升級到',
    'generalBroeserSupportedAffix': '瀏覽器',
    'generalBroeserSupportedOr': '或',
    'generalBroeserSupportedSuffix': '瀏覽器的最新版本。',
    'generalPagenotFoundtitle': '找不到頁面。',
    'generalPagenotFoundDescription': '抱歉未能提供此頁面。',
    'generalLanguageSupported': '請按頁面頂部的 🌐 以選擇另一種語言。',
    'generalBacktoHome': '返回首頁',
    'generalServerError': '服務器錯誤，請稍後再試。',
    'generalServerBusyError': '系統繁忙，請稍後重試。',
    'generalAddtoHomescreenClick': '點擊',
    'generalAddtoHomescreenSuffix': '選擇',
    'generalAddtoHomescreenAdd': '「添加到主畫面」',
    'generalAddtoHomescreenAffix': '方便你獲得我們最新的優惠資訊。',
    'generalAddtoHomescreenAppSuffix': '選擇',
    'generalAddtoHomescreenAppInstall': '「安裝應用程式」',
    'generalAddtoHomescreenAppTo': '並',
    'generalAddtoHomescreenAppAdd': '添加到主畫面',
    'generalAddtoHomescreenAppAffix': '方便你獲得我們最新的優惠資訊。',
    'generalAddtoHomescreenWebSuffix': '選擇',
    'generalAddtoHomescreenWebInstall': '「安裝」',
    'generalAddtoHomescreenWebTo': '並',
    'generalAddtoHomescreenWebAdd': '加到主畫面',
    'generalAddtoHomescreenWebDescription': '方便你獲得我們最新的優惠資訊。',
    'generalAddtoHomescreenOfferYoucan': '你可以選擇',
    'generalAddtoHomescreenOfferAdd': '「添加到主畫面」',
    'generalAddtoHomescreenOfferDescription': '方便你獲得我們最新的優惠資訊！',
    'generalAddtoHomescreenOfferAffix': '查看詳情',
    'generalCookieAffix': '本網站使用Cookies來確保您獲得我們網站上的最佳體驗。',
    'generalCookiePrefix': '欲了解更多信息，請諮詢我們的',
    'generalCookieSuffix': 'Cookie政策',
    'ContentNotAvailable': '所選語言未能提供此內容。',
    'generalGotIt': '知道了',
    'loginMobileNo': '手機',
    'profileAddSecondarycontact': '請提供另一聯繫方式，以獲得我們的最新資訊及優惠',
    'profileAddSecondarycontactPhoneReminder': '*請確保你的行動裝置能夠接收短訊（SMS）',
    'generalCountryCode': '國家代碼',
    'profileMobileNo.': '手機號碼',
    'profileSendCode': '發送驗證碼',
    'profileVerificationCode': '請輸入驗證碼',
    'profileResend': '重新發送',
    'profileResendInSeconds': '重新發送 {0}秒',
    'profilePhoneTakenTryAnother': '此手機號碼已被使用。請嘗試另一個。',
    'profileEmailTakenTryAnother': '此電郵地址已被使用。請嘗試另一個。',
    'profileValidphoneNo': '請輸入有效的電話號碼。',
    'profileValidemail': '請輸入有效的電郵地址。',
    'profileInvalidVerificationcode': '驗證碼無效。',
    'profileMobileSuccessfullyVerified': '你的手機號碼已成功驗證！',
    'profileEmailSuccessfullyVerified': '你的電郵地址已成功驗證！',
    'profileVerified': '已驗證',
    'profileEmailAddress': '電郵',
    'profileVerificationTip': '你可能需要聯絡電訊商開通服務，才能接收從香港特別行政區發送的短信。請確保你的行動裝置能夠接收短訊（SMS）。',
    'commonLogoutCancel': '取消',
    'commonLogoutConfirm': '確認',
    'mycouponFeedback': '歡迎多提寶貴意見',
    'mycouponSubmit': '提交',
    'mycouponSubmissionTitle': '謝謝！',
    'mycouponSubmissionDescription': '您的回應已發送！',
    'mycouponSubmissionAffix': '查看其他優惠',
    'mycouponfullWindowBarcode': '請將屏幕旋轉至橫向模式以完整顯示條碼',
    'campaignGetCoupon': '新增到我的錢包',
    'availableOn': '可於{startDate}開始領取',
    'fortuneBagExpired': '福袋已過期',
    'homeUseCoupon': '使用優惠券',
    'homeUseTicket': '使用門票',
    'myWalletClickCouponunlockCode': '於商戶網站使用此代碼即可享指定優惠!',
    'homeMembershipID': '會員編號',
    'footerT&C': '條款及細則',
    'campaignAvailableOn': '可用於',
    'mycouponTransactionAmount': '請輸入使用此優惠券的交易金額。',
    'generalOfferMessageTitle': '',
    'generalOfferMessageDescription': '',
    'generalTwoPasswordserrorMessage': '密碼不相符',
    'campaignGeneralMessagePrompt': '點擊瀏覽查看更多詳情：',
    'generalConfirmNewPassword': '確定新密碼',
    'campaignEnjoyOffer': '立即享受此優惠：',
    'myAccountDeleteMolAccountconfirm2PromptTitle': '請先驗證你的身份',
    'myAccountDeleteMolAccountconfirm2PromptDescription': '我們已發送連結到您的手機號碼，請檢查並按指示繼續。',
    'myAccountDeleteMolAccountByWhatsAppconfirm2PromptDescription': '我們已透過WhatsApp發送連結到您的手機號碼，請檢查並按指示繼續。',
    'myAccountDeleteMolAccountBySMSconfirm2PromptDescription': '我們已透過短訊發送連結到您的手機號碼，請檢查並按指示繼續。',
    'myAccountDeleteMolAccountconfirm2PromptOK': '知道了',
    'detailDetail': '優惠詳情',
    'tourcardSectionTourCardSection': '行程表',
    'offerdetailsectionOfferDetailsection': '優惠內容',
    'instructionsectionInstructionSection': '如何使用',
    'merchantTerms&ConditionsMTConditions': '商戶條款及細則',
    'genericTerms&ConditionsGTConditions': '一般條款及細則',
    'profileEmailPlaceHolder': '電郵地址',
    'storeWeekdayTo': '至',
    'storeClosed': '休息',
    'storePublicHoliday': '公衆假期',
    'myAccountIncorrectCurrentPassword': '現時密碼不正確',
    'myAccountSamePasswordError': '不能跟現時密碼相同',
    'myAccountRequired': '有<div class="zh">*</div>的欄位必須填寫資料',
    'storeArea': '區域',
    'storeDistrict': '分區',
    'navigationNavigationTitle': '如何兌換優惠',
    'navigationStep1': '使用電郵或手機號碼註冊並設立密碼',
    'navigationStep2': '選擇優惠，點擊「新增到我的錢包」',
    'navigationStep3': '進入「我的錢包」',
    'navigationStep4': '點擊「使用優惠券」',
    'navigationStep5': '掃描商戶二維碼',
    'navigationStep6': '兌換成功後收取通知',
    'navigationStep7': '立即享受優惠',
    'navigationContact': '聯絡我們',
    'cookiepolicyCookiePolicy': 'Cookie政策',
    'cookiepolicyManageCookie': '管理我的 Cookie',
    'wechatLoginWelcom': '歡迎！',
    'wechatLoginMessage': '登入 / 註冊「我的錢包」，在我們的一站式本地旅遊消費和優惠資訊平台上盡享精選禮遇!',
    'wechatLoginT&CandPolicy': '我已閱讀並同意我們的 條款&條件 和 隱私政策。',
    'wechatLoginDescription': '當你使用我們的服務，即表示你同意我們的',
    'wechatLoginT&C': '條款&條件',
    'wechatLoginAnd': '和',
    'wechatLoginPolicy': '資料私隱政策。',
    'wechatLoginSignup': '登入/註冊',
    'wechatLoginSignupNav': '登入/註冊',
    'campaignFull': '名額已滿',
    '//campaignSTARRED': '音樂會門票',
    '//campaignExceededTheMax': '已達「我的錢包」中可儲存之「音樂會門票」上限。',
    'campaignSTARRED': '香港夜饗樂',
    'campaignExceededTheMax': '「我的錢包」中可儲存之「香港夜饗樂」本地市民版餐飲消費券數量已達上限。',
    'myAccountLoginMethods': '您可以通過以下方式登入',
    'myAccountWeChatAccount': '微信帳戶',
    'myRewardEmptyTitle': '暫時沒有獎賞',
    'myRewardEmptyDesc': '點擊下方瀏覽最新禮遇。',
    'goodiebagEntitlementLink1': '條款及細則',
    'goodiebagEntitlementLink2': '立即領取',
    'goodiebagEntitlementLink3': '已換罄',
    'goodiebagAcquireGoodiebagA1': '未能成功取得獎賞包',
    'goodiebagAcquireGoodiebagA2': '此獎賞已被領取。',
    'goodiebagAcquireGoodiebagA3': '確定',
    'goodiebagAcquireGoodiebagA5': '你已領取此獎賞。',
    'goodiebagAcquireGoodiebagA6': '查看我的獎賞',
    'goodiebagAcquireGoodiebagB2': '您未有權限參與此活動。',
    'goodiebagCheckmyWallet': '查看「我的錢包」',
    'goodiebagMyRewardCheckmyWallet': '查看「<img width="18px" style="margin-right:5px;margin-bottom: 3px;" class="wallet-icon" srcset="/img/my_wallet.1x.png, /img/my_wallet.2x.png 2x, /img/my_wallet.3x.png 3x" src="/img/my_wallet.2x.png"><b style="color: #E2002C;text-transform: uppercase;">我的錢包</b>」',
    'goodiebagFindredemption': '查看已領取的現金券或優惠券',
    'goodiebagRecentlyAdded': '最近領取',
    'goodiebagAcquiringAfterStart': '{startDate}開始領取{itemName}',
    'goodiebagAcquiringBeforeEnd': '請於{endDate} 前，將心儀禮遇加到「我的錢包」',
    'goodiebagAcquiringLastNDay': '最後{dayCount}天於{endTime}前取得你的{itemName}',
    'goodiebagAcquiringLastDay': '最後1天於{endTime}前取得你的{itemName}',
    'goodiebagExpired': '已過期',
    'goodiebagRewards': '獎賞',
    'goodiebagMax': '最大',
    'goodiebagMin': '最小',
    'goodieBagUnlimitedRewardDesc': '立即獲取專屬精彩優惠！',
    'goodiebagCashVouchers': '現金券',
    'goodiebagCredits': '獎賞積分',
    'goodiebagCreditTitle': '<strong>{owned}</strong>獎賞積分',
    'goodiebagLimitedOffers': '優惠券',
    'goodiebagQuotaTitle': '<strong>{owned}</strong>',
    'goodiebagTreatReward': '瀏覽優惠資訊，領取心儀禮遇。',
    'goodiebagSuccessfullyUnlockedBoth': '成功解鎖<strong>‘{name}’</strong>及其<strong>{credit}</strong>獎賞積分和<strong>{quota}</strong>個配額',
    'goodiebagSuccessfullyUnlockedCash': '成功解鎖<strong>‘{name}’</strong>及其<strong>{credit}</strong>獎賞積分',
    'goodiebagSuccessfullyUnlockedGift': '成功解鎖<strong>‘{name}’</strong>及其<strong>{quota}</strong>個配額',
    'goodiebagSuccessfullyUnlockedXBoth': '成功領取獎賞。',
    'goodiebagSuccessfullyUnlockedXCash': '成功領取獎賞。',
    'goodiebagSuccessfullyUnlockedXGift': '成功領取獎賞。',
    'goodiebagSeemore': '查看更多',
    'goodiebagExplorecashvoucher': '探索現金券',
    'goodiebagAcquirecredit': '瀏覽優惠資訊，使用積分領取心儀禮遇。',
    'goodiebagStillHave': '你還有',
    'goodiebagStillHave2': '<b>{cash}</b>',
    'goodiebagStillHave2b': '獎賞積分&nbsp;',
    'goodiebagStillHave3': ' (共{limit}獎賞積分)',
    'goodiebagSort': '排序',
    'goodiebagFilters': '篩選',
    'goodiebagLocationFilters': '地點',
    'goodiebagLocationFilterArea': '區域',
    'goodiebagLocationFilterDistrict': '地區',
    'goodiebagResults': '{count} 個結果',
    'goodiebagFeature': '精選',
    'goodiebagVaildtil': '有效至{validTil}',
    'goodiebagNoVouch': '噢！還未有現金券呢！',
    'goodiebagExploreOffer': '瀏覽最新優惠',
    'goodiebagOnlyAcquire': '每人只限下載 <b>{limit}</b>張優惠券/現金券',
    'goodiebagOwnedCouponLimit': '而每次最多只可持有未使用之獎賞<b>{limit}</b>張。',
    'goodiebagCouponDownloadLimit': '你最多可領取此獎賞 <b>{limit}</b> 次，',

    'goodiebagCouponLimitMix1': '你最多可領取此獎賞<b>{limit1}</b>次。',
    'goodiebagCouponLimitMix3': '你每次最多只可持有未使用之獎賞<b>{limit3}</b>張。',
    'goodiebagCouponLimitMix1And3': '你最多可領取此獎賞<b>{limit1}</b>次，而每次最多只可持有未使用之獎賞<b>{limit3}</b>張。',
    '//goodiebagOwnedCouponLimitSTARRED': '備註：推廣期內，你每次最多只可領取「音樂會門票」<b>{limit}</b> 次。',
    'goodiebagOwnedCouponLimitSTARRED': '備註：推廣期內，你每次最多只可領取「香港夜饗樂」<b>{limit}</b> 次。',
    'goodiebagRequirecredits': '所需獎賞積分 (每份)',
    'goodiebagAcquireNow': '加到「我的錢包」',
    'goodiebagAvailableOn': '可於{date}下載',
    'goodiebagFailedAcquire1': '未能新增此優惠至「我的錢包」',
    'goodiebagFailedAcquire2': '你正在兌換的獎勵已售罄',
    'goodiebagFailedAcquire6': '你正在兌換的獎勵已售罄',
    'goodiebagFailedAcquire7': '[確定]',
    'goodiebagFailedAcquire10': '已超出您錢包内可持有優惠的上限',
    'goodiebagFailedAcquire14': '獎賞積分/餘額不足',
    'goodiebagConfirmation': '請確認以下資料',
    'goodiebagCampaginExpiryDateText': '到期日 (GMT+8)',
    'goodiebagTotalCreditsrequired': '合共所需獎賞積分',
    'goodiebagRemainingCredits': '領取後剩餘的獎賞積分',
    'goodiebagConfirm': '確定',
    'goodiebagReminderAllTime': '優惠一經選定後，不可更改或取消，逾期亦不獲補發。',
    'goodiebagReminderRelative': '優惠一經選定後 {day}日內有效，不可更改或取消，逾期亦不獲補發。',
    'goodiebagReminderAbsolute': '優惠一經選定後，有效期限至{endDate}，不可更改或取消，逾期亦不獲補發。',
    'goodiebagExploreLimitedoffers': '探索優惠券',
    'goodiebagAcquireLimitedoffer': '瀏覽優惠資訊，領取心儀禮遇。',
    'goodiebagYousStillHave1': '您還可領取',
    'goodiebagYousStillHave2': '<b>{cash}</b>',
    'goodiebagYousStillHave2b': ' 份禮遇',
    'goodiebagYousStillHave3': ' (共{limit}份)',
    'goodiebagYousStillHave4': '&nbsp;',
    'goodiebagUnlimitedRewardTitle': '立即獲取專屬精彩優惠！',
    'goodiebagNoLimiteedoffer': '噢！還未有優惠券呢！',
    'goodiebagSelectQuantily': '選擇數量',
    'goodiebagQuantily': '數量',
    'goodiebagTotalQuotas': '所需配額',
    'goodiebagRemainingQuotas': '剩餘可領取禮遇數量',
    'goodiebagClearAll': '清除所有',
    'goodiebagCategory': '分類',
    'goodiebagBrand1': '品牌',
    'goodiebagOfferTypelabel': '標籤',
    'goodiebagCreditRange': '所需獎賞積分 ',
    'goodiebagValidPeriod': '使用期限',
    'goodiebagNoRxpiryDate': '沒有使用期限',
    'goodiebagViewItems': '查看結果',
    'goodiebagNoResultsFound': '沒有結果。 嘗試其他搜索。',
    'goodiebagFeatured': '精選',
    'goodiebagAlphabetical(aToz)': '字母排序(A至Z)',
    'goodiebagAlphabetical(zToa)': '字母排序(Z至A)',
    'goodiebagCredit(lowTohigh)': '獎賞積分(低至高)',
    'goodiebagCredit(highTolow)': '獎賞積分(高至低)',
    'goodiebagAlpha...(aToz)': '字母排序(A至Z)',
    'goodiebagAlpha...(zToa)': '字母排序(Z至A)',
    'goodiebagCre...(lowTohigh)': '獎賞積分(低至高)',
    'goodiebagCre...(highTolow)': '獎賞積分(高至低)',
    'goodiebagSuccessfullyAcquired1': '成功領取',
    'goodiebagSuccessfullyAcquired2': '份禮遇：',
    'goodiebagSuccessfullyAcquired4': '份禮遇：',
    'goodiebagSuccessfully': '成功領取獎賞。',
    'goodiebagOk': '確定',
    'goodiebagBrowseOtherOffers': '瀏覽其他優惠',
    'goodiebagSsomeOffers': '你可能還喜歡',
    'goodiebagAll': '全部',
    'goodiebagNothingHere': '暫無可用的優惠券或門票',
    'goodiebagVoucharNothingHere': '未有優惠券',
    'goodiebagCouponsNothingHere': '未有優惠券',
    'goodiebagTicketsNothingHere': '暫無可用門票',
    'goodiebagBrowseAround': '立即瀏覽最新優惠！',
    'goodiebagBrowseAroundCenter': ' 立即瀏覽最新優惠！',
    'goodiebagSelectAll': '選擇所有',
    'goodieBagExpired': '獎賞已過期',
    'goodieBagOutOfStockPrompt': '此獎賞已派發完畢。',
    'goodieBagNotEntitlePrompt': '您未有權限參與此活動',
    'goodiebagInvalidCountryCode': '此獎賞不適用於您的手機號碼所屬的國家或地區。',
    'goodiebagPerheadLimitReached': '已達此獎賞之領取上限。',
    'couponCampaginReminderTitle': '提示',
    'couponCampaginReminderContent': '添加到「我的錢包」後，優惠券將不可更改或取消。',
    'couponCampaginReminderConfirm': '確認',
    'couponCampaginReminderCancel': '取消',
    'campaginInvalidCountryCode': '此優惠不適用於您的手機號碼所屬的國家或地區。',
    'findYourAcquiredReward': '在「我的錢包」内查看已領取的獎賞',
    'sessionTitle': '瀏覽時間已完結',
    'sessionContent': '你的瀏覽時間已完結，請按「確認」重新排隊進入我們的網站。',
    'ageTitle': 'Important notice<br />重要告示',
    'ageContent': 'Under the law of Hong Kong, intoxicating liquor must not be sold or supplied to a minor in the course of business. I declare that I am aged 18 or above.<br />根據香港法律，不得在業務過程中，向未成年人售賣或供應令人醺醉的酒類。謹此聲明本人已年滿18歲。',
    'ageContentOK': 'I am aged 18 or above',
    'ageContentOKSecond': '本人年滿18歲',
    'ageContentCancel': 'Leave',
    'ageContentCancelSecond': '離開',
    'referenceID': '參考編號：',
    'campaginCategoryTitle': '選擇單個分類:',
    'headerBack': '返回',
    'toHome': '‘首頁’',
    'toSlotName': '‘{creditName}’',
    'toDeals': '‘全城優惠’',
    'toMyWallet': '‘我的錢包’',
    'toMyRewards': '‘香港大禮包’',
    'toSearchResult': '搜尋結果',
    'searchResult': '搜尋結果',
    'acquiredFortuneBagCouponsSuccessful': '你已成功領取以下「{goodiebagName}」禮遇！',
    'acquiredFortuneBagCouponsSuccessfulMany': '你已成功領取「{goodiebagName}」禮遇！',
    'tipShowFortuneBagCoupon': '優惠可於「我的錢包」找到。',
    'tipShowFortuneBagCouponMany': '優惠可於「我的錢包」找到。',
    'acquiredFortuneBagCouponsSuccessfulButtonText': '完成',
    'showCodeToStaff': '使用此券時請出示優惠碼',
    'tipShowFullBarCode': '如果條形碼無效,請<span>點擊此處來放大查看</span>。',
    'partnerCodeReferenceId': '參考編號:',
    'ViewFullBarcode': '查看條形碼',
    'fortunebagAcquireGoodiebag': '查看「我的錢包」',
    'rewardUnavailableTitle': '獎賞暫時無法領取',
    'rewardUnavailableMessage': '請稍後再回來領取你的獎賞。',
    'rewardUnavailableButton': '好的，我稍後再來',
    'rewardNotReadyButton': '獎賞暫時無法領取',
    'notAddMywalletRewardTitle': '未能新增此優惠到「我的錢包」',
    '//notAddMywalletRewardMessage': '已達「我的錢包」中可儲存之「限量獎賞」上限。',
    'notAddMywalletRewardMessage': '「我的錢包」中可儲存之「香港夜饗樂」本地市民版餐飲消費券數量已達上限。',

    '//homeUseCouponNotAvailable': '這張優惠券只適用於指定日期或時間。',
    'homeUseCouponNotAvailable': '使用優惠券 (未開始)',
    'couponNotAvailableInSpecificDaysPromptTitle': '暫不可用',
    'couponNotAvailableInSpecificDaysPrompt': '這張優惠券只適用於指定日期或時間。\n請在優惠券詳情中查看可使用時間。',
    'couponInvalidPromptTitle': '優惠券無效',
    'couponInvalidPrompt': '此優惠券已過期。',
    'couponUsedPrompt': '此優惠券已被使用。',
    'couponInactivePrompt': '此優惠券暫不可用。',
    'couponUnavailablePromptTitle': '暫不可用',
    'couponNotInActivePeriodPrompt': '此優惠券尚未生效，請查看有效期。',

    'recaptcha_low_score_title': '提示',
    'recaptcha_low_score_content': '<div className="recaptcha-fail-reminder-title">暫時無法處理你的指示，請嘗試按以下步驟進行操作</div><ul><li>嘗試使用其他瀏覽器</li><li>暫時關閉瀏覽器中的任何廣告攔截器或擴展程式</li><li>停用任何 VPN 或代理服務，直接連接到互聯網</li><li>避免快速、重複的操作</li></ul>',
    'recaptcha_fail_content': '目前無法處理你的請求，請稍後再試。',

    'searchButton': '搜尋',
    'goodiebagRecommend': '精選',
    'goodiebagSearchPlaceholder': '在{goodiebagName}中搜尋禮遇',
    'inSearching': '搜尋中...',
    'searchResultCount': '符合關鍵詞‘<b>{keyword}</b>’的搜尋結果共有{count}項',
    'searchNoResultTitle': '沒有搜尋結果',
    'searchNoResultContent': '請嘗試搜尋其他關鍵詞。',

    'goodiebagHasNotStartedYet': '此推廣活動即將開始，敬請期待。',
    'goodiebagHasExpired': '此推廣活動現已結束。',
    'goodiebagIsNotAvaliable': '此推廣活動頁面不存在。',
    'tryOtherGoodiebag': 'Please try other goodie bag.',
    'tryAgainLater': 'Please try again later.'

  },
  'zh-s': {
    'navBarMenu': '选单',
    'navBarMenuAlert': '通知',
    'navBarWallet': '我的钱包',
    'navBarExplore': '全城优惠',
    'navBarRewards': '我的奖赏',
    'navDeals': '优惠资讯',
    'navDealsLow': '优惠资讯',
    'homeSignUp': '登入或注册，探索更多优惠',
    'homeShowMore': '显示更多',
    'homeExploreMore': '发掘更多',
    'homeTopOfferTitle': '全城优惠',
    'homeTopOfferViewMore': '查看更多优惠',
    'linkCopied': '链接已复制，您可以在浏览器中粘贴并查看。',
    'linkCopiedShort': '已复制链接',
    'codeCopiedShort': '已复制代码',
    'homeDiscoverOffer': '立即发掘更多属于你的优惠！',
    'homeBrowseOffer': '尽情浏览，领取你的优惠！',
    'headerlanguage': 'Language / 語言',
    'headerHome': '首页',
    'headerHomeLow': '首页',
    'headerMyWallet': '我的钱包',
    'headerMyWalletLow': '我的钱包',
    'headerDearMember': '亲爱的用户',
    'homeMyWallet': '我的钱包',
    'headerMyRewards': '我的奖赏',
    'headerMyRewardsSingle': '我的奖赏',
    'headerMyAccount': '我的帐户',
    'headerMyAccountLow': '我的帐户',
    'headerHelloName': '你好，',
    'headerLoginSignup': '登入/注册',
    'headerLogout': '登出',
    'headerLogoutLow': '登出',
    'generalLogoutPromptTitle': '登出?',
    'generalLogoutPromptDescription': '你是否确认登出?',
    'generalLogoutPromptCancel': '取消',
    'generalLogoutPromptLogout': '确认',
    'showLanguageReminderTitle': '提示',
    'showLanguageReminderContent': '你是否想要切换到简体中文界面？',
    'showLanguageReminderOk': '是',
    'showLanguageReminderCancel': '否',
    'footerTermsConditions': '条款及细则',
    'miniNavTermsConditions': '条款及细则',
    'footerPrivacyPolicy': '隐私政策',
    'footerFAQ': '常见问题',
    'footer2021HKTBAllRightsRecerved': '版权所有© {year} 香港旅游发展局。',
    'footerReCAPTCHA': 'This site is protected by reCAPTCHA and the Google:',
    'footerRePrivacyPolicy': 'Privacy Policy',
    'footerTermsOfService': 'Terms of Service',
    'myWalletMyVouchers': '我的奖赏',
    'myWalletMyCoupons': '我的优惠券',
    'myWalletCoupons': '优惠券',
    'myWalletTickets': '门票',
    'myWalletAllStatus': '全部状态',
    'myWalletAvailableAndUnavailable': '可使用 / 未可用',
    'myWalletRedeemedExpired': '已使用/过期',
    'myWalletRecentlyAdded': '最近领取',
    'myWalletRedeemed': '已使用',
    'myWalletExpired': '已過期',
    'myWalletNoExpiryDate': '没有期限',
    'myWalletLastDay': '最后一天',
    'myWalletStartDate': '开始日期: ',
    'myWalletXDaysLeft': '最后{dayCount}天',
    'myWalletExpiryDate': '到期日: ',
    'myWalletEffectiveDatetext': '有效期',
    'myWalletEffectiveDate': '{startDate}到{endDate}',
    'myWalletEffectiveDateLessOneDay': '{endDate}',
    'myWalletEmptyCouponsMessage': '噢！你还未有优惠券呢！立即浏览最新优惠！',
    'myWalletDiscover': '发掘更多',
    'myWalletValidTime': '有效至',
    'myWalletRedeemedTime': '使用日期：{dateString}',
    'myWalletApplicableStores': '适用商店',
    'myWalletSeeMore': '查看更多',
    'myWalletUserExperienceMessage': '请使用移动装置扫描此二维码，并在手机上使用此优惠券。',
    'myWalletUserExperienceTicketMessage': '请使用移动装置扫描此二维码，并在手机上使用此门票。',
    'myWalletScanQRcodeMessage': '您可以扫描二维码，使用移动设备浏览优惠详情。',
    'myWalletScanQRcodeTicketMessage': '您可以扫描二维码，使用移动设备浏览门票详情。',
    'myWalletAvailableTime': '此优惠券将由',
    'myWalletTicketAvailableTime': '此门票将由',
    'myWalletAvailableTimeSuffix': '开始可以使用',
    'myWalletRedeemNow': '立即使用',
    '//myWalletStarredAlert': '提示: 你已持有 <b>{count}</b> 张“音乐会门票”。你最多只可同时持有 <b>{limit}</b> 张未使用之“音乐会门票”。',
    'myWalletStarredAlert': '提示：每位登记者同一时间最多只可领取及储存<span><b>{limit}张</b></span>“香港夜飨乐”本地市民版餐饮消费券。请注意您现时已持有<span><b>{count}张</b></span>餐饮消费券。',
    'acquireUseTicketOnMobile': '请在手机上使用此门票。',
    'myAccountAccountInformation': '我的个人资料',
    'myAccountFirstName': '名',
    'myAccountLastName': '姓',
    'myAccountNickname': '昵称',
    'myAccountIam': '请输入',
    'myAccountEmail': '电邮',
    'myAccountResidence': '居住地',
    'myAccountIamFrom': '请选择',
    'myAccountSalutationLabel': '称谓',
    'myAccountSalutation': '请选择',
    'myAccountCountry': '国家',
    'myAccountMobileNo': '手机号码',
    'myAccountBirthMonthYear': '出生月份',
    'myAccountOptional': '(可选填)',
    'myAccountSelectBirthdate': '请选择',
    'myAccountEditBirthprompt': '此项一经确认，不能更改。',
    'myAccountUpdate': '更新',
    'myAccountAccountSettings': '帐户设定',
    'myAccountLanguagePreference': '语言',
    'myAccountEditLanguageRegion': '请选择',
    'myAccountInterestPreference': '兴趣',
    'myAccountDirectMarketing': '直接促销推广讯息',
    'myAccountDirectMarketinghints': '我希望收到香港旅游发展局的直接促销活动消息、资讯及个人化讯息。',
    'myAccountChangePassword': '更改密码',
    'myAccountCurrentPassword': '现时密码',
    'myAccountNewPassword': '新密码',
    'myAccountPasswordErrormessage': '必须至少8个字元，并包含英文大写字母及数字',
    'myAccountDeleteAccount': '删除我的账户',
    'myAccountDeleteAccountPrompt': '我们会永久删除你的帐户、优惠券和数据。',
    'myAccountDelete': '删除',
    'myAccountUnsavedPromptTile': '您有未储存的更改',
    'myAccountUnsavedPromptDescription': '离开前储存更改',
    'myAccountUnsavedPromptDiscardchanges': '放弃更改',
    'myAccountUnsavedPromptSave': '储存',
    'myAccountBirthConfirmPromptTitle': '确认？',
    'myAccountBirthConfirmPromptDescription': '出生月份一经确认，不能更改。',
    'myAccountBirthConfirmPromptCancel': '取消',
    'myAccountBirthConfirmPromptConfirm': '确认',
    'myAccountSuccessfullyUpdated': '更新成功！',
    'myAccountDeleteAccountconfirmPromptTitle': '删除你的帐户',
    'myAccountDeleteAccountconfirmPromptDescription': '你确定要永久删除此帐户及其数据？确认删除后，已领取的优惠券及门票等将被永久删除及失效。',
    'myAccountDeleteAccountconfirmPromptCancel': '取消',
    'myAccountDeleteAccountconfirmPromptConfirm': '确认',
    'myAccountDeleteAccountconfirm2PromptTitle': '请先验证你的身份',
    'myAccountDeleteAccountconfirm2PromptDescription': '我们已发送连结到你的电邮帐户，请检查并按指示继续。',
    'myAccountDeleteAccountconfirm2PromptOK': '知道了',
    'myAccountDeleteAccountconfirm2PromptTitleMobile': '',
    'myWalletAcquiredVouchar': '恭喜！你已经成功获得了优惠券！',
    'myWalletAcquiredCoupons': '恭喜！你已经成功获得了优惠券！',
    'myWalletAcquiredTickets': '你已经成功获得门票！',
    'myWalletAcquiredCouponViewVouchar': '查看优惠券',
    'myWalletAcquiredCouponViewCoupons': '查看优惠券',
    'myWalletAcquiredCouponViewTickets': '查看门票',
    'myWalletValueCoupon': '仅适用于',
    'myWalletScanQRcode': '扫描商户二维码',
    'myWalletScanQRcodeAlign': '对齐扫描框扫描二维码',
    'myWalletScanQRcodeScanning': '扫描中...',
    'myWalletSuccessfullyRedeemedTitle': '已于',
    'myWalletSuccessfullyRedeemedSuffix': '成功使用',
    'myWalletSuccessfullyRedeemedTakeSuvey': '填写问卷',
    'myWalletSuccessfullyRedeemedDone': '确认',
    'myWalletInvalidQRcodeLineOne': '无效的二维码',
    'myWalletInvalidQRcodeLineTwo': '尝试其他二维码或重试。',
    'myWalletMerchantScan': '扫描此条码，并更新POS的优惠券使用纪录。',
    'myWalletTakeSurvey': '填写问卷',
    'myWalletViewCouponCode': '查看优惠码',
    'myWalletViewTicketCode': '查看门票',
    'myWalletViewCode': '查看商户条码',
    'myWalletUsingQRCodetip': '使用此二维码时，必须持有有效帐户。如帐户已被删除，此二维码亦会失效。',
    'myWalletUseTicket': '请于活動入场处出示此二维码',
    'myWalletUseCoupon': '使用此券时请出示优惠码',
    'myWalletUseTicketPromptTitle': '使用门票',
    'myWalletUseCouponPromptTitle': '使用优惠券',
    'myWalletUseTicketPromptDescription': '你确认现在使用门票?',
    'myWalletUseCouponPromptDescription': '你确认现在使用优惠券？',
    'myWalletUseCouponPromptUselater': '以后使用',
    'myWalletUseCouponPromptConfirm': '确认',
    'myWalletUseCouponDiscount': '于商户网站使用此代码即可享指定优惠!',
    'myWalletUseCouponDiscountLink': '使用下列指定网店连结即可享优惠或奖赏!',
    'myWalletCopyCode': '复制优惠码',
    'wechatLogout': '登出',
    'campaignApplicableStores': '适用商店',
    'campaignEventLocation': '会场地点',
    'campaignBusinessHours': '营业时间',
    'campaignFortuneBagcampaign': '福袋活动',
    'homeLatest': '最新',
    'campaignUesCoupon': '现金券/优惠券只能于行动装置上使用',
    'campaignAddtoMyallet': '新增到我的钱包',
    'campaignShare': '分享',
    'campaignTooltips': '已复制到剪贴板',
    '//campaignStarredCampaign': '音乐会门票',
    '//campaignSta...Campaign': '音乐会门票',
    'campaignStarredCampaign': '香港夜飨乐',
    'campaignSta...Campaign': '香港夜飨乐',
    'campaignOutOf': '已换罄',
    'campaignLow': '尚余少量',
    'homeEmptyOffers': '噢！你还未有优惠券呢！立即浏览最新优惠！',
    'generalBroeserSupportedTitle': '抱歉，我们并不支援你的浏览器。',
    'generalBroeserSupportedDescription': '若要浏览此网站，请升级到',
    'generalBroeserSupportedAffix': '浏览器',
    'generalBroeserSupportedOr': '或',
    'generalBroeserSupportedSuffix': '浏览器的最新版本。',
    'generalPagenotFoundtitle': '找不到页面。',
    'generalPagenotFoundDescription': '抱歉未能提供此页面。',
    'generalLanguageSupported': '请按页面顶部的 🌐 以选择另一种语言。',
    'generalBacktoHome': '返回首页',
    'generalServerError': '服务器错误，请稍后再试。',
    'generalServerBusyError': '系统繁忙，请稍后重试。',
    'generalAddtoHomescreenClick': '点击',
    'generalAddtoHomescreenSuffix': '选择',
    'generalAddtoHomescreenAdd': '“添加到主画面”',
    'generalAddtoHomescreenAffix': '方便你获得我们最新的优惠资讯。',
    'generalAddtoHomescreenAppSuffix': '选择',
    'generalAddtoHomescreenAppInstall': '“安装应用程式”',
    'generalAddtoHomescreenAppTo': '并',
    'generalAddtoHomescreenAppAdd': '添加到主画面',
    'generalAddtoHomescreenAppAffix': '方便你获得我们最新的优惠资讯。',
    'generalAddtoHomescreenWebSuffix': '选择',
    'generalAddtoHomescreenWebInstall': '“安装”',
    'generalAddtoHomescreenWebTo': '并',
    'generalAddtoHomescreenWebAdd': '添加到主画面',
    'generalAddtoHomescreenWebDescription': '方便你获得我们最新的优惠资讯。',
    'generalAddtoHomescreenOfferYoucan': '你可以选择',
    'generalAddtoHomescreenOfferAdd': '“添加到主画面”',
    'generalAddtoHomescreenOfferDescription': '方便你获得我们最新的优惠资讯！',
    'generalAddtoHomescreenOfferAffix': '查看详情',
    'generalCookieAffix': '本网站使用Cookies来确保您获得我们网站上的最佳体验。',
    'generalCookiePrefix': '欲了解更多信息，请咨询我们的',
    'generalCookieSuffix': 'Cookie政策',
    'ContentNotAvailable': '所选语言未能提供此内容。',
    'generalGotIt': '知道了',
    'loginMobileNo': '手机',
    'profileAddSecondarycontact': '请提供另一联系方式，以获得我们的最新资讯及优惠',
    'profileAddSecondarycontactPhoneReminder': '*请确保你的行动装置能够接收短信（SMS）',
    'generalCountryCode': '国家代码',
    'profileMobileNo.': '手机号码',
    'profileSendCode': '发送验证码',
    'profileVerificationCode': '请输入验证码',
    'profileResend': '重新发送',
    'profileResendInSeconds': '重新发送 {0}秒',
    'profilePhoneTakenTryAnother': '此手机号码已被使用。请尝试另一个。',
    'profileEmailTakenTryAnother': '此电邮地址已被使用。请尝试另一个。',
    'profileValidphoneNo': '请输入有效的电话号码。',
    'profileValidemail': '请输入有效的电邮地址。',
    'profileInvalidVerificationcode': '验证码无效。',
    'profileMobileSuccessfullyVerified': '你的手机号码已成功验证！',
    'profileEmailSuccessfullyVerified': '你的电邮地址已成功验证！',
    'profileVerified': '已验证',
    'profileEmailAddress': '电邮',
    'profileVerificationTip': '你可能需要联络电讯商开通服务，才能接收从香港特别行政区发送的短信。请确保你的行动装置能够接收短讯（SMS）。',
    'commonLogoutCancel': '取消',
    'commonLogoutConfirm': '确认',
    'mycouponFeedback': '欢迎多提宝贵意见',
    'mycouponSubmit': '提交',
    'mycouponSubmissionTitle': '谢谢！\r',
    'mycouponSubmissionDescription': '您的回应已发送！',
    'mycouponSubmissionAffix': '查看其他优惠',
    'mycouponfullWindowBarcode': '请将屏幕旋转至横向模式以完整显示条码',
    'campaignGetCoupon': '新增到我的钱包',
    'availableOn': '可于{startDate}开始领取',
    'fortuneBagExpired': '福袋已过期',
    'homeUseCoupon': '使用优惠券',
    'homeUseTicket': '使用门票',
    'myWalletClickCouponunlockCode': '于商户网站使用此代码即可享指定优惠!',
    'homeMembershipID': '会员编号',
    'footerT&C': '条款及细则',
    'campaignAvailableOn': '可用于',
    'mycouponTransactionAmount': '请输入使用此优惠券的交易金额。',
    'generalOfferMessageTitle': '',
    'generalOfferMessageDescription': '',
    'generalTwoPasswordserrorMessage': '密码不相符',
    'campaignGeneralMessagePrompt': '点击浏览查看更多详情：',
    'generalConfirmNewPassword': '确定新密码',
    'campaignEnjoyOffer': '立即享受此优惠：\n',
    'myAccountDeleteMolAccountconfirm2PromptTitle': '请先验证你的身份',
    'myAccountDeleteMolAccountconfirm2PromptDescription': '我们已发送连结到您的手机号码，请继续删除帐户的过程。',
    'myAccountDeleteMolAccountByWhatsAppconfirm2PromptDescription': '我们已透过WhatsApp发送连结到您的手机号码，请检查并按指示继续。',
    'myAccountDeleteMolAccountBySMSconfirm2PromptDescription': '我们已透过短信发送连结到您的手机号码，请检查并按指示继续。',
    'myAccountDeleteMolAccountconfirm2PromptOK': '知道了',
    'detailDetail': '优惠详情',
    'tourcardSectionTourCardSection': '行程表',
    'offerdetailsectionOfferDetailsection': '优惠内容',
    'instructionsectionInstructionSection': '如何使用',
    'merchantTerms&ConditionsMTConditions': '商户条款及细则',
    'genericTerms&ConditionsGTConditions': '一般条款及细则',
    'profileEmailPlaceHolder': '电邮地址',
    'storeWeekdayTo': '至',
    'storeClosed': '休息',
    'storePublicHoliday': '公众假期',
    'myAccountIncorrectCurrentPassword': '现时密码不正确',
    'myAccountSamePasswordError': '不能跟现时密码相同',
    'myAccountRequired': '有<div class="zh">*</div>的栏位必须填写资料',
    'storeArea': '区域',
    'storeDistrict': '分区',
    'navigationNavigationTitle': '如何兑换优惠',
    'navigationStep1': '使用电邮或手机号码注册并设立密码',
    'navigationStep2': '选择优惠，点击“新增到我的钱包”',
    'navigationStep3': '进入“我的钱包”',
    'navigationStep4': '点击“使用优惠券”',
    'navigationStep5': '扫描商户二维码',
    'navigationStep6': '兑换成功后收取通知',
    'navigationStep7': '立即享受优惠',
    'navigationContact': '联络我们',
    'cookiepolicyCookiePolicy': 'Cookie政策',
    'cookiepolicyManageCookie': 'Cookie 设置',
    'wechatLoginWelcom': '欢迎！',
    'wechatLoginMessage': '登入 / 注册“我的钱包”，在我们的一站式本地旅游消费和优惠信息平台上尽享精选礼遇!',
    'wechatLoginT&CandPolicy': '我已阅读并同意我们的 条款&条件 和 隐私政策。',
    'wechatLoginDescription': '当你使用我们的服务，即表示你同意我们的',
    'wechatLoginT&C': '使用条款',
    'wechatLoginAnd': '和',
    'wechatLoginPolicy': '资料私隐政策。',
    'wechatLoginSignup': '登入/注册',
    'wechatLoginSignupNav': '登入/注册',
    'campaignFull': '名额已满',
    '//campaignSTARRED': '音乐会门票',
    '//campaignExceededTheMax': '已达“我的钱包”中可储存之“音乐会门票”上限。',
    'campaignSTARRED': '香港夜飨乐',
    'campaignExceededTheMax': '“我的钱包”中可储存之“香港夜飨乐”本地市民版餐饮消费券数量已达上限。',
    'myAccountLoginMethods': '您可以通过以下方式登入',
    'myAccountWeChatAccount': '微信账户',
    'myRewardEmptyTitle': '暂时没有奖赏',
    'myRewardEmptyDesc': '点击下方浏览最新礼遇。',
    'goodiebagEntitlementLink1': '条款及细则',
    'goodiebagEntitlementLink2': '立即领取',
    'goodiebagEntitlementLink3': '已换罄',
    'goodiebagAcquireGoodiebagA1': '未能成功取得奖赏包',
    'goodiebagAcquireGoodiebagA2': '此奖赏已被领取。',
    'goodiebagAcquireGoodiebagA3': '确定',
    'goodiebagAcquireGoodiebagA5': '你已领取此奖赏。',
    'goodiebagAcquireGoodiebagA6': '查看我的奖赏',
    'goodiebagAcquireGoodiebagB2': '您未有权限参与此活动。',
    'goodiebagCheckmyWallet': '查看“我的钱包”',
    'goodiebagMyRewardCheckmyWallet': '查看“<img width="18px" style="margin-right:5px;margin-bottom: 3px;" class="wallet-icon" srcset="/img/my_wallet.1x.png, /img/my_wallet.2x.png 2x, /img/my_wallet.3x.png 3x" src="/img/my_wallet.2x.png"><b style="color: #E2002C;text-transform: uppercase;">我的钱包</b>"',
    'goodiebagFindredemption': '查看已领取的现金券或优惠券',
    'goodiebagRecentlyAdded': '最近领取',
    'goodiebagAcquiringAfterStart': '{startDate}开始领取{itemName}',
    'goodiebagAcquiringBeforeEnd': '请于{endDate} 前，将心仪礼遇加到“我的钱包”',
    'goodiebagAcquiringLastNDay': '最后{dayCount}天于{endTime}前取得你的{itemName}',
    'goodiebagAcquiringLastDay': '最后1天于{endTime}前取得你的{itemName}',
    'goodiebagExpired': '已过期',
    'goodiebagRewards': '奖赏',
    'goodiebagMax': '最大',
    'goodiebagMin': '最小',
    'goodieBagUnlimitedRewardDesc': '立即获取您的精彩优惠！',
    'goodiebagCashVouchers': '现金券',
    'goodiebagCredits': '奖赏积分',
    'goodiebagCreditTitle': '<strong>{owned}</strong>奖赏积分',
    'goodiebagLimitedOffers': '优惠券',
    'goodiebagQuotaTitle': '<strong>{owned}</strong>',
    'goodiebagTreatReward': '浏览优惠资讯，领取心仪礼遇。',
    'goodiebagSuccessfullyUnlockedBoth': '成功解锁<strong>‘{name}’</strong>及其<strong>{credit}</strong>奖赏积分和<strong>{quota}</strong>个配额',
    'goodiebagSuccessfullyUnlockedCash': '成功解锁<strong>‘{name}’</strong>及其<strong>{credit}</strong>奖赏积分',
    'goodiebagSuccessfullyUnlockedGift': '成功解锁<strong>‘{name}’</strong>及其<strong>{quota}</strong>个配额',
    'goodiebagSuccessfullyUnlockedXBoth': '成功领取奖赏。',
    'goodiebagSuccessfullyUnlockedXCash': '成功领取奖赏。',
    'goodiebagSuccessfullyUnlockedXGift': '成功领取奖赏。',
    'goodiebagSeemore': '查看更多',
    'goodiebagExplorecashvoucher': '探索现金券',
    'goodiebagAcquirecredit': '浏览优惠资讯，使用积分领取心仪礼遇。',
    'goodiebagStillHave': '你还有',
    'goodiebagStillHave2': '<b>{cash}</b>',
    'goodiebagStillHave2b': '奖赏积分&nbsp;',
    'goodiebagStillHave3': ' (共{limit}奖赏积分)',
    'goodiebagSort': '排序',
    'goodiebagFilters': '筛选',
    'goodiebagLocationFilters': '地点',
    'goodiebagLocationFilterArea': '区域',
    'goodiebagLocationFilterDistrict': '地区',
    'goodiebagResults': '{count} 个结果',
    'goodiebagFeature': '精选',
    'goodiebagVaildtil': '有效至{validTil}',
    'goodiebagNoVouch': '噢！还未有现金券呢！',
    'goodiebagExploreOffer': '浏览最新优惠',
    'goodiebagOnlyAcquire': '每人只限下载 <b>{limit}</b>张优惠券/现金券',
    'goodiebagOwnedCouponLimit': '而每次最多只可持有未使用之奖赏<b>{limit}</b>张。',
    'goodiebagCouponDownloadLimit': '你最多可换领此奖赏 <b>{limit}</b> 次，',

    'goodiebagCouponLimitMix1': '你最多可换领此奖赏<b>{limit1}</b>次。',
    'goodiebagCouponLimitMix3': '你每次最多只可持有未使用之奖赏<b>{limit3}</b>张。',
    'goodiebagCouponLimitMix1And3': '你最多可换领此奖赏<b>{limit1}</b>次，而每次最多只可持有未使用之奖赏<b>{limit3}</b>张。',
    '//goodiebagOwnedCouponLimitSTARRED': '备注：推广期内，你每次最多只可领取“音乐会门票”奖赏 <b>{limit}</b> 次。',
    'goodiebagOwnedCouponLimitSTARRED': '备注：推广期内，你每次最多只可领取“香港夜飨乐”奖赏 <b>{limit}</b> 次。',
    'goodiebagRequirecredits': '所需奖赏积分 (每份)',
    'goodiebagAcquireNow': '加到“我的钱包”',
    'goodiebagAvailableOn': '可于{date}下载',
    'goodiebagFailedAcquire1': '未能新增此优惠至"我的钱包"',
    'goodiebagFailedAcquire2': '你正在兑换的奖励已售罄',
    'goodiebagFailedAcquire6': '已超出此优惠的下载上限',
    'goodiebagFailedAcquire7': '[确定]',
    'goodiebagFailedAcquire10': '已超出您钱包内可持有优惠的上限',
    'goodiebagFailedAcquire14': '奖赏积分/余额不足',
    'goodiebagConfirmation': '请确认以下资料',
    'goodiebagCampaginExpiryDateText': '到期日 (GMT+8)',
    'goodiebagTotalCreditsrequired': '合共所需奖赏积分',
    'goodiebagRemainingCredits': '领取后剩余的奖赏积分',
    'goodiebagConfirm': '确定',
    'goodiebagReminderAllTime': '优惠一经选定后，不可更改或取消，逾期亦不获补发。',
    'goodiebagReminderRelative': '优惠一经选定后{day}日内有效，不可更改或取消，逾期亦不获补发。',
    'goodiebagReminderAbsolute': '优惠一经选定后，有效期限至{endDate}，不可更改或取消，逾期亦不获补发。',
    'goodiebagExploreLimitedoffers': '探索优惠券',
    'goodiebagAcquireLimitedoffer': '浏览优惠资讯，领取心仪礼遇。',
    'goodiebagYousStillHave1': '您还可领取',
    'goodiebagYousStillHave2': '<b>{cash}</b>',
    'goodiebagYousStillHave2b': ' 份礼遇',
    'goodiebagYousStillHave3': ' (共{limit}份)',
    'goodiebagYousStillHave4': '&nbsp;',
    'goodiebagUnlimitedRewardTitle': '立即获取您的精彩优惠！',
    'goodiebagNoLimiteedoffer': '噢！还未有优惠券呢',
    'goodiebagSelectQuantily': '选择数量',
    'goodiebagQuantily': '数量',
    'goodiebagTotalQuotas': '所需配额',
    'goodiebagRemainingQuotas': '剩余可领取礼遇数量',
    'goodiebagClearAll': '清除所有',
    'goodiebagCategory': '分类',
    'goodiebagBrand1': '品牌',
    'goodiebagOfferTypelabel': '标签',
    'goodiebagCreditRange': '所需奖赏积分 ',
    'goodiebagValidPeriod': '使用期限',
    'goodiebagNoRxpiryDate': '没有使用期限',
    'goodiebagViewItems': '查看结果',
    'goodiebagNoResultsFound': '没有结果。尝试其他搜索。',
    'goodiebagFeatured': '精选',
    'goodiebagAlphabetical(aToz)': '字母排序(A至Z)',
    'goodiebagAlphabetical(zToa)': '字母排序(Z至A)',
    'goodiebagCredit(lowTohigh)': '奖赏积分(低至高)',
    'goodiebagCredit(highTolow)': '奖赏积分(高至低)',
    'goodiebagAlpha...(aToz)': '字母排序(A至Z)',
    'goodiebagAlpha...(zToa)': '字母排序(Z至A)',
    'goodiebagCre...(lowTohigh)': '奖赏积分(低至高)',
    'goodiebagCre...(highTolow)': '奖赏积分(高至低)',
    'goodiebagSuccessfullyAcquired1': '成功领取',
    'goodiebagSuccessfullyAcquired2': '份礼遇：',
    'goodiebagSuccessfullyAcquired4': '份礼遇：',
    'goodiebagSuccessfully': '成功领取奖赏。',
    'goodiebagOk': '确定',
    'goodiebagBrowseOtherOffers': '浏览其他优惠',
    'goodiebagSsomeOffers': '你可能还喜欢',
    'goodiebagAll': '全部',
    'goodiebagNothingHere': '暂无可用的优惠券或门票',
    'goodiebagVoucharNothingHere': '未有优惠券',
    'goodiebagCouponsNothingHere': '未有优惠券',
    'goodiebagTicketsNothingHere': '暂无可用门票',
    'goodiebagBrowseAround': '立即浏览最新优惠！',
    'goodiebagBrowseAroundCenter': ' 立即浏览最新优惠！',
    'goodiebagSelectAll': '选择所有',
    'goodieBagExpired': '奖赏已过期',
    'goodieBagOutOfStockPrompt': '此奖赏已派发完毕。',
    'goodieBagNotEntitlePrompt': '您未有权限参与此活动。',
    'goodiebagInvalidCountryCode': '此奖赏不适用于您的手机号码所属的国家或地区。',
    'goodiebagPerheadLimitReached': '已达此奖赏之领取上限。',
    'couponCampaginReminderTitle': '提示',
    'couponCampaginReminderContent': '添加到“我的钱包“后，优惠券将不可更改或取消。',
    'couponCampaginReminderConfirm': '确认',
    'couponCampaginReminderCancel': '取消',
    'campaginInvalidCountryCode': '此优惠不适用于您的手机号码所属的国家或地区。',
    'findYourAcquiredReward': '在 “我的钱包” 内查看已领取的奖赏',
    'sessionTitle': '浏览时间已完结',
    'sessionContent': '你的浏览时间已完结，请按“确认”重新排队进入我们的网站。',
    'ageTitle': 'Important notice<br />重要告示',
    'ageContent': 'Under the law of Hong Kong, intoxicating liquor must not be sold or supplied to a minor in the course of business. I declare that I am aged 18 or above.<br />根据香港法律，不得在业务过程中，向未成年人售卖或供应令人醺醉的酒类。谨此声明本人已年满18岁。',
    'ageContentOK': 'I am aged 18 or above',
    'ageContentOKSecond': '本人年满18岁',
    'ageContentCancel': 'Leave',
    'ageContentCancelSecond': '离开',
    'referenceID': '参考编号：',
    'campaginCategoryTitle': '选择单个分类:',
    'headerBack': '返回',
    'toHome': '‘首页’',
    'toSlotName': '‘{creditName}’',
    'toDeals': '‘全城优惠’',
    'toMyWallet': '‘我的钱包’',
    'toMyRewards': '‘香港大礼包’',
    'toSearchResult': '搜索结果',
    'searchResult': '搜索结果',
    'acquiredFortuneBagCouponsSuccessful': '你已成功领取以下“{goodiebagName}”礼遇！',
    'acquiredFortuneBagCouponsSuccessfulMany': '你已成功领取“{goodiebagName}”礼遇！',
    'tipShowFortuneBagCoupon': '优惠可于“我的钱包”找到。',
    'tipShowFortuneBagCouponMany': '优惠可于“我的钱包”找到。',
    'acquiredFortuneBagCouponsSuccessfulButtonText': '完成',
    'showCodeToStaff': '使用此券时请出示优惠码',
    'tipShowFullBarCode': '如果条形码无效,请<span>点击此处来放大查看</span>。',
    'partnerCodeReferenceId': '参考编号:',
    'ViewFullBarcode': '查看条形码',
    'fortunebagAcquireGoodiebag': '查看“我的钱包”',
    'rewardUnavailableTitle': '奖赏暂时无法领取',
    'rewardUnavailableMessage': '请稍后再回来领取你的奖赏。',
    'rewardUnavailableButton': '好的，我稍后再来',
    'rewardNotReadyButton': '奖赏暂时无法领取',
    'notAddMywalletRewardTitle': '未能新增此优惠到「我的钱包」',
    '//notAddMywalletRewardMessage': '已达「我的钱包」中可储存之「限量奖赏」上限。',
    'notAddMywalletRewardMessage': '“我的钱包”中可储存之“香港夜飨乐”本地市民版餐饮消费券数量已达上限。',

    // 'homeUseCouponNotAvailable': '这张优惠券只适用于指定日期或时间。',
    'homeUseCouponNotAvailable': '使用优惠券（未开始）',
    'couponNotAvailableInSpecificDaysPromptTitle': '暂不可用',
    'couponNotAvailableInSpecificDaysPrompt': '这张优惠券只适用于指定日期或时间。\n请在优惠券详情中查看可使用时间。',
    'couponInvalidPromptTitle': '优惠券无效',
    'couponInvalidPrompt': '此优惠券已过期。',
    'couponUsedPrompt': '此优惠券已被使用。',
    'couponInactivePrompt': '此优惠券暂不可用。',
    'couponUnavailablePromptTitle': '暂不可用',
    'couponNotInActivePeriodPrompt': '此优惠券尚未生效，请查看有效期。',

    'recaptcha_low_score_title': '提示',
    'recaptcha_low_score_content': '<div className="recaptcha-fail-reminder-title">暂时无法处理您的指示，请尝试按以下步骤进行操作</div><ul><li>尝试使用其他浏览器</li><li>暂时关闭浏览器中的任何广告拦截器或扩展程序</li><li>停用任何VPN或代理服务，直接连接到互联网</li><li>避免快速、重复的操作</li></ul>',
    'recaptcha_fail_content': '目前无法处理你的请求，请稍后再试。',

    'searchButton': '搜索',
    'goodiebagRecommend': '精选',
    'goodiebagSearchPlaceholder': '在{goodiebagName}中搜索礼遇',
    'inSearching': '搜索中...',
    'searchResultCount': '符合关键词‘<b>{keyword}</b>’的搜索结果共有{count}项',
    'searchNoResultTitle': '没有搜索结果',
    'searchNoResultContent': '请尝试搜索其他关键词。',

    'goodiebagHasNotStartedYet': '此推广活动即将开始，敬请期待。',
    'goodiebagHasExpired': '此推广活动现已结束。',
    'goodiebagIsNotAvaliable': '此推广活动页面不存在。',
    'tryOtherGoodiebag': 'Please try other goodie bag.',
    'tryAgainLater': 'Please try again later.'
  },
  'zh-t-wechat': {
    'homeExploreMore': '全城優惠',
    'navBarRewards': '香港大禮包',
    'headerMyRewards': '香港大禮包',
    'headerMyRewardsSingle': '香港大禮包',
    'myWalletMyVouchers': '香港大禮包',
    'myRewardEmptyTitle': '暫時沒有「香港大禮包」獎賞',
    'myRewardEmptyDesc': '點擊下方瀏覽最新禮遇。',
    'goodiebagLimitedOffers': '尊享優惠',
    'myWalletCoupons': '尊享優惠',
    'myWalletAcquiredVouchar': '恭喜！你已經成功獲得了現金劵！',
    'myWalletAcquiredCoupons': '恭喜！你已經成功獲得了尊享優惠！',
    'myWalletAcquiredTickets': '你已經成功獲得門票。',
    'myWalletAcquiredCouponViewVouchar': '查看現金劵',
    'myWalletAcquiredCouponViewCoupons': '查看尊享優惠',
    'myWalletAcquiredCouponViewTickets': '查看門票',
    'goodiebagVoucharNothingHere': '未有現金劵',
    'goodiebagCouponsNothingHere': '未有尊享優惠',
    'headerBack': '返回',
    'toHome': '‘首頁’',
    'toSlotName': '‘{creditName}’',
    'toDeals': '‘全城優惠’',
    'toMyWallet': '‘我的錢包’',
    'toMyRewards': '‘香港大禮包’',
    'toSearchResult': '搜尋結果',
    'searchResult': '搜尋結果'
  },
  'zh-s-wechat': {
    'homeExploreMore': '全城优惠',
    'navBarRewards': '香港大礼包',
    'headerMyRewards': '香港大礼包',
    'headerMyRewardsSingle': '香港大礼包',
    'myWalletMyVouchers': '香港大礼包',
    'myRewardEmptyTitle': '暂时沒有“香港大礼包”奖赏',
    'myRewardEmptyDesc': '点击下方浏览最新礼遇。',
    'goodiebagLimitedOffers': '尊享优惠',
    'myWalletCoupons': '尊享优惠',
    'myWalletAcquiredVouchar': '恭喜！你已经成功获得了现金劵！',
    'myWalletAcquiredCoupons': '恭喜！你已经成功获得了尊享优惠！',
    'myWalletAcquiredTickets': '你已经成功获得门票！',
    'myWalletAcquiredCouponViewVouchar': '查看现金劵',
    'myWalletAcquiredCouponViewCoupons': '查看尊享优惠',
    'myWalletAcquiredCouponViewTickets': '查看门票',
    'goodiebagVoucharNothingHere': '未有现金劵',
    'goodiebagCouponsNothingHere': '未有尊享优惠',
    'headerBack': '返回',
    'toHome': '‘首页’',
    'toSlotName': '‘{creditName}’',
    'toDeals': '‘全城优惠’',
    'toMyWallet': '‘我的钱包’',
    'toMyRewards': '‘香港大礼包’',
    'toSearchResult': '搜索结果',
    'searchResult': '搜索结果'
  }
}

Object.assign(messages['en'], enLocale)
if (isWechatMiniProgram()) {
  Object.assign(messages['zh-s'], messages['zh-s-wechat'], zhsLocale)
  Object.assign(messages['zh-t'], messages['zh-t-wechat'], zhtLocale)
} else {
  Object.assign(messages['zh-s'], zhsLocale)
  Object.assign(messages['zh-t'], zhtLocale)
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages
})

ElementLocale.i18n((key, value) => i18n.t(key, value))

export {
  i18n
}
