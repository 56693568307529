<template>
  <div>
    <not-find :title="title" :description="description" :from="from"/>
  </div>
</template>
<script>
import NotFind from '@/components/NotFind'
export default {
  name: 'PageNotFind',
  components: {
    NotFind
  },
  data () {
    return {
      title: null,
      description: null,
      from: null
    }
  },
  mounted () {
    console.log('@@14: ', this.$router.history.current.query)
    this.title = this.$router.history.current.query?.title
    this.description = this.$router.history.current.query?.description
    this.from = this.$router.history.current.query?.from
  }
}
</script>
