<template>
  <div class="applicable-store-mobile">
    <applicable-store
      :coupon-id="couponId"
      :campaign-id="campaignId"
      :applicablestoreslist="applicableStoresList"
      :pagetype="applicableStoresPageType"
      :is-back-navigation-store="isBackNavigationStore"
      @pagetypechange="pageTypeChange"
    />
  </div>
</template>
<script>
import ApplicableStore from '@/components/ApplicableStore'
import utilsMixin from '@/mixins/utilsMixin'
import backHandlerMixin from '@/mixins/backHandlerMixin'

export default {
  name: 'ApplicableStoreForMobile',
  components: {
    ApplicableStore
  },
  mixins: [utilsMixin, backHandlerMixin],
  data () {
    return {
      applicableStoresPageType: 'list',
      couponId: null,
      campaignId: null,
      applicableStoresList: [],
      isBackNavigationStore: false
    }
  },
  computed: {
    navTitle () {
      let navTitle = this.$t('campaignApplicableStores')
      console.log('@36 @47', this.campaignId, this.couponId)
      if (this.campaignId) {
        const campaginData = this.$store.getters['campaigns/campaignDetail'](this.campaignId)
        if (campaginData && campaginData.coupon_campaign_type_coupon_template && campaginData.coupon_campaign_type_coupon_template.display_in === 'TICKET_SECTION') {
          navTitle = this.$t('campaignEventLocation')
        }
      } else {
        const couponData = this.$store.getters['coupons/couponDetail'](this.couponId)
        if (couponData && couponData.template && couponData.template.display_in === 'TICKET_SECTION') {
          navTitle = this.$t('campaignEventLocation')
        }
      }
      return navTitle
    }
  },
  mounted () {
    console.log('@ScarabQueue storelist')
    this.emarsysSetCustomerId()
    this.$store.commit('app/setNavType', 'back_language_menu')
    this.$store.commit('app/setMiniProgramBackTitle', '')
    console.log('140---- applicable store list: ', this.$route.query)
    const campaignId = this.$route.query['campaignId']
    const couponId = this.$route.query['couponId']
    if (campaignId) {
      this.campaignId = campaignId
    } else {
      this.couponId = couponId
    }

    this.$store.commit('app/setNavTitle', this.navTitle)
    console.log('47---- si mobile', this.$store.getters['app/isMobile'])
    if (this.$store.getters['app/isMobile']) {
      this.$store.commit('app/hideFooter')
    }

    console.log('@240isBackNavigationStore', this.isBackNavigationStore, this.applicableStoresList)
    this.applicableStoresList = this.getApplicableStoresList()

    if (this.isBackNavigation) {
      // we cannot use isBackNavigation directly, because this value will be erased in the next cycle
      this.isBackNavigationStore = true
    }
  },
  beforeDestroy () {
    this.$store.commit('app/setNavType', 'logo_language_menu')
    this.$store.commit('app/setNavTitle', '')
    this.$store.commit('app/showFooter')
  },
  methods: {
    getApplicableStoresList () {
      let applicableStoresList = []
      if (this.campaignId) {
        const campaginData = this.$store.getters['campaigns/campaignDetail'](this.campaignId)
        console.log('campaign data: ', campaginData)
        if (campaginData && campaginData.detail && campaginData.detail.stores) {
          applicableStoresList = this.formatApplicableStores(campaginData.detail.stores)
        }
      } else {
        const couponData = this.$store.getters['coupons/couponDetail'](this.couponId)
        if (couponData && couponData.template) {
          applicableStoresList = this.formatApplicableStores(couponData.template.applicable_stores)
        }
      }
      return applicableStoresList
    },
    pageTypeChange (type) {
      this.applicableStoresPageType = type
    }
  }
}
</script>
