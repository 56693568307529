<template>
  <div>
    <div class="page-not-found-contain">
      <img class="page_img" width="232px" height="184px" :src="from === 'GOODIE_BAG' ? '/img/goodie-bag-not-avaliable.png' : '/img/page_not_find_img.png'" >
      <div class="title">
        {{ title ? $t(title) : $route.name=="page-not-found"?$t('generalPagenotFoundtitle'):$t('ContentNotAvailable') }}
      </div>
      <div class="description">
        {{ description ? $t(description) : from === 'GOODIE_BAG' ? '' : $route.name=="page-not-found"?$t('generalPagenotFoundDescription'):$t('generalLanguageSupported') }}
      </div>
      <button class="back-to-home primary-button" @click="pushRouterWithQuery($router, { name: 'home' })">{{ $t('generalBacktoHome') }}</button>
    </div>
  </div>
</template>
<script>
import utilsMixin from '@/mixins/utilsMixin'

export default {
  name: 'NotFind',
  components: {
  },
  mixins: [utilsMixin],
  props: {
    title: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    from: {
      type: String,
      default: null
    }
  }
}
</script>
<style scoped lang='scss'>
.page-not-found-contain{
   display: flex;
   flex-flow: column;
   align-items: center;
}
.page-not-found-contain{
   .page_img{
       margin-top: 112px;
   }
   .title{
       font-weight: bold;
       font-size: 30px;
       color: #4A4A4A;
       margin-top: 4px;
       margin-bottom: 12px;
       text-align: center;
       padding: 0px 16px;
   }
   .description{
       font-size: 15px;
       color: #4A4A4A;
   }
   .back-to-home{
       font-weight: bold;
       font-size: 18px;
       border: none;
       height: 50px;
       border-radius: 41px;
       margin-top: 20px;
       margin-bottom: 36px;
       padding-left: 40px;
       padding-right: 40px;
   }
}
@media screen and (max-width:450px) {
    .page-not-found-contain .page_img{
        margin-top: 36px;
    }
}
</style>
